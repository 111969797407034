﻿
.secondary-search{
    border:1px solid #9e9e9e;
    .example-form{
        >mat-form-field{
        width:100% !important;
        padding:0 8px !important;

        .mat-form-field-underline{
            display:none !important;
        }
    }

        .mat-form-field-suffix{
            position: relative !important;
    top: 8px !important;
        }
    }

    
}