.mat-horizontal-content-container{
    //padding-top:30px;
    @include respond-to("x-small") {
        padding: 0;
    }

    @include respond-to("small") {
        padding: 0;
    }

    @include respond-to("medium") {
        padding: 30px 24px 24px 24px;
    }
    @include respond-to("large") {
        padding: 30px 24px 24px 24px;
    }
}
 .icon-2{
    padding-left: 0px !important;
    margin-left: 0px !important;

}
.cart-price{
    /*font-weight: 700;*/
    padding-bottom:5px;
    font-size:14px;
}

.expansion-header-icons {
    position: relative;
    margin-left: 5px;
    .count {
      padding: 7px !important;
      height: 12px;
      width: 12px;
      font-size: 8px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: -6px;
      top: -8px;
      border-radius: 50px;
    }
  }


.mmc_cart {

    .main {
        @include respond-to("x-small") {
            flex-direction: column;
        }

        @include respond-to("small") {
            flex-direction: column;
        }

        @include respond-to("medium") {
            flex-direction: row;
        }

        @include respond-to("large") {
            flex-direction: row;
        }
    }



    .up-sell-items {



        .table-details {
            &:after {
                display: none;
            }
        }

        .main--title {
            font-size: 16px;
            padding-top: 5px;
            color: #6b6868;
            padding-bottom: 5px;
            font-weight: 500 !important;
            color: #000 !important;
            font-weight: 800 !important;
        }

        .cart_facility {
            background-color: #fff;
            margin-top: 10px;
            margin-bottom: 5px;
            padding: 15px 20px;





            &:hover {
                box-shadow: -1px 0px 5px -1px rgba(0,0,0,0.75);
                -webkit-box-shadow: -1px 0px 5px -1px rgba(0,0,0,0.75);
                -moz-box-shadow: -1px 0px 5px -1px rgba(0,0,0,0.75);
            }


            .image-wrapper {
                height: 120px;
            }
        }








        @include respond-to("x-small") {
            flex-direction: column;
        }

        @include respond-to("small") {
            flex-direction: column;
        }

        @include respond-to("medium") {
            flex-direction: row;
        }

        @include respond-to("large") {
            flex-direction: row;
        }

        .image-wrapper {
            @include respond-to("x-small") {
                width: 100%;
            }

            @include respond-to("small") {
                width: 100%;
            }

            @include respond-to("medium") {
                width: 25%;
            }

            @include respond-to("large") {
                width: 25%;
            }
        }
    }

    .snipper-body {
        //padding-top: 30px;
        @include respond-to("x-small") {
            padding-top: 10px;
        }

        @include respond-to("small") {
            padding-top: 10px;
        }

        @include respond-to("medium") {
            padding-top: 30px;
        }

        @include respond-to("large") {
            padding-top: 30px;
        }

        .left-side {
            //  flex-basis: 60%;
            //flex: 0 0 70%;
            @include respond-to("x-small") {
                flex: 0 0 auto;
            }

            @include respond-to("small") {
                flex: 0 0 auto;
            }

            @include respond-to("medium") {
                flex: 0 0 70%;
            }

            @include respond-to("large") {
                flex: 0 0 70%;
            }

            .card-inner {
                padding: 10px;
            }

            .mmc_card {
                border-radius: 3px;
                background-color: #eee !important;
                padding: 20px 15px;
                margin-bottom: 15px;
                position: relative;
                margin-left: 0;

                .btn__validate {
                    .count {
                      position: absolute;
                      padding-left: 2px;
                      border-radius: 45px;
                      height: 22px;
                      width: 22px;
                      font-size: 12px;
                      margin-top: -40px;
                      margin-left: -20px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }

                }

                .cart-outer-wrapper {
                    @include respond-to("small") {
                        margin: 40px 0;
                    }

                    @include respond-to("medium") {
                        margin: 0;
                    }

                    @include respond-to("large") {
                        margin: 0;
                    }

                    @include respond-to("x-large") {
                        margin: 0;
                    }

                    .mat-form-field.mat-form-field-invalid .mat-form-field-ripple, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {

                        @include respond-to("x-small") {
                            background-color: #f44336;
                        }

                        @include respond-to("small") {
                            background-color: #f44336;
                        }

                        @include respond-to("medium") {
                            background-color: #f44336;
                        }

                        @include respond-to("large") {
                            background-color: #f44336;
                        }

                        @include respond-to("x-large") {
                            background-color: #f44336;
                        }
                    }

                    > .mmc_flex--container {
                        @include respond-to("x-small") {
                            flex-direction: column;
                        }

                        @include respond-to("small") {
                            flex-direction: row;
                        }

                        @include respond-to("medium") {
                            flex-direction: row;
                        }

                        @include respond-to("large") {
                            flex-direction: row;
                        }

                        @include respond-to("x-large") {
                            flex-direction: row;
                        }
                    }

                    .drop {

                        .mat-form-field-infix {
                            font-size: 0.8125rem;
                            font-weight: $pw-font-w-medium;
                            line-height: 0.8125rem;
                            letter-spacing: 0.05em;
                            word-spacing: 0.2rem;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                        }
                        //height: 77px;
                        @include respond-to("x-small") {
                            width: 100%;
                        }

                        @include respond-to("small") {
                            width: 76%;
                        }

                        @include respond-to("medium") {
                            width: 76%;
                        }

                        @include respond-to("large") {
                            width: 76%;
                        }

                        @include respond-to("x-large") {
                            width: 76%;
                        }
                    }

                    .add {
                        height: 70px;
                        display: flex;
                        width: auto;

                        @include respond-to("x-small") {
                            //width: 100%;
                            padding-bottom: 10px;
                        }

                        @include respond-to("small") {
                            //width: 35%;
                            padding-bottom: 10px;
                        }

                        @include respond-to("medium") {
                            //width: 40%;
                            padding-bottom: 0;
                        }

                        @include respond-to("large") {
                            //width: 30%;
                            padding-bottom: 0;
                        }

                        @include respond-to("x-large") {
                            //width: 20%;
                            padding-bottom: 0;
                        }
                    }

                    .mat-input-element {
                        color: $pw-text-color__primary !important;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 0.8125rem !important;
                        font-weight: $pw-font-w-medium !important;
                        line-height: 0.8125rem !important;
                        letter-spacing: 0.05em !important;
                        word-spacing: 0.2rem !important;
                        -webkit-font-smoothing: antialiased !important;
                        -moz-osx-font-smoothing: grayscale !important;
                    }
                }

                .mat-radio-label-content {
                    margin-right: 15px;
                }

                .mat-form-field {
                    .mat-input-element {
                        @include respond-to("x-small") {
                            color: #696969 !important;
                        }

                        @include respond-to("small") {
                            color: #696969 !important;
                        }

                        @include respond-to("medium") {
                            color: #696969 !important;
                        }

                        @include respond-to("large") {
                            color: #696969 !important;
                        }

                        @include respond-to("x-large") {
                            color: #696969 !important;
                        }
                    }

                    .mat-form-field-label {
                        @media only screen and (max-width: 1024px) {
                            color: #696969 !important;
                        }


                        @include respond-to("x-small") {
                            color: #696969;
                        }

                        @include respond-to("small") {
                            color: #696969;
                        }

                        @include respond-to("medium") {
                            color: #696969;
                        }

                        @include respond-to("large") {
                            color: #696969;
                        }

                        @include respond-to("x-large") {
                            color: #696969;
                        }
                    }
                }

                .mat-select-value-text {
                    span {
                        @include respond-to("x-small") {
                            color: #000 !important;
                        }

                        @include respond-to("small") {
                            color: #000 !important;
                        }

                        @include respond-to("medium") {
                            color: #000 !important;
                        }

                        @include respond-to("large") {
                            color: #000 !important;
                        }

                        @include respond-to("x-large") {
                            color: #000 !important;
                        }
                    }
                }

                .mat-form-field-appearance-legacy {
                    .mat-form-field-underline {
                        @include respond-to("x-small") {
                            background: #696969;
                        }

                        @include respond-to("small") {
                            background: #696969;
                        }

                        @include respond-to("medium") {
                            background: #696969;
                        }

                        @include respond-to("large") {
                            background: #696969;
                        }

                        @include respond-to("x-large") {
                            background: #696969;
                        }
                    }
                }

                .mat-radio-label-content {
                    @include respond-to("small") {
                        color: #fff;
                    }

                    @include respond-to("medium") {
                        color: #fff;
                    }

                    @include respond-to("large") {
                        color: #696969;
                        ;
                    }

                    @include respond-to("x-large") {
                        color: #696969;
                        ;
                    }
                }

                .mat-radio-outer-circle {
                    @include respond-to("small") {
                        border-color: #fff;
                    }

                    @include respond-to("medium") {
                        border-color: #fff;
                    }

                    @include respond-to("large") {
                        border-color: #ff4081;
                    }

                    @include respond-to("x-large") {
                        border-color: #ff4081;
                    }
                }

                .mat-radio-inner-circle {
                    @include respond-to("small") {
                        background-color: #fff;
                    }

                    @include respond-to("medium") {
                        background-color: #fff;
                    }

                    @include respond-to("large") {
                        background-color: #ff4081;
                    }

                    @include respond-to("x-large") {
                        background-color: #ff4081;
                    }
                }

                @include respond-to("x-small") {
                    background-color: #ee3e84;
                }

                @include respond-to("small") {
                    background-color: #ee3e84;
                }

                @include respond-to("medium") {
                    background-color: #eee;
                }

                @include respond-to("large") {
                    background-color: #eee;
                }

                @include respond-to("x-large") {
                    background-color: #eee;
                }

                > .mmc_flex--container {

                    @include respond-to("small") {
                        margin-top: 15px;
                    }

                    @include respond-to("medium") {
                        padding-top: 0;
                    }

                    @include respond-to("large") {
                        padding-top: 0;
                    }

                    @include respond-to("x-large") {
                        padding-top: 0;
                    }
                }

                &.enquiry {
                    background-color: #fff;
                }

                .card-responsive--button {
                    background-color: #d2c210;
                    border-radius: 50%;
                    color: #fff;
                    position: absolute;
                    top: -37px;
                    right: 10px;

                    i {
                        padding: 10px;
                        font-size: 24px;
                    }

                    @include respond-to("x-small") {
                        display: block;
                    }

                    @include respond-to("small") {
                        display: block;
                    }

                    @include respond-to("medium") {
                        display: none;
                    }

                    @include respond-to("large") {
                        display: none;
                    }

                    @include respond-to("x-large") {
                        display: none;
                    }
                }

                .btn__validate {
                    display: flex;
                    border: 1px dashed #b5b5b5;
                    order-radius: 4px;
                    //@include font-size(12);
                    min-height: 70px;
                    width: 100%;
                    text-align: center;
                    background-color: #fff;
                    -webkit-transition: width 1s, height 1s, background-color 1s, -webkit-transform 1s;
                    transition: width 1s, height 1s, background-color 1s, transform 1s;

                    &.add-venue {
                        color: #ff4081;
                        cursor: pointer;
                        //width: 130px;
                        min-width: 150px;

                        .icon-calendar {
                            //font-size: 20px !important;
                            margin-right: 10px !important;
                        }
                    }

                    .mmc_flex__item--inherit {
                        align-self: center;
                    }

                    .icn-aminity {
                        font-size: 30px;
                        margin-right: 8px;
                    }

                    @include respond-to("x-small") {
                        margin-left: 0;
                    }

                    @include respond-to("small") {
                        margin-left: 8px;
                    }

                    @include respond-to("medium") {
                        margin-left: 8px;
                    }

                    @include respond-to("large") {
                        margin-left: 8px;
                    }

                    @include respond-to("x-large") {
                        margin-left: 8px;
                    }


                    &:hover {
                        background-color: #ee3e84;
                        color: #fff ;
                        border: 1px dashed #fbfbfb;

                        > .mat-button-wrapper {
                            //  background-color:#ee3e84;
                            color: #ffffff;

                            @include respond-to("x-small") {
                                font-size: 14px !important;
                            }

                            @include respond-to("small") {
                                font-size: 14px !important;
                            }

                            @include respond-to("medium") {
                                font-size: 16px !important;
                            }

                            @include respond-to("large") {
                                font-size: 16px !important;
                            }
                        }
                    }
                }

                .mat-button-wrapper {
                    display: flex;
                    color: #ee3e84;
                    text-align: left;
                    font-weight: 500;

                    div {
                        white-space: normal;
                        @include font-size(14);
                        // font-size: 18px;
                    }

                    i {
                        @include font-size(34);
                        padding-right: 10px;
                    }

                    &:hover {
                        color: #fff !important;
                    }
                }
            }
        }

        .right-side {
            //  flex-basis: 40%;
            //flex: 0 0 30%;
            @include respond-to("x-small") {
                flex: 0 0 auto;
            }

            @include respond-to("small") {
                flex: 0 0 auto;
            }

            @include respond-to("medium") {
                flex: 0 0 30%;
            }

            @include respond-to("large") {
                flex: 0 0 30%;
            }

            width: 100%;

            .confirmation {
                padding-bottom: 0;

                i {
                    padding-right: 5px;
                }
            }

            .cart-responsive--button {
                background-color: #ce6b6b;
                border-radius: 50%;
                color: #fff;
                position: absolute;
                top: -20px;
                right: 10px;

                i {
                    padding: 10px;
                    font-size: 24px;
                }

                @include respond-to("x-small") {
                    display: block;
                }

                @include respond-to("small") {
                    display: block;
                }

                @include respond-to("medium") {
                    display: none;
                }

                @include respond-to("large") {
                    display: none;
                }

                @include respond-to("x-large") {
                    display: none;
                }
            }

            @include respond-to("x-small") {
                padding-left: 0;
            }

            @include respond-to("small") {
                padding-left: 0;
            }

            @include respond-to("medium") {
                padding-left: 20px;
            }

            @include respond-to("large") {
                padding-left: 20px;
            }

            @include respond-to("x-large") {
                padding-left: 20px;
            }
        }
    }

    .image-wrapper {
        height: 200px;
        width: 174px;
        background-size: cover;
        background-position: center center;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.5s ease;
        border: 1px solid #b5b5b5;
        object-fit: cover;

        .img:hover {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }
    }

    .cart_facility {
        margin-bottom: 20px;
        border-radius: 3px;
        background-color: #eee;
        padding: 15px;
        border: 1px solid #e3e3e3;

        .cart-zero-error {
            /*background: red;*/
            margin: 20px -20px -20px;
            padding: 5px;
            color: #f44336;
            @include font-size(12);
            border-radius: 3px;
            font-weight: 600;
            transform: scaleX(1);
            -ms-transform: scaleX(1); /* IE 9 */
            -webkit-transform: scaleX(1); /* Safari 3-8 */
            transition: transform 1s;
        }

        .time-icon-cart {
            // flex: 0 1 155px;
        }

        &.not {
            .icon {
                i {
                    @include font-size(60);
                    /*color: #696969;*/
                }
            }

            .text-align {
                text-align: center;
            }

            .text-bold {
                /*font-weight: 500;*/
                font-size: 36px;
                margin-bottom: 20px;
            }

            .text-medium {
                font-weight: bolder;
                font-size: 17px;
                color: #696969;
            }

            .label-warn {
                padding: 40px 0;
                margin-left: auto;
                margin-right: auto;
                border: 1px solid #d6d6d6;
            }
        }

        @include respond-to("x-small") {
            margin-bottom: 50px;
            // margin-bottom: 85px;
            /*margin-left: 15px;
            margin-right: 15px;
            margin-top: -35px;*/
            position: relative;
            z-index: 1000;
        }

        @include respond-to("small") {
            margin-bottom: 50px;
            //margin-bottom: 85px;
            /*margin-left: 40px;
            margin-right: 40px;
            margin-top: -60px;*/
            position: relative;
            z-index: 1000;
        }

        @include respond-to("medium") {
            margin-bottom: 30px;
            margin-left: 0;
            margin-right: 0;
            margin-top: 0;
        }

        @include respond-to("large") {
            margin-bottom: 30px;
            margin-left: 0;
            margin-right: 0;
            margin-top: 0;
        }

        @include respond-to("x-large") {
            margin-bottom: 30px;
            margin-left: 0;
            margin-right: 0;
            margin-top: 0;
        }


        &--detail {
            padding: 0 20px;
            padding-right: 0px;
        }

        &--icon {
            justify-content: flex-end;
            /*padding-left: 10px;*/
            > .mmc_flex__item {
                &:first-child {
                    border-right: 1px solid #696969;
                }
            }

            .card-icon {
                > .mmc_flex__item {
                    &:first-child {
                        position: relative;
                    }
                }

                .count {
                    background-color: $pw-badge-bg-color__primary;
                    color: $pw-badge-text-color;
                    border-radius: 50px;
                    padding: 2px;
                    height: 15px;
                    width: 15px;
                    font-size: 9px;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 12px;
                    top: -4px;
                }
            }
        }

        .card-icon {
            align-items: center;
            flex-direction: column;
            padding: 4px 8px;
            /*color:#696969;*/
            cursor: pointer;
            font-size: 12px;

            @media only screen and (max-width: 425px) {
                font-size: 10px !important;
            }

            &:hover {
                background-color: #e8e8e8;
            }


            i {
                font-size: 18px;
            }

            .mmc_flex__item {
                font-size: 12px;
                font-weight: 500;
            }
             @media only screen and (max-width: 768px) {
                padding: 4px 5px;
            }
        }

        @media screen and (max-width: 575.98px) {
            border: unset !important;
          }
    }

    .table-details {
        padding: 15px 0 15px;
        //  height: 130px;
        position: relative;

        .first-row {
            @media only screen and (max-width: 768px) {
                flex-wrap: wrap !important;

                .w-50-mob {
                    width: 50%;
                }
            }
        }


        .mmc {
            @include respond-to("x-small") {
                display: none;
            }

            @include respond-to("small") {
                display: block;
            }

            @include respond-to("medium") {
                display: block;
            }

            @include respond-to("large") {
                display: block;
            }

            @include respond-to("x-large") {
                display: block;
            }
        }

        .icn-aminity {
            font-size: 24px;
            margin-right: 8px;
            margin-left: 8px !important;
        }

        .attendees {
            @include respond-to("x-small") {
                position: relative;
                left: -3px;
            }

            @include respond-to("small") {
                position: relative;
                left: 0;
            }

            @include respond-to("medium") {
                position: relative;
                left: 0;
            }

            @include respond-to("large") {
                position: relative;
                left: 0;
            }

            @include respond-to("x-large") {
                position: relative;
                left: 0;
            }

            @media screen and (max-width: 768px) {
                left: 0px;
            }
            @media screen and (max-width: 375px) {
                left: 0px;
            }
        }

        .endtime {
            @include respond-to("x-small") {
                position: relative;
                // left: 10px;
            }

            @include respond-to("small") {
                position: relative;
                left: 0;
            }

            @include respond-to("medium") {
                position: relative;
                left: 0;
            }

            @include respond-to("large") {
                position: relative;
                left: 0;
            }

            @include respond-to("x-large") {
                position: relative;
                left: 0;
            }
        }

        .font__blue {
            font-weight: 600;
        }
        /*&:after{

            content: "";
            width: 2px;
            height: 100%;
            border-left: 1px solid #d6d6d6;
            position: absolute;
            left: 50%;
            top: 0%;
            z-index: 1;


       }*/
        > .mmc_flex--container {
            padding: 10px 0;
        }

        i {
            padding-right: 20px;
        }

        .first-row {
            position: relative;

            &:after {
                content: "";
                width: 100%;
                height: 2px;
                border-bottom: 1px solid #d6d6d6;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
            }
        }
    }

    .enquiry-submit {
        margin-left: 10px;
        position: relative;
        margin-bottom: 60px;

        .file-icon {
            @include respond-to("x-small") {
                color: #fff;
            }

            @include respond-to("small") {
                color: #fff;
            }

            @include respond-to("medium") {
                color: #fff;
            }

            @include respond-to("large") {
                color: #454545;
            }

            @include respond-to("x-large") {
                color: #454545;
            }
        }

        .mobi-btn {
            @include respond-to("x-small") {
                color: #fff;
            }

            @include respond-to("small") {
                color: #fff;
            }

            @include respond-to("medium") {
                color: #fff;
            }

            @include respond-to("large") {
                color: #454545;
            }

            @include respond-to("x-large") {
                color: #454545;
            }

            &:hover {
                color: #fff;
                background-color: #0076C0;
            }
        }

        .doc-outer {
            @include respond-to("x-small") {
                color: #fff;
            }

            @include respond-to("small") {
                color: #fff;
            }

            @include respond-to("medium") {
                color: #fff;
            }

            @include respond-to("large") {
                color: #454545;
            }

            @include respond-to("x-large") {
                color: #454545;
            }
        }

        .mobi-btn {
        }

        @include respond-to("x-small") {
            background-color: #454545 !important;
            margin-bottom: 50px;
            margin-left: 0;
        }

        @include respond-to("small") {
            background-color: #454545 !important;
            margin-bottom: 50px;
            margin-left: 0;
        }

        @include respond-to("medium") {
            background-color: #fff !important;
            margin-bottom: 20px;
            margin-left: 10px;
        }

        @include respond-to("large") {
            background-color: #fff !important;
            margin-bottom: 20px;
            margin-left: 10px;
        }

        @include respond-to("x-large") {
            background-color: #fff !important;
            margin-bottom: 20px;
            margin-left: 10px;
        }
        //border-radius: 10px !important;
        .mobile-only {
            @include respond-to("x-small") {
                display: none;
            }

            @include respond-to("small") {
                display: none;
            }

            @include respond-to("medium") {
                display: none;
            }

            @include respond-to("large") {
                display: none;
            }

            @include respond-to("x-large") {
                display: none;
            }
        }

        .txt-date {
            color: #9a9797;
            font-weight: 500;
            font-size: 14px;
        }




        .cart {
            background-color: #ee3e84;
            border-radius: 50%;
            color: #fff;
            position: absolute;
            top: -22px;
            right: 10px;

            i {
                padding: 10px;
            }

            @include respond-to("x-small") {
                display: none;
            }

            @include respond-to("small") {
                display: none;
            }

            @include respond-to("medium") {
                display: block;
            }

            @include respond-to("large") {
                display: block;
            }

            @include respond-to("x-large") {
                display: block;
            }
        }

        .outer-wrapper {
            .mmc_flex--container {
                padding: 15px;
            }
        }

        .booking {
            background-color: #000;
        }

        .rpt {
            padding: 15px;

            .inner {
                border-bottom: 1px solid #dcdcdc;
                /*padding: 5px;*/
                background-color: #f6f6f6;
                margin-bottom: 2px;

                @include respond-to("x-small") {
                    margin-bottom: 0px;
                }

                @include respond-to("small") {
                    margin-bottom: 0px;
                }

                @include respond-to("medium") {
                    margin-bottom: 0px;
                }

                @include respond-to("large") {
                    margin-bottom: 2px;
                }

                @include respond-to("x-large") {
                    margin-bottom: 2px;
                }



                &:hover {
                    background-color: #fff;
                    color: #0275d8;

                    .facility-name {
                        color: #0275d8;
                    }
                }

                .btn-click {
                    padding: 5px;
                    cursor: pointer;

                    &:hover {
                        background-color: #e8e8e8;
                    }
                }





                &:last-child {
                    //  border-bottom:  none;
                }

                .facility-name {
                    @include font-size(14);
                    color: #454545;
                    margin-bottom: 5px;
                }

                i {
                    @include font-size(16);
                }
            }

            i {
                padding-left: 10px;
                color: #696969;
                font-size: 20px;
            }
        }

        .right-align {
            text-align: right;
        }

        .bottom-section {
            background-color: #eee;
            padding: 25px;
            border-radius: 3px;
            border: 1px solid #e3e3e3;

            .mat-checkbox {
                margin-right: 3px;

                label {
                    margin-bottom: 3px;
                    display: inline-flex;
                }
            }

            .line-sep {
                border-bottom: 1px solid #dedede;
                height: 1px;
                margin-left: -15px;
                margin-right: -15px;
            }


            .mat-checkbox-label {
                white-space: normal;
                display: flex;
            }

            .mmc_flex__item {
                padding: 10px 0;
            }

            .btn__card {
                margin: 10px 0;

                &.main {
                    border-radius: 39px !important;
                    font-size: 16px;
                }

                &.submit {
                    /*margin-bottom:20px;*/
                    // border-bottom: 1px solid #696969;
                    color: #fff;
                    background-color: #006ff8;

                    @include respond-to("x-small") {
                        height: 40px;
                    }

                    @include respond-to("small") {
                        height: 40px;
                    }

                    @include respond-to("medium") {
                        height: 40px;
                    }

                    @include respond-to("large") {
                        height: 40px;
                    }

                    @include respond-to("x-large") {
                        height: 60px;
                    }

                    -webkit-box-shadow: -1px 2px 5px 0px rgba(199,199,199,1);
                    -moz-box-shadow: -1px 2px 5px 0px rgba(199,199,199,1);
                    box-shadow: -1px 2px 5px 0px rgba(199,199,199,1);

                    &:hover {
                        -webkit-box-shadow: -1px 3px 27px 0px rgba(199,199,199,1);
                        -moz-box-shadow: -1px 3px 27px 0px rgba(199,199,199,1);
                        box-shadow: -1px 3px 27px 0px rgba(199,199,199,1);
                        background-color: #1254cd;
                    }
                }

                &.sign {
                    @include respond-to("x-small") {
                        height: 40px;
                    }

                    @include respond-to("small") {
                        height: 40px;
                    }

                    @include respond-to("medium") {
                        height: 40px;
                    }

                    @include respond-to("large") {
                        height: 40px;
                    }

                    @include respond-to("x-large") {
                        height: 60px;
                    }

                    margin-top: 20px;
                    background-color: #fff;
                    color: #3e3e3e;
                    border: 1px solid #3e3e3e;


                    &:hover {
                        -webkit-box-shadow: -1px 3px 27px 0px rgba(199,199,199,1);
                        -moz-box-shadow: -1px 3px 27px 0px rgba(199,199,199,1);
                        box-shadow: -1px 3px 27px 0px rgba(199,199,199,1);
                        background-color: #1254cd;
                        color: #fff;
                        background-color: #006ff8;
                    }




                    > .mmc_flex__item {
                        text-align: center;
                    }
                }
            }
        }
    }

    .txt-sign {
        text-align: center;
    }


    .file-upload--card {
        margin-bottom: 30px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        padding: 20px;
        border-radius: 4px;
        position: relative;
        background-color: #ffffff;
        display: flex;

        @include respond-to("x-small") {
            text-align: center;
        }

        @include respond-to("small") {
            text-align: center;
        }

        @include respond-to("medium") {
            text-align: unset;
        }

        @include respond-to("large") {
            text-align: unset;
        }

        @include respond-to("x-large") {
            text-align: unset;
        }

        .file-drop {
            width: 100%;

            .drop-zone {
                margin: auto;
                /*height: 135px;*/
                border: 1px dotted $pw-border-color__secondary;
                border-radius: 5px;

                .content {
                    flex-direction: column;

                    div:nth-child(4) {
                        display: none !important;
                    }
                }
            }
        }

        .file-list-row {
            background-color: #fff;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            margin-bottom: 10px;

            &:nth-last-child(1) {
                margin-bottom: 0px;
            }
        }
    }
    .drop-zone {
        height: max-content !important
        }

        .cart_facility--icon {
            .card-icon {
              @media screen and (max-width: 575.98px) {
                height: 100%;
                text-align: center;
              }
            }
        
            @media screen and (max-width: 575.98px) {
              justify-content: space-evenly;
              margin-bottom: 10px;
            }
        
          }
}


.disable-div{
    cursor: not-allowed !important;
    opacity: 1;
    &:hover{
        opacity: 0.5 !important;
    }
}

//doc upload

.document-upload{
        background-color: $pw-bg-color__tertiary;
        padding:20px;
        padding: 20px 20px 40px 20px;
        margin-bottom: 10px;
             .content{
                    background-color: $pw-bg-color;

                        i{
                font-size: 32px;
                //    margin-right: 20px;

                }
                }



        .title{
            @include font-size(24);
            @include font-weight(semibold);
            text-align: center;
            padding: 20px 0;
            margin-bottom:30px;
            span{
                color:#ee3e84;
            }
        }

        .drop-zone {
            margin: auto;
                height: auto;
                border: 1px solid #eee;
                background-color: #fff;
                border-radius: 5px;


                &:hover{

                border: 1px solid $pw-border-color;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
                }

            .content{
                display: flex;
                flex-direction: column;
                /* padding: 18px 0; */
                margin: 15px 15px;
                border: 1px dashed $pw-border-color__tertiary;

                div:nth-child(4) {
                    display: none !important;
                }


            }

            .upload-icon{
                /*background-color: #696969;
                border-radius: 50%;
                width: 65px;
                height: 65px;
                text-align: center;
                margin-bottom:15px;*/
                padding: 11px;
                border-radius: 50%;
                border: 1px solid gray;
                background-color: gray;
                color: #fff;
                cursor:pointer;

             }
            .doc-up-text{
                cursor:pointer;
                margin-top: 15px;
                &--mobile{
                    @include respond-to("x-small") {
                        display: block;
                      }
                      @include respond-to("small") {
                          display: none;
                      }
                      @include respond-to("medium") {
                        display: none;
                      }
                      @include respond-to("large") {
                         display: none;
                      }
                      @include respond-to("x-large") {
                         display: none;
                      }
                }
                &--desktop{
                    @include respond-to("x-small") {
                        display: none;
                      }
                      @include respond-to("small") {
                          display: block;
                      }
                      @include respond-to("medium") {
                        display: block;
                      }
                      @include respond-to("large") {
                         display: block;
                      }
                      @include respond-to("x-large") {
                         display: block;
                      }
                }
                @include respond-to("x-small") {
                        flex-direction:column;
                      }
                      @include respond-to("small") {
                          flex-direction:row;
                      }
                      @include respond-to("medium") {
                        flex-direction:row;
                      }
                      @include respond-to("large") {
                         flex-direction:row;
                      }
                      @include respond-to("x-large") {
                         flex-direction:row;
                      }
            }
        }

        .drap-drop{
            padding: 15px;
            border: 1px dashed;
            border-radius: 5px;
            background-color: #fff;
            flex-direction: column;
            align-items: center;
            margin-bottom:30px;

            .upload-icon{
                /*background-color: #696969;
                border-radius: 50%;
                width: 65px;
                height: 65px;
                text-align: center;
                margin-bottom:15px;*/
                padding: 10px;
                border-radius: 50%;
                border: 1px solid gray;
                background-color: gray;
                color: #fff;
                i{
                    padding:10px;
                    color: #fff;
                   // @include font-size(32);
                    margin: auto;
                }
            }
        }
    }

@media screen and (max-width: 1023px) {
  .container {
        max-width: unset;
        margin: 0px;
        width: 100%;
  }
}

.doc-sec{
            .doc-container{

                 border-radius: 3px;


                 .doc-inner{
                  //   margin-bottom: 10px;

                        background-color: #eee;
                     @include respond-to("x-small") {
                        flex-direction:column;
                      }
                      @include respond-to("small") {
                          flex-direction:column;
                      }
                      @include respond-to("medium") {
                        flex-direction:row;
                      }
                      @include respond-to("large") {
                         flex-direction:row;
                      }
                      @include respond-to("x-large") {
                         flex-direction:row;
                      }


                      .doc-icon{
                          align-items: center;
                           display: flex;
                        @include respond-to("x-small") {
                            padding: 10px;
                            text-align: center;
                            justify-content: center;
                            background-color: #eee;

                          }
                          @include respond-to("small") {
                                padding: 10px;
                            text-align: center;
                            background-color: #eee;
                          }
                          @include respond-to("medium") {
                             padding: 1.25rem;;
                            text-align: left;
                            background-color: #006ff8;
                          }
                          @include respond-to("large") {
                              padding: 1.25rem;;
                            text-align: left;
                            background-color: #006ff8;
                          }
                          @include respond-to("x-large") {
                               padding: 1.25rem;;
                              text-align: left;
                              background-color: #006ff8;
                          }
                          i{
                              @include respond-to("x-small") {
                                color: #6f6f6f;
                              }
                              @include respond-to("small") {
                                  color: #6f6f6f;
                              }
                              @include respond-to("medium") {
                                  color: #fff;
                              }
                              @include respond-to("large") {
                                 color: #fff;
                              }
                              @include respond-to("x-large") {
                                 color: #fff;
                              }
                          }


                      }


                      .middle-sec{
                          @include respond-to("x-small") {
                            padding: 10px;
                            text-align: center;
                          }
                          @include respond-to("small") {
                                padding: 10px;
                            text-align: center;
                          }
                          @include respond-to("medium") {
                             padding: 1.25rem;;
                            text-align: left;
                          }
                          @include respond-to("large") {
                              padding: 1.25rem;;
                            text-align: left;
                          }
                          @include respond-to("x-large") {
                               padding: 1.25rem;;
                              text-align: left;
                          }
                      }
                      .down-sec{
                          @include respond-to("x-small") {
                            padding: 10px;
                            padding-right:0;
                            text-align: center;
                          }
                          @include respond-to("small") {
                               padding-right:0;
                                padding: 10px;
                            text-align: center;
                          }
                          @include respond-to("medium") {
                             padding: 10px;
                            text-align: left;
                          }
                          @include respond-to("large") {
                               padding: 10px;
                            text-align: left;
                          }
                          @include respond-to("x-large") {
                               padding: 10px;
                              text-align: left;
                          }
                      }

                 }
            }
        }

.right-side{



         .mmc_card enquiry{
                 @include respond-to("x-small") {
                     background-color: #454545 !important;
                     margin-bottom: 30px;
                 }
         }

         .confirmation{
            @include respond-to("x-small") {
             color:#fff;
            }
             @include respond-to("medium") {
                color: #454545;
            }

            }
}




.sec-show{
   .left-side{
       .mmc_card{
           display: block;
       }
   }
   .right-side{
       display: none;
   }
}


@media screen and (max-width: 1023px) {

.sec-hide{
    .left-side{
       .mmc_card{
           display: none;
       }
   }
   .right-side{
       display: block;
   }
}
}


.cart-show{
    background-color: #fff;
    //position: absolute;
    /* right: 0; */
     left: 7%;
    //height: 100vh;
    width: 92vw;
    /* bottom: 0; */
    display: inline !important;
    //z-index: 9999;
    @include respond-to("x-small") {
          top: 18%;
    }
    @include respond-to("small") {
          top: 18%;
    }
    @include respond-to("medium") {
          top: 0;
    }
    @include respond-to("large") {
         top: 0;
    }
    @include respond-to("x-large") {
         top: 0;
    }

}

.cart-show{
    &.right-side{
        margin-bottom: 20px;
        .cart-list{
            display: none;
        }
    }
}
.show{
    display: block;
}
.hide{
    display: none;
}
.mat-radio-container{
    top: 4px !important;
}

.doc-summary{
    @include font-size(12);

    border-bottom: 2px solid #ccc;





                     @include respond-to("x-small") {
                      border-top: 0px solid #ccc;
                      }
                    @include respond-to("small") {
                      border-top: 0px solid #ccc;
                   }
                     @include respond-to("medium") {
                 border-top: 0px solid #ccc;
            }
            @include respond-to("large") {
                border-top: 2px solid #ccc;
            }
            @include respond-to("x-large") {
                 border-top: 2px solid #ccc;
            }





    .file-icon{
        padding-right:5px;
    }
    .mobi-btn{
        font-size: 10px;
        border: 1px dashed #696969;
        border-radius: 15px;
        padding: 5px;
    }
    .doc-outer{
        flex-grow:1;
        .doc-available{
            display:flex;
            flex-direction: column;
        }
    }
    @include respond-to("x-small") {
           display: block;
    }
    @include respond-to("small") {
           display: block;
    }
    @include respond-to("medium") {
           display: none;
    }
    @include respond-to("large") {
          display: none;
    }
    @include respond-to("x-large") {
          display: none;
    }
}

.terms-n-conditions{
    cursor:default !important;
    text-decoration: none !important;
    color: #000;
    a:hover{
        text-decoration: none !important;
        color: #000 !important;
    }
}

.terms-n-conditions:hover{
    color: #000 !important;
}
/*.mobile-only{
     display: none;
     overflow: hidden;
     visibility:hidden;
}
.desktop{
     display: block;
}*/
.error-con{
     flex-direction:column;
     .mat-error{
         @include font-size(12);
         font-weight:500;

         @include respond-to("x-small") {
        //    color: #fff;
           color: $mandatory-text-color;
           padding-bottom:15px;
           line-height: 0.015rem !important;
    }
    @include respond-to("small") {
        //    color: #fff;
           color: $mandatory-text-color;
           line-height: 0.015rem !important;
           padding-bottom:15px;
    }
    @include respond-to("medium") {
           color: #f44336;
           line-height: 1.125rem !important;
    }
    @include respond-to("large") {
          color: #f44336;
          line-height: 1.125rem !important;
    }
    @include respond-to("x-large") {
          color: #f44336;
          line-height: 1.125rem !important;
    }
     }
}

.upsell-modal{
    .modal-header {
        >.mmc_flex--container{
                width: 100%;
            .title-icon{
                border-right: 1px solid $opt-line-color;
                padding-right:20px;
                flex-grow: 0;
            }
            .title{
                padding-left: 20px;
                margin-right:auto;
            }
        }
    }
    .upsell-result{
        .search--result{
            >.mmc_flex--container{
                @include respond-to("x-small") {
                 flex-direction:column;
                }
                @include respond-to("small") {
                     flex-direction:column;
                }
                @include respond-to("medium") {
                     flex-direction:column;
                }
                @include respond-to("large") {
                    flex-direction:column;
                }
                @include respond-to("x-large") {
                     flex-direction:column;
                }
            }

            .content{
                .header-content{
                    flex-direction:column;
                    @include respond-to("x-small") {
                 flex-direction:column;
                }
                @include respond-to("small") {
                     flex-direction:column;
                }
                @include respond-to("medium") {
                      flex-direction:column;
                }
                @include respond-to("large") {
                     flex-direction:column;
                }
                @include respond-to("x-large") {
                      flex-direction:column;
                }
                }
            }

        }
        .quentity {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            .quentity-increase{
                display: inherit;
                .count{
                    margin: 0 10px;
                }
                .mat-raised-button{
                    min-width: unset !important;
                }

                .mat-form-field{
                    width:auto;
                    max-width:100px;
                }
            }
        }
    }
    .sec-two{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @include respond-to("x-small") {
           flex-direction:column;
        }
        @include respond-to("small") {
           flex-direction:column;
        }
        @include respond-to("medium") {
           flex-direction:row;
        }
        @include respond-to("large") {
            flex-direction:row;
        }
        @include respond-to("x-large") {
            flex-direction:row;
        }
    }
    .item-type{
        max-width: 100% !important;
    }
    .main_search{
        >.mmc_flex--container{
            >.mmc_flex__item {
                &:not(:last-child){
                    padding: 0 10px;
                    border-right: 1px solid $opt-line-color;
                }
            }
        }
    }
    .butn__cage{
        height: auto !important;
        display: flex;
        justify-content: flex-end;
    }
    .image-cage{
        max-height: auto !important;
    }
}


.extra--cage{





 @include respond-to("x-small") {
           flex-direction:column;
        }
        @include respond-to("small") {
           flex-direction:column;
        }
        @include respond-to("medium") {
           flex-direction:row;
        }
        @include respond-to("large") {
            flex-direction:row;
        }
        @include respond-to("x-large") {
            flex-direction:row;
        }

    .extra--count{
    flex: 0 1 90px;
    font-weight:500;
    font-size:20px !important;
    height: 100%;
    align-items: center;

     @include respond-to("x-small") {
           width:30%;
        }
        @include respond-to("small") {
          width:30%;
        }
        @include respond-to("medium") {
           width:30%;
        }
        @include respond-to("large") {
          flex: 0 1 90px;
        }
        @include respond-to("x-large") {
          flex: 0 1 90px;
        }



    >.mmc_flex--container{
    height:80px;

    .mmc_flex__item{
    align-self: center;
    }


    }

    }

    .extra--image{
        flex: 0 1 80px;
        .image-wrapper{
        width:100%;
        height:80px !important

        }

    }

    .extra--titlle{
    padding-left:15px;
    height:80px;
    font-weight:500;
    font-size:20px !important;
    align-items: center;
    display: inline-flex;

    }

}
.txt-lbl-count{
color: #adadad;
}

.cart_respo{
    .cart_facility--detail{
        .facility-address{
            line-height: unset;
        }
        .table-details{
            .detail-card{
                .detail-card.endtime{
                    .pw-txt-s3{
                        text-wrap: nowrap !important;
                    }
                    .pw-txt-b3{
                        text-wrap: nowrap !important;
                    }
                }
            }
        }
    }

    @include respond-to("x-small") {
           flex-direction:column;

        }
        @include respond-to("small") {
           flex-direction:column;
        }
        @include respond-to("medium") {
           flex-direction:row;
        }
        @include respond-to("large") {
            flex-direction:row;
        }
        @include respond-to("x-large") {
            flex-direction:row;
        }

        .image-wrapper{
         @include respond-to("x-small") {
          width:100%;
            margin-bottom: 20px;
        }
        @include respond-to("small") {
            width:100%;
              margin-bottom: 20px;
        }
        @include respond-to("medium") {
              width:180px;
               height:180px;
                 margin-bottom: 0px;
        }
        @include respond-to("large") {
              width:180px;
               height:180px;
                 margin-bottom: 0px;
        }
        @include respond-to("x-large") {
             width:180px;
               height:180px;
                 margin-bottom: 0px;
        }

        }


        .detail-card{
            .config{
                .config-img{
                    >img{
                        height:30px !important;
                         @media only screen and (max-width: 768px) {
                                height:30px !important;
                        }
                    }

                }
            }
        }

        .cart_facility--detail {
            .first-row {
              @media only screen and (max-device-width: 768px) {
                .detail-card.endtime {
                  width: unset !important;
                }
              }
            }
          }

}

.icn--star{
    flex: 0 1 40px !important;
}


//booking-cart

.booking-cart{


    .uploaded-file-list--card {
        margin-bottom: 30px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        padding: 20px;
        border-radius: 4px;
        position: relative;
        background-color: #ffffff;
        display: flex;

        @include respond-to("x-small") {
            flex-direction: column;
            height: auto;
        }

        @include respond-to("small") {
            flex-direction: column;
            height: auto;
        }

        @include respond-to("medium") {
            flex-direction: column;
            height: auto;
        }

        @include respond-to("large") {
            flex-direction: column;
            height: auto;
        }

        @include respond-to("x-large") {
            flex-direction: column;
            height: auto;
        }


        .file-list-row {
            background-color: #fff;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            margin-bottom: 10px;

            &:nth-last-child(1) {
                margin-bottom: 0px;
            }
        }

        .icon-col {
            background-color: #006ff8;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            .material-icons {
                color: #fff;
            }
        }

        .label-col {
            padding: 10px 0px 10px 10px;
        }

        .download-col {
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            .material-icons {

                &:hover {
                    cursor: pointer;
                    color: #ED3E84;
                }
            }
        }
    }

    .mmc_ {
  &payment {
    padding: 0;
    margin: 16px auto;
    background-color: #fff;
    @include font-size(16);
    .flex-column {
      padding: 20px;
      flex-direction: column;
    }
    .main-row {
      @include padding-all(10);
    }
    .mat-form-field {
      display: block;
    }
    .pay-amount{
             .mat-input-element{
                  text-align: left !important;
              }
    }
    .val-sec {
      @include padding-left(10);
    }
    .btn-section {
      margin: auto;
      display:flex;
      justify-content:center;
      .login__submit{
        margin-bottom: 16px !important;
        margin-top:0 !important;
        width:50%!important;
    }

      //   width: 100%;
    }
  }
}

}

.cart-flex-item {
    flex-direction: row !important;
    flex-wrap: wrap;
}

.cart-item-one {
    flex: 0 0 70%;
}

.cart-item-two {
    flex: 0 0 30%;
    justify-content: flex-end;
    align-items: flex-start;
}

.cart-item-three {
    flex: 0 0 100%;
}

.notright{
    display: none;
  }

  .when-notright{
    flex: 0 0 100% !important;
  }

  .mmc_card .facility-card{
    background-color: #fff !important;
  }

  .cart_facility:last-child{
    margin-bottom: unset !important;
  }

  .mmc_cart .cart_facility {
    margin-bottom: 5px !important;
  }

  .left-side{
    .document-upload{
      box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
      border: 1px solid #eee;
    }
  }


.document-upload .drop-zone .content {
    text-align: center !important;
    height: max-content !important;
    padding: 15px !important;
    border-radius: 5px !important;
  }
div#document-upload-container {
    width: 100% !important;
}
.mmc_flex--container.doc-sec {
    background: whitesmoke !important;
    padding-bottom: unset !important;
}
@media (max-width: 640px) {
    .mmc_cart .drop-zone {
        height: 160px !important;
    }
}