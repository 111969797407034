@import "../../sass/helpers/functions";
@import "../../sass/helpers/mixins";
@import "../../sass/helpers/variables";
@import "../../sass/base/color";

.login__name.forget-email{
  .forget-email-username-icon{
    position:relative !important;
    .icon-user.icon{
      position: absolute !important;
      top: 31% !important;
      left: 5px !important;
    }
  }
  .forget-email-username-placeholder{
    margin-left: 1rem;
  }
}

.forgot-password {
  @include font-size();

  

  .forget-email{
      padding: 0 10px;
  }
  .login {
    padding: 120px 0;
    color: white;

    form{
        width: 80%;

    }


    &__welcome {
      width: 100%;
      background: linear-gradient(-241deg, #2388f0 0%, #00698f 100%);
      border-radius: 3px 0 0 3px;
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35);
          text-align: center;
    }
    &__signin {
      background: $color-login-bg;
      border-radius: 0 3px 3px 0;
      padding: 65px 0 30px;
      .row {
        justify-content: center;
      }
    }
    &__center {
      display: flex;
      justify-content: center;
    }
    &__title {
      color: #006ff8;
      text-transform: uppercase;
          width: 100%;
    }
    &__sub {
      padding: 45px 0 35px;
      color: $color-text;
          width: 100%;
    }
    &__name,
    &__password {
      width: 100%;
      background: $color-login-input-bg;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 10px;
      color: black;
      img {
        width: 30px;
        height: 25px;
        margin-right: 20px;
      }
    }
    &__input {
      height: 25px;
      min-width: 80%;
      border: none;
      background: none;
      &::placeholder {
        font-size: 16px;
        font-weight: 500;
        color: black;
      }
      &:focus {
        outline-color: $color-login-input-bg;
      }
    }
    &__btn {
      border: none;
      color: white;
      text-transform: uppercase;
      &--signup {
        background: none;
        margin: 0;
      }
    }
    &__submit {
      margin-top: 65px;
      margin-bottom: 45px;
    }
    &__forgot {
      color: black;
      &:hover {
        text-decoration: none;
        color: #006ff8;
        border-bottom: 1px solid #006ff8;
      }
    }
    &__return {
      img {
        @include sizeSquare(2.5rem);
        margin-right: 1.5rem;
      }
      span {
        color: black;
      }
    }
    &__bg {
      background: $color-login-bg;
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35);
    }
  }
  .check-user {
    padding: 1.5rem 2rem;
    input {
      min-width: 0;
      @include sizeSquare(2.8rem);
      margin-right: 2rem;
    }
    img {
      width: 3.6rem;
      height: 3.7rem;
      margin-left: auto;
    }
  }
  .text-center {
    text-align: center;
  }
  .sigup--mobile {
    display: none;
  }
  .confirm {
    color: $color-red-text;
    margin-bottom: 4.5rem;
    margin-top: -3rem;
        margin-top: 5px;
  }
}

@media screen and (max-width: 576px) {
  .login {
    //padding-top: 80px;
    &__welcome {
      display: none;
    }
    &__bg {
      background: none;
    }
  }
  .sigup--mobile {
    display: block;
    padding-top: 160px;
    padding-bottom: 90px;
    color: $color-text-dark;
    &--link {
      cursor:pointer;
      color: $color-text-link;
    }
  }
}
