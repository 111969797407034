/*@import "../helpers/mixins";
@import "../helpers/flexbox";
@import "../base/typography";
@import "../pages/search";*/


.map-right{
    flex: 0 0 30% !important;
    .right{
        width: 100%;
    }
}

.booking--details {
    /*padding: 15px;*/
    .aminity-icons {
        i {
            margin-right: 15px;
        }
    }


    ngx-gallery {

        @include respond-to("x-small") {
            height: 600px !important;
        }

        @include respond-to("small") {
            height: 600px !important;
        }

        @include respond-to("medium") {
            height: 500px !important;
        }

        @include respond-to("large") {
            height: 500px !important;
        }

        @include respond-to("x-large") {
            height: 700px !important;
        }
    }

    .--email,.ie-email{
    white-space: -moz-pre-wrap !important; 
    white-space: -pre-wrap;    
    white-space: -o-pre-wrap;   
    white-space: pre-wrap;      
    word-wrap: break-word;      
    white-space: -webkit-pre-wrap; 
    word-break: break-all;
    white-space: normal;
    display: inline-block;
    }

    .tp-no {
        font-size: 18px !important;


        a {
            color: #fff;

            &:hover {
                text-decoration: none;
                color: #0275d8;
            }
        }
    }

    .map {
        height: 400px;
        background-color: #eee;
    }

    .mat-form-field-label {
        color: #a0a0a0;
    }

    .sld-time {
        margin-top:24px;
        text-align: right;



        .mmc_flex__item {
            margin-right: 10px;

            .mat-form-field {
                .mat-input-element {
                    cursor: pointer;
                }
                @include respond-to("x-small") {
                    width: 100%;
                }

                @include respond-to("small") {
                    width: 100%;
                }

                @include respond-to("medium") {
                    width: 75%;
                }

                @include respond-to("large") {
                    width: 65%;
                }
            }
        }
    }

    .main-container {

        @include respond-to("x-small") {
            flex-direction: column;
        }

        @include respond-to("small") {
            flex-direction: column;
        }

        @include respond-to("medium") {
            flex-direction: row;
        }

        @include respond-to("large") {
            flex-direction: row;
        }
    }

    .details-section {
        padding-top: 50px;
        color: #696969;
        margin:0 -15px;
        .contact-wrapper {
            background-color: $pw-bg-color__senary;
            color: #fff;
            border-radius: 5px;
            margin-bottom: 20px;

            &--inner {
                @include padding-all(30);

                .--email {
                    border-top: 1px solid #717171;
                    border-bottom: 1px solid #717171;
                    padding: 5px;
                    margin: 10px 0;
                    @include font-size(14);
                    word-wrap: break-word;
                    word-break: break-all;
                    white-space: normal;
                    display: inline-block;
                    a {
                        color: #fff;

                        &:hover {
                            text-decoration: none;
                            color: #0275d8;
                        }
                    }
                }
            }
        }

        .left {
            flex: 0 0 70%;


            > .mmc_flex--container {
                flex-direction: column;
                // padding-bottom: 50px;
            }

            p {
                text-align: justify;
                @include font-weight(medium);
            }

            .paragraph {
                padding-bottom: 20px;
            }
            .video{
                margin-bottom: 50px;
                height: 450px;
                
                iframe{
                  border:none !important;
                    height:100%;
                    width:100%;
                }              
            }

            .facility-list {
                @include font-weight(semibold);
                padding-bottom: 50px;
                // @include padding-top(30px);
                flex-direction: column;
                // .title {
                //   position: relative;
                //   width: 100%;
                //   &::after {
                //     width: 100%;
                //     height: 2px;
                //     border-bottom: 1px solid red;
                //     position: absolute;
                //     left: 0;
                //     top: 50%;
                //     z-index: 1;
                //   }
                // }
                .items--wrapper {
                    img {
                        height: 24px !important;
                        width: 24px !important;
                    }

                    .list-item {
                        color: #000;
                        padding-left: 15px;
                        margin-bottom: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        list-style: none;
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: space-around;
                        align-self: flex-start;
                        justify-content: flex-start;
                        align-content: flex-start;
                        align-items: flex-start;

                        li {
                            margin-bottom: 10px;
                            color: #766f6f;
                            width: 50%;

                            img {
                                margin-right: 20px;
                            }
                        }

                        i {
                            font-size: 16px;
                        }

                        @include respond-to("x-small") {
                            max-height: unset;
                        }

                        @include respond-to("small") {
                            max-height: unset;
                        }

                        @include respond-to("medium") {
                            max-height: 200px;
                        }

                        @include respond-to("large") {
                            max-height: 200px;
                        }

                        li {
                            // &::before {
                            //   font-family: "Material Icons";
                            // content: "person";
                            i {
                                padding-right: 15px;
                                font-size: 25px;
                            }
                            // }
                        }
                    }
                }
            }
        }

        .right {
            height: 100%;
            flex-direction: column;
            margin: auto;
           // flex: 0 0 30%;

            @include respond-to("x-small") {
                padding-left: 0;
            }

            @include respond-to("small") {
                padding-left: 0;
            }

            @include respond-to("medium") {
                padding-left: 30px;
            }

            @include respond-to("large") {
                padding-left: 50px;
            }


            .map {
                // background:url('dist/assets/images/map.png') no-repeat center center fixed;
                margin-bottom: 40px;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }
        }
    }

    .carousel-wrapper {
        padding: 50px 0;
        background-color: #eee;
        margin-top: 50px;

        .name__inner {
            background-color: #eee;
        }

        .title {
            padding-bottom: 10px;
        }
    }


    .title {
        /*@include font-size(22);*/
        margin-bottom: 50px;
        position: relative;

        &:after {
            content: "";
            width: 100%;
            height: 2px;
            border-bottom: 1px solid #e3e3e3;
            position: absolute;
            left: 0;
            top: 50%;
            z-index: 1;
        }

        .name {
            &:before {
                content: "";
                width: 100%;
                height: 2px;
                border-bottom: 1px solid #e3e3e3;
                position: absolute;
                left: 0;
                top: 50%;
                z-index: 1;
            }

            span {
                color: #ed3e84;
            }
        }
    }

    .name__inner {
        background-color: #fff;
        position: absolute;
        left: 0;
        z-index: 100;
        position: -webkit-sticky;
        margin-top: -15px;
    }

    .footer_owl {
        background-color: #eee;
        padding: 20px 0;
        margin-top: 60px;

        .regular {
            padding-top: 30px;

            .slick-prev:before,
            .slick-next:before {
                color: black;
            }
        }

        .slider-details {
            flex-direction: column;
            padding: 15px !important;

            .item_name {
                padding-bottom: 10px;

                p {
                    color: #696969;
                    @include font-size(12);
                }

                a {
                    color: #000;
                }
            }

            .aminity-icons {
                float: right;
            }
        }

        .name__inner {
            background-color: #eee !important;
        }

        .slider-card {
            background-color: #fff;
            flex-direction: column;
            margin: 0 15px;

            .image-cage {
                width: unset !important;
                border: none;

                .img {
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                    background-position: center center;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    -webkit-transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -ms-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    transition: all 0.5s ease;

                    width: 100%;
                    object-fit: cover;
                }




                &:hover {
                    border: none;
                    -webkit-transform: scale(1.2);
                    -moz-transform: scale(1.2);
                    -o-transform: scale(1.2);
                    -ms-transform: scale(1.2); /* IE 9 */
                    transform: scale(1.2);
                }

                .overlay {
                    background-color: #4545451c;
                    height: 100%;
                }
            }
        }
    }
}

.flex-colomn {
    flex-direction: column;
}

.flex-end {
    justify-content: flex-end;
}

.slider-detail--wrapper {
    //margin-bottom: 10px;
    background: -moz-linear-gradient(top, rgba(0,0,0,0) -1%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.03) 1%, rgba(0,0,0,0.78) 30%, rgba(0,0,0,0.9) 65%, rgba(0,0,0,0.9) 99%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) -1%,rgba(0,0,0,0) 0%,rgba(0,0,0,0.03) 1%,rgba(0,0,0,0.78) 30%,rgba(0,0,0,0.9) 65%,rgba(0,0,0,0.9) 99%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) -1%,rgba(0,0,0,0) 0%,rgba(0,0,0,0.03) 1%,rgba(0,0,0,0.78) 30%,rgba(0,0,0,0.9) 65%,rgba(0,0,0,0.9) 99%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#e6000000',GradientType=0 );
    padding: 15px;
    color: white;

    .btn_book {
        top:10px;
        margin: auto;
        width: 100%;
        display: flex; 
        align-items: center;
        @include respond-to("x-small") {
            padding-bottom: 20px;
        }

        @include respond-to("small") {
            padding-bottom: 20px;
        }

        @include respond-to("medium") {
            padding-bottom: 20px;
            margin: 0px !important;
            align-items: flex-end;
        }

        @include respond-to("large") {
            padding-bottom: 20px;
        }

        @include respond-to("x-large") {
            padding-bottom: 20px;
        }

        .btn__card {
            cursor: pointer;
            padding: 30px 10px;
            background-color: #006ff8;
            color: #fff;
            border: 1px solid #2db8ea;

            &:hover {
                background-color: #1148a9;
                color: #fff;
                border: 1px solid #2db8ea;
            }
        }
    }

    .mat-icon-button {
        color: #fff;
        font-size: 100% !important;
    }

    .mat-focused .placeholder {
        color: #fff;
    }

    .mat-form-field {
        .mat-input-element {
            color: $pw-text-color__special !important;
        }
    }

    .mat-form-field-appearance-legacy {
        .mat-form-field-underline {
            background-color: $pw-text-color__special !important;
        }
    }
    /*::ng-deep .mat-form-field-placeholder{
      color: #d5c51c;
    }
    ::ng-deep .mat-focused .mat-form-field-placeholder{
      color:#d5c51c;
    }*/
}

//
.disable-div {
    cursor: not-allowed !important;
    opacity: 1;
    &:hover{
        opacity: 0.5 !important;
    }
}


//Time picker
timepicker {
    .btn {
        &.btn-link {
            width: 45px;
            height: 30px;
            background: none !important;
        }
    }

    input {
        &.form-control {
            width: 50px;
        }
    }

    button {
        &.btn {
            width: 60px;
            border-radius: 5px;
            height: 40px;
            background:$pw_btn-bg-color__secondary;
        }
    }
}

//slider
.mat-sidenav-container, .mat-sidenav-content, .mat-tab-body-content {
    transform: none !important;
}

//doc
.doc-sec {
    padding-bottom: 20px;
    @include font-weight(semibold);
    flex-direction: column;

    .doc-inner {
        //margin-bottom: 10px;
        background-color: $pw-bg-color__secondary;

        @include respond-to("x-small") {
            flex-direction: column;
        }

        @include respond-to("small") {
            flex-direction: column;
        }

        @include respond-to("medium") {
            flex-direction: row;
        }

        @include respond-to("large") {
            flex-direction: row;
        }

        @include respond-to("x-large") {
            flex-direction: row;
        }
    }

    .doc-container {
        margin-top: 10px;
        border-radius: 5px;
        border-radius: 3px;

        .doc-icon {
            @include padding-all(20);
            background-color: $pw_btn-bg-color__primary;
            border-radius: 5px 0 0 5px;
            align-items: center;
            display: flex;

            @include respond-to("x-small") {
                padding: 10px;
                text-align: center;
                justify-content: center;
                background-color: #eee;
            }

            @include respond-to("small") {
                padding: 10px;
                text-align: center;
                background-color: #eee;
            }

            @include respond-to("medium") {
                padding: 1.25rem;
                ;
                text-align: left;
                background-color: #006ff8;
            }

            @include respond-to("large") {
                padding: 1.25rem;
                ;
                text-align: left;
                background-color: #006ff8;
            }

            @include respond-to("x-large") {
                padding: 1.25rem;
                ;
                text-align: left;
                background-color: #006ff8;
            }

            i {
                color: #fff;
                align-self: center;

                @include respond-to("x-small") {
                    color: #6f6f6f;
                }

                @include respond-to("small") {
                    color: #6f6f6f;
                }

                @include respond-to("medium") {
                    color: #fff;
                }

                @include respond-to("large") {
                    color: #fff;
                }

                @include respond-to("x-large") {
                    color: #fff;
                }
            }
        }
            .middle-sec {
                align-self: center;
                padding-left: 10px;
                word-break: break-all;
                @include padding-left(20);
                align-self: center;

                @include respond-to("x-small") {
                    padding: 10px;
                    text-align: center;
                }
                @include respond-to("small") {
                    padding: 10px;
                    text-align: center;
                }
                @include respond-to("medium") {
                    padding: 1.25rem;
                    text-align: left;
                }
                @include respond-to("large") {
                    padding: 1.25rem;
                    text-align: left;
                }
                @include respond-to("x-large") {
                    padding: 1.25rem;
                    text-align: left;
                }
            }

            .down-sec {
                // @include padding-all(15);
                /*padding-top: 11px;
                padding-right: 30px;*/
                text-align: -webkit-center;
                align-self: center;
                padding-left: 10px;
                color: #0275d8;
                text-decoration: none;
                cursor: pointer;
                >a{
                    text-decoration:none;
                }

                &:hover{
                    color: #014c8c;
                }

                @include respond-to("x-small") {
                    padding: 10px;
                    padding-right: 0;
                    text-align: center;
                }

                @include respond-to("small") {
                    padding-right: 0;
                    padding: 10px;
                    text-align: center;
                }

                @include respond-to("medium") {
                    padding: 10px;
                    text-align: left;
                }

                @include respond-to("large") {
                    padding: 10px;
                    text-align: left;
                }

                @include respond-to("x-large") {
                    padding: 10px;
                    text-align: left;
                }

                .down-icon {
                    color: $pw-icon-color__tertiary;
                    align-items: center;
                    display: flex;
                    display: flex;
                    justify-content: center;

                    i {
                        @include respond-to("x-small") {
                            color: #6f6f6f;
                        }

                        @include respond-to("small") {
                            color: #6f6f6f;
                        }

                        @include respond-to("medium") {
                            color: #fff;
                        }

                        @include respond-to("large") {
                            color: #fff;
                        }

                        @include respond-to("x-large") {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

/*.doc-sec{
            .doc-container{
              
                 border-radius: 3px;


                 .doc-inner{
                     margin-bottom: 10px;

                        background-color: #eee;
                     @include respond-to("x-small") {
                        flex-direction:column;
                      }
                      @include respond-to("small") {
                          flex-direction:column;
                      }
                      @include respond-to("medium") {
                        flex-direction:row;
                      }
                      @include respond-to("large") {
                         flex-direction:row;
                      }
                      @include respond-to("x-large") {
                         flex-direction:row;
                      }


                      .doc-icon{
                          align-items: center;
                           display: flex;
                        @include respond-to("x-small") {
                            padding: 10px;
                            text-align: center;
                            justify-content: center;
                            background-color: #eee;

                          }
                          @include respond-to("small") {
                                padding: 10px;
                            text-align: center;
                            background-color: #eee;
                          }
                          @include respond-to("medium") {
                             padding: 1.25rem;;
                            text-align: left;
                            background-color: #006ff8;
                          }
                          @include respond-to("large") {
                              padding: 1.25rem;;
                            text-align: left;
                            background-color: #006ff8;
                          }
                          @include respond-to("x-large") {
                               padding: 1.25rem;;
                              text-align: left;
                              background-color: #006ff8;
                          }
                          i{
                              @include respond-to("x-small") {
                                color: #6f6f6f;
                              }
                              @include respond-to("small") {
                                  color: #6f6f6f;
                              }
                              @include respond-to("medium") {
                                  color: #fff;
                              }
                              @include respond-to("large") {
                                 color: #fff;
                              }
                              @include respond-to("x-large") {
                                 color: #fff;
                              }
                          }


                      }


                      .middle-sec{
                          @include respond-to("x-small") {
                            padding: 10px;
                            text-align: center;
                          }
                          @include respond-to("small") {
                                padding: 10px;
                            text-align: center;
                          }
                          @include respond-to("medium") {
                             padding: 1.25rem;;
                            text-align: left;
                          }
                          @include respond-to("large") {
                              padding: 1.25rem;;
                            text-align: left;
                          }
                          @include respond-to("x-large") {
                               padding: 1.25rem;;
                              text-align: left;
                          }
                      }
                      .down-sec{
                          @include respond-to("x-small") {
                            padding: 10px;
                            padding-right:0;
                            text-align: center;
                          }
                          @include respond-to("small") {
                               padding-right:0;
                                padding: 10px;
                            text-align: center;
                          }
                          @include respond-to("medium") {
                             padding: 10px;
                            text-align: left;
                          }
                          @include respond-to("large") {
                               padding: 10px;
                            text-align: left;
                          }
                          @include respond-to("x-large") {
                               padding: 10px;
                              text-align: left;
                          }
                      }

                 }
            }
        }*/
.wrap-small{
     @media only screen and (max-width: 425px) {
                                        flex-wrap:wrap;
                                    }

}