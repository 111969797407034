
.snow{
// font
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
/*@import url("https://cdnjs.cloudflare.com/ajax/libs/hammer.js/2.0.8/hammer.min.js");*/
//@import url("https://i.icomoon.io/public/temp/29ea2db74a/UntitledProject1/style.css");

//@import "~bootstrap/dist/css/bootstrap.css";
@import "../../vendors/bootstrap/scss/bootstrap.scss";
//@import "~font-awesome/css/font-awesome.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~@angular/material/theming';

@import "variables.scss";
@import "../../helpers/functions.scss";
@import "../../helpers/mixins.scss";
@import "../../helpers/flexbox.scss";

// base
@import "../../base/color.scss";
@import "../../base/colors.scss";
@import "../../base/normalize.scss";
@import "../../base/tc-md-typography.scss";
@import "../../base/typography.scss";
///@import "../../base/reset.scss";
@import "../../base/padding.scss";
@import "../../base/border.scss";
@import "../../base/margin.scss";
@import "../../base/_icon-font.scss";
@import "../../base/tc-md-color-definitions.scss";
@import "../../base/tc-md-functions.scss";
@import "../../base/tc-md-typography.scss";
@import "../../base/_tc-md-variables.scss";


@import "../../base/_typography-new.scss";
@import "../../base/_tc-md-mixins-new.scss";
@import "../../base/_colors-new.scss";
@import "../../components/_buttons-new.scss";
@import "../../themes/default/_variables-new.scss";

@import "../../vendors/bootstrap/scss/utilities/_spacing.scss";

// themes
//@import "./themes/dark_theme";
//@import "./themes/light_theme";

// component
@import "../../components/buttons.scss";
@import "../../components/carousel.scss";
@import "../../components/cover.scss";
@import "../../components/dropdown.scss";
@import "../../components/gallery.scss";
@import "../../components/navigation.scss";
@import "../../components/corporate.scss";
@import "../../components/slider.scss";
@import "../../components/modal.scss";


// layout
@import "../../layout/forms.scss";
@import "../../layout/grid.scss";
@import "../../layout/sidebar.scss";


// module
@import "../../module/cards.scss";
@import "../../module/footer.scss";
@import "../../module/header.scss";
@import "../../module/filter.scss";
@import "../../module/search_result.scss";
@import "../../module/toast-message.scss";

//pages
@import "../../pages/search.scss";
@import "../../pages/secondary_search.scss";
@import "../../pages/payment.scss";
@import "../../pages/booking.scss";
@import "../../pages/login.scss";
@import "../../pages/forgot_password.scss";
@import "../../pages/facility.scss";
@import "../../pages/_setup.scss";
@import "../../pages/_cart.scss";
@import "../../pages/_enquiry.scss";
@import "../../pages/_404.scss";
@import "../../pages/_past_booking.scss";
@import "../../pages/_past_booking_details.scss";
@import "../../pages/_account.scss";


@import "../../layout/_hack.scss";

}

