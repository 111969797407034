﻿@import "../../base/_colors-new.scss";


$base-text-color: #000;
$color-base: $base-text-color;

$light: lighten($color-base,60%);
$lighter: lighten($color-base,30%);
$dark: darken($color-base,15%);
$darker: darken($color-base,87%);

//Font Family..............................................

$font-montserrat: "Montserrat", sans-serif;

//Font Weight..............................................

$pw-font-w-light: 300;
$pw-font-w-regular: 400;
$pw-font-w-medium: 500;
$pw-font-w-semi-bold: 600;
$pw-font-w-bold: 700;
$pw-font-w-extra-bold: 800;
$pw-font-w-black: 900;

//General Colours..............................................

$pw-color__white:$pw_color_white !default;
$pw-color__red:$red-500;
$pw-color__pink:$pink-500;
$pw-color__blue:$blue-500;
$pw-color__light-blue:$light-blue-500;
$pw-color__teal:$teal-500;
$pw-color__yellow:$yellow-500;
$pw-color__amber:$amber-500;


//Bussiness Colours..............................................

$pw-color:#994198 !default;
$pw-color__primary:#ed3e84;
$pw-color__secondary:#cbdd59;
$pw-color__tertiary:#3d9b47;
$pw-color__quaternary:#27bfc2 !default;
$pw-color__quinary:#27bfc2 !default;


//Text Colours..............................................

$pw-text-color:$pw_color_white !default;
$pw-text-color__primary:$darker !default;
$pw-text-color__secondary:$grey-500 !default;
$pw-text-color__tertiary:$grey-700;
$pw-text-color__quaternary:#006FF8;
$pw-text-color__quinary:#ed3e84;
$pw-text-color__senary:$red-500;

$pw-text-color__special:#FFEB3B;


//Icon Button Colours..............................................

$pw_btn-icon-bg-color:$pw_color_white;
$pw_btn-icon-bg-color__primary:#ED3E84;
$pw_btn-icon-bg-color__secondary:#009688;
$pw_btn-icon-bg-color__tertiary:#FF9800;
$pw_btn-icon-bg-color__quaternary:#3fba74;


//Icon Button text Colours..............................................

$pw_icon-btn-text-color:$pw_color_white;
$pw_icon-btn-text-color__primary:#ED3E84;
$pw_icon-btn-text-color__secondary:#009688;
$pw_icon-btn-text-color__tertiary:#f44336;
$pw_icon-btn-text-color__quaternary:#f44336;
$pw_icon-btn-text-color__quinary:#ED3E84;

//Button Colours..............................................

$pw_btn-bg-color:$pw_color_white;
$pw_btn-bg-color__primary:#006FF8;
$pw_btn-bg-color__secondary:#0076C0;
$pw_btn-bg-color__tertiary:#3fba74;
$pw_btn-bg-color__quaternary: #E0E0E0;
$pw_btn-bg-color__quinary: $pw_color_white;
$pw_btn-bg-color__gradient: linear-gradient(-241deg, #2388f0 0%, #00698f 100%);


//Button text Colours..............................................

$pw_btn-text-color:$pw_color_white;
$pw_btn-text-color__primary:#006FF8;
$pw_btn-text-color__secondary:#ED3E84;
$pw_btn-text-color__tertiary:#009688;
$pw_btn-text-color__quaternary:#f44336;
$pw_btn-text-color__quinary:#ED3E84;


//Button Border line Colors.........................................

$pw-btn-border-color:$pw_color_white;
$pw-btn-border-color__primary:#006FF8;
$pw-btn-border-color__secondary:#ed3e84 !default;
$pw-btn-border-color__tertiary:#3a424d;
$pw-btn-border-color__quaternary:#27bfc2 !default;
$pw-btn-border-color__quinary:#27bfc2 !default;

//Border line Colors.........................................

$pw-border-color:$grey-200 !default;
$pw-border-color__primary:$grey-300;
$pw-border-color__secondary:$grey-700 !default;
$pw-border-color__tertiary:#3a424d;
$pw-border-color__quaternary:#27bfc2 !default;
$pw-border-color__quinary:#f5f5f5 !default;

//Link Colours..............................................

$pw-link-color:#006FF8 !default;
$pw-link-color__primary:#3a424d;
$pw-link-color__secondary:#27bfc2 !default;
$pw-link-color__tertiary:#3a424d;
$pw-link-color__quaternary:#27bfc2 !default;
$pw-link-color__quinary:#27bfc2 !default;

//State Colours..............................................

$pw_status_paid :#009688;
$pw_status_pending :#FF9800;
$pw_status_cancelled :#9E9E9E;

$pw_status_confirmed :#f5766c;
$pw_status_confirmed-multiple:#607d8b !default;
$pw_status_provisional :#00bcd4;
$pw_status_warning :#ff9800;
$pw_status_tentative :#673ab7;
$pw_status_good_availability :#2c95dc !default;
$pw_status_few_availability :#f44336 !default;

//Background Colours..............................................

$pw-bg-color:$pw_color_white !default;
$pw-bg-color__primary:$grey-50;
$pw-bg-color__secondary:$grey-100 !default;
$pw-bg-color__tertiary:$grey-200;
$pw-bg-color__quaternary:#f7faff !default;
$pw-bg-color__quinary:#FCF6F8 !default;
$pw-bg-color__senary:#212121 !default;


//Badge Background Colours..............................................

$pw-badge-bg-color:$pw_color_white !default;
$pw-badge-bg-color__primary:#ed3e84;
$pw-badge-bg-color__secondary:#994198 !default;
$pw-badge-bg-color__tertiary:$grey-200;
$pw-badge-bg-color__quaternary:#f7faff !default;
$pw-badge-bg-color__quinary:#FCF6F8 !default;

//Badge Text Colours..............................................

$pw-badge-text-color:$pw_color_white !default;
$pw-badge-text-color__primary:#ed3e84;
$pw-badge-bg-color__secondary:#994198 !default;
$pw-badge-bg-color__tertiary:$grey-200;
$pw-badge-bg-color__quaternary:#f7faff !default;
$pw-badge-bg-color__quinary:#FCF6F8 !default;


//Icon Colours..............................................

$pw-icon-color:$pw_color_white !default;
$pw-icon-color__primary:$grey-500;
$pw-icon-color__secondary:$grey-700 !default;
$pw-icon-color__tertiary:#ED3E84;
$pw-icon-color__quaternary:#F6F9FC !default;
$pw-icon-color__quinary:#F6F9FC !default;

$mandatory-text-color: #f44336;

//shadow Colors

$pw-box-shadow-color__primary:$grey-400;
$pw-box-shadow-color__secondary:$grey-500;
