.border{
  &--circle, circle{
    border-radius: 50%;
    display:flex;
  }
  &__r{
     border-radius: 0 0 5px 5px;
     &--3{
       border-radius: 3px;
     }
  }
  
}