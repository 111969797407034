@import "../themes/default/_variables-new.scss";
.mat-menu-content{
    @media only screen and (max-width: 1024px) {
        .booking-date-title-section{
            margin-bottom: 1rem;
            .booking-date-title{
                margin-top: 10px;
            }
            .w50-balance{
                width: 50%
            }
            .w100-balance{
                width: 100%
            }
        }
    }

}
.past-booking {
    .icon{
        &--small{
            font-size:16px !important;
        }

    }

    .sec-search{
        margin-left:24px;
        min-width: 260px;
         @media only screen and (max-width: 1024px) {
                margin: 0;
            }
         .mat-form-field{
              @media only screen and (max-width: 1024px) {
                width:100%;
            }
             .mat-form-field-wrapper{
                 padding:0;
                 margin:0;

                 .mat-form-field-infix{
                     margin-bottom:8px !important;
                     font-size:14px !important;


                 }
             }

             .search-icon{
                 position:relative;
                 top:6px;
             }
         }

    }

    .mmc_flex--container {
        width: 100%;
        height: 100%;
    }


    .mat-tab-label {
        .tab-text {
            display: block;
        }
    }
    .mat-tab-label:focus {
        border: 1px solid !important;
    }

    .title {
        margin-top:22px;
        padding: 15px 0px !important;


        &--txt-colr {
            color: $opt_color_primary;
        }

        .name {
            .title--sub {
                @include font-size(20);
                color: $opt-text-color__primary;
            }
        }
    }

    .filter-sec {
        justify-content: space-between;
        padding: 20px 0 !important;

        .mat-form-field{
            .mat-select{
                font-size:12px !important;
            }
        }
    }

    .booking-list {

        .not-found{
            justify-content:center !important;
        }


        background-color: $background-color_light;
        padding: 50px 20px;
        margin-bottom: 30px;

        .mmc_flex__item{
             @media only screen and (max-width: 1024px) {
                                        justify-content:flex-start;
                                    }

        }

        .mcc-wrap-items{
                       @media only screen and (max-width:1024px) {
                                        flex-wrap:wrap;
                                    }

                        }


        .mat-expansion-panel-header{
            min-height:65px;
           height: auto !important;
            padding: 5px 0px 5px 13px;


             @media only screen and (max-width: 1024px) {
                               height:auto !important;
                               align-items:stretch;
                               }




                               .icon__expand{
                                   @media only screen and (max-width: 1024px) {
                               align-items:flex-start;
                               }
                            }
                               .item-btn-wrapper{
                                @media screen and (max-width:768px) {
                                    margin-top: 5px;
                                    margin-bottom: 10px;
                                }
                                    @media only screen and (max-width: 1024px) {
                                width:100%;
                               }
                                   .btn-wrapper{
                                        @media only screen and (max-width: 1024px) {
                                 display:flex;
                                       width:100%;

                               }



                                        >button{
                                             @media only screen and (max-width: 1024px) {
                                  flex-grow:1;

                                        }
                                   }

                                        >a{
                                            @media only screen and (max-width: 1024px) {
                                 /*display:flex;*/
                                       width:100%;

                               }
                                        }
                               }

                               span{
                                   @media screen and (max-width:768px) {
                                       margin: 0 !important;
                                   }
                               }
                                   }

                               .mat-expansion-panel-body{
                                   /*padding: 0px 0px 0px 13px;*/

                                   position:relative;
                               }
        }

        .my-bookings-expansion-body {
            margin: 0px 0px 0px 0px;
            width: 100%;
            .not-found{
                white-space: break-spaces;
                text-align: center;
            }

            .pw-text-trans__upper{
                .status{
                    margin-left: 0px;
                }
            }

            @include respond-to("x-small") {
                // height:250px;
                height: 65px;
                width: 100%;
            }

            @include respond-to("small") {
                // height:250px;
                height: 65px;
                width: 100%;
            }

            @include respond-to("medium") {
                height:65px;
            }

            @include respond-to("large") {
                height:65px;
            }

            @include respond-to("x-large") {
               height:65px;
            }
            &--container {
                 @include respond-to("x-small") {
                flex-wrap: wrap;
            }

            @include respond-to("small") {
                flex-wrap: wrap;
            }

            @include respond-to("medium") {
                flex-wrap: nowrap;
            }

            @include respond-to("large") {
                flex-wrap: nowrap;
            }

            @include respond-to("x-large") {
                flex-wrap: nowrap;
            }
            }
        }

        @media screen and (max-width:450px) {
            .b-search-pagi{
                .mat-paginator .mat-paginator-container .mat-paginator-page-size{
                    width: 40% !important;
                }
                .mat-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-select{
                    width: 45% !important;
                }

                .mat-paginator .mat-paginator-container .mat-paginator-range-label{
                    margin: 0 5px 0 10px !important;
                }
            }

            .mat-paginator .mat-paginator-container .mat-paginator-range-actions{
                width: auto;
                button{
                    width: 15%;
                    justify-content: unset !important;
                }
                .mat-paginator-range-label{
                    width: 70%;
                }
            }
        }
        @media screen and (min-width:450px) and (max-width:768px) {
            .b-search-pagi{
                .mat-paginator .mat-paginator-container .mat-paginator-page-size{
                    width: unset !important;
                }
                // .mat-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-select{
                //     width: unset !important;
                // }
            }

            .mat-paginator .mat-paginator-container .mat-paginator-range-actions{
                width: unset !important;
                justify-content: unset !important;
                button{
                    width: unset !important;
                    justify-content: unset !important;
                }
                .mat-paginator-range-label{
                    width: unset !important;
                }
            }
        }
    }

    &--card {
        margin-bottom: 30px;
        @include box_shadow (2);
        padding: 20px;
        border: 1px solid $opt-border-color;
        border-radius: 4px;
        position: relative;
        background-color: $opt_color_white;

        > .mmc_flex--container {
            @include respond-to("x-small") {
                flex-wrap: wrap;
            }

            @include respond-to("small") {
                flex-wrap: wrap;
            }

            @include respond-to("medium") {
                flex-wrap: nowrap;
            }

            @include respond-to("large") {
                flex-wrap: nowrap;
            }

            @include respond-to("x-large") {
                flex-wrap: nowrap;
            }
        }

        .detail-btn, .btn-wrapper {
            @include respond-to("x-small") {
                width: 100%;
            }

            @include respond-to("small") {
                width: 100%;
            }

            @include respond-to("medium") {
                width: auto;
            }

            @include respond-to("large") {
                width: auto;
            }

            @include respond-to("x-large") {
                width: auto;
            }
        }

        &-status {
            @include box_shadow (1);
            color: white;
            position: absolute;
            top: -20px;
            padding: 5px;

            &.pending {
                background-color: $refund_status_darker;
            }

            &.paid {
                background-color: $success-color--state;
            }

            &.cancel {
                background-color: $opt_text_color_tertiary;
            }
        }

        .price-sec {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .booking-title {
                img {
                    margin-right: 5px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .navbar-collapse{
        // margin:0 -40px !important;

        @media screen and (max-width:768px) {
            margin: 0 !important;
        }

        .header__alter-menu{
            .sub{
                @media screen and (max-width:768px) {
                    margin: 0 5px 0 0 !important;
                    width: 100% !important;
                }
            }
            .sub_left span{
                @media screen and (max-width:768px) {
                    height: 25px;
                    min-width: 200px;
                    padding-right: 10px;
                    display: flex;
                    align-items: center;
                    transition: 0.3s;
                    text-decoration: unset !important;
                    justify-content: flex-start;
                }
            }

            .col-md-8.sub.wm-top-name.align-items-center{
                @media (min-width: 769px){
                  flex: 0 0 66.66666667% !important;
                  max-width: 66.66666667% !important;
                }
                @media (max-width: 768px){
                  flex: 0 0 100% !important;
                  max-width: 100% !important;
                }
            }
        }
    }



}


.past-booking{
    .mat-tab-label {
        .tab-text{
            display: block;
        }
    }
    .title{
        padding: 15px;

        &--txt-colr{
            color:$pw-text-color__quinary;
        }
        .name{
            .title--sub{
                @include font-size(20) ;
                color: $opt-text-color__primary;
            }
        }

    }
    .filter-sec{
        justify-content: space-between;
        padding: 20px;
    }
    .booking-list{
        background-color: $background-color_light;
        padding: 50px 20px;
        margin-bottom: 30px;
        .mat-expansion-panel-body{
            // padding: 0px 0px 0px 16px !important;
            padding: 0px 0px 0px 0px !important;
            background-color:#FCF6F8 !important;
            border-left: 4px solid #ED3E84;
            overflow:hidden;

            .mmc_expansion__body--row-header{
                padding: 0px 0px 0 0px;
            }
        }

        .mmc_flex--container{
            width:100%;
        }

        .mat-raised-button{
            margin: 0px 5px !important;
        }
    }

    }

.seperator{
    line-height:1.5;
    padding:0 1px;
    letter-spacing: 0.15rem;
}


.clear-all{
    // position: relative;
    display: flex;
    // right: 73px;
    // top: -9px;

    button{
        width: 100%;
        display: flex;
        line-height: 1.3125rem !important;
    }
    }


/*.edit-modal {
    .mat-datepicker-content {
        .mat-calendar {
            @media only screen and (max-height: 530px) {
                width: 266px !important;
                height: 314px !important;
            }
        }
    }
}*/
@media screen and (max-width:768px) {
    .past-booking-container{
        .mat-tab-group{
            .mat-tab-header{
                .mat-tab-header-pagination{
                    display: none;
                }
            }
            .mat-tab-label-container{
                .mat-tab-list{
                    .mat-tab-labels{
                        .mat-tab-label{
                            padding: 0 10px !important;
                            // left: -10px;

                            .mat-tab-label-content{
                                span{
                                    font-size: 14px !important;
                                }

                            }
                        }
                    }
                    .mat-ink-bar{
                        width:190px !important;
                        display: none;
                    }
                }
            }
        }
    }
}

.mmc_flex__item.mmc_flex__item--vcenter.mmc_just-content-end.booking-filter.p33 {
    width: auto !important;
}
.mat-download {
    text-align: center;
    line-height: 1;
    margin-right: 20px;
    color: #616161 !important;

    .material-icons {
        color: #616161;
    }
}
