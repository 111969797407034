@import "../components/buttons";
@import "../helpers/mixins";

.mmc_ {
  &payment {
    padding: 20px 0;
    margin: 50px auto;
    background-color: #fff;
    @include font-size(16);
    .flex-column {
      padding: 20px;
      flex-direction: column;
    }
    .main-row {
      @include padding-all(10);
    }
    .mat-form-field {
      display: block;     
    }
    .pay-amount{
             .mat-input-element{
                  text-align: left !important;
              }
    }
    .val-sec {
      @include padding-left(10);
    }
    .btn-section {
      margin: auto;

      //   width: 100%;
    }
  }
}
body{
    overflow-x:hidden;
}

.btn_mobile__pay{
    background: #383838;
    color: white;
    border: none;
    border-radius: 3px;
    font-size: 14px !important;
    box-shadow: 1px 2px 3px #dddddd;
     @include respond-to("x-small") {
            padding: 0px 10px !important;
             height: 40px  !important;
             font-size: 12px  !important;
             width: 100%  !important;
        }
        @include respond-to("small") {
            padding: 0px 20px !important;
             height: 40px  !important;
             font-size: 16px  !important;
             width: 100%  !important;
        }
        @include respond-to("medium") {
            padding: 0px 20px !important;
             height: 40px  !important;
             font-size: 16px  !important;
             width: 100%  !important;
        }
        @include respond-to("large") {
              padding: 0.5rem 1rem;
             height: 40px  !important;
             font-size: 16px  !important;
             width: 100%  !important;
        }
        @include respond-to("x-large") {
              padding: 0.5rem 1rem;
             height: 40px  !important;
             font-size:16px ;
             width: 100% !important;
        }
}

/* Chrome, Safari, Edge, Opera */
input#amountInput::-webkit-outer-spin-button,
input#amountInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number]#amountInput {
    -moz-appearance: textfield;
}
/* Chrome, Safari, Edge, Opera */
input#amountInput1::-webkit-outer-spin-button,
input#amountInput1::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number]#amountInput1 {
    -moz-appearance: textfield;
}



.cog{
    .btn-section{
     .buttons-secondary{
             border-radius: 5px !important;
           border: 1px solid #00507c !important;
            background-color: #00507c !important;
            color: #fff !important;
            cursor: pointer;
    }

    .buttons-secondary:hover{
           background-color: #EF6C00 !important;
            border: 1px solid #EF6C00 !important;
            color:#fff !important;
            cursor: pointer;
    }
}
}

.iframe-form {   
  @include respond-to("x-small") {
    min-height: 64rem !important;
    // margin-top: 10rem;
  }
  @include respond-to("small") {
    min-height: 64rem !important;
  }
  @include respond-to("medium") {
    min-height: 85vh !important;
  }
  @include respond-to("large") {
    min-height: 88vh !important;
  }
  @include respond-to("x-large") {
    min-height: 90vh !important;
  }
  
  @media print{
    min-height: 19cm !important;
  }
}


/* Chrome, Safari, Edge, Opera */
input#amountInput::-webkit-outer-spin-button,
input#amountInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number]#amountInput {
    -moz-appearance: textfield;
}
/* Chrome, Safari, Edge, Opera */
input#amountInput1::-webkit-outer-spin-button,
input#amountInput1::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number]#amountInput1 {
    -moz-appearance: textfield;
}
