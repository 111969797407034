﻿//Corporate Customer
.corporate-customer-wrapper, .individual-customer-wrapper, .change-password-wrapper{
        .cus-container{
        @media only screen and (max-width: 425px) {
                                        flex-wrap:wrap !important;
                                    }
        .customer-data{
            @media only screen and (max-width: 768px) {
                                        width:70% !important;
                                    }
        .customer-btn{
            @media only screen and (max-width: 768px) {
                                        width:30% !important;
                                    }
        }
    }
    }

         .customer-title-row{
        .dflex{
            display:flex;
            justify-content:flex-end;
            align-items:center;
        }
        .mmc-btn-secondary{
            border: 1px solid #044fa2 !important;
            color: #044fa2 !important;
            transition:0.3s;
            .mat-button-wrapper{
                display:flex;
                align-items:center;
            }
            .material-icons{
                color:#044fa2 !important;
            }
            &:hover{
                background-color: #044fa2 !important;
                color: #fff !important;
                .material-icons{
                    color:#fff !important;
                }
            }
        }
    }


    
    .account-customer{
    &-name{
        display:flex;
        background-color: #f6f6f6;
        border-radius: 3px;
        padding: 10px 10px 10px 20px;
        border-left: 8px solid #994198;
    }
    &-detail{
        background-color: #f6f6f6;
        border-radius: 3px;
        padding: 10px 10px 10px 20px;
        display:flex;
    }
    }
    .customer-bio-row{
        margin-top:20px;
        border-radius:3px;
        .left-col{
            padding-left:0px;
        }
        .right-col{
            padding-right:0px;
        }
    }
    .inner-container{
        background-color: $pw-bg-color__secondary;
        padding: 15px;
        margin:20px -15px 20px -15px;
        .mat-form-field{
            width:100%;
            .mat-form-field-outline{
                border-radius:5px;
                background-color:$pw-bg-color;
            }
            &.mat-form-field-disabled{
                .mat-form-field-outline{
                    background-color:$pw-bg-color__tertiary;
                }
                /*.mat-form-field-label-wrapper{
                    top:-18px;
                }
                .mat-form-field-label{
                    color: #006ff8;
                }*/
            }
            .mat-form-field-flex{
                 &:hover{
                     .mat-form-field-outline-thick{
                         color:#006ff8;
                     }
                 }
             }
        }
        .sub-section-title{
            align-items:center;
        }
    }
    .title-primary{
        padding: 16px 0px;
    }
    hr{
        position: relative;
        top: 5px;
    }
    .add-more-btn{
        border: 1px dotted #91a0ad;
        height: 60px;
        color:#91a0ad;
        position: relative;
        bottom: 11px;
        margin-left: 5px;
        transition:0.3s;
        &:hover{
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            background-color: #006ff8;
            color: #ffffff !important;
            border: 1px solid #006ff8 !important;
            text-align: center !important;
        }
    }


    .request-access-txtarea{
        
        border-radius:5px !important;

        .mat-form-field-underline{
            display:none !important;
        }
        .mat-form-field-infix{
            background-color: #fff !important;
        padding:5px !important;
        border-radius:5px !important;

        .mat-input-element{
            min-height:100px !important;
        }
        }
    }
}
.input-error {
    border: red 1px solid;
    color: red; 
    .mat-form-field-wrapper {
        padding-bottom: 5px !important;
    }
}

.input-error {
    border: red 1px solid;
    color: red; 
    .mat-form-field-wrapper {
        padding-bottom: 5px !important;
    }
}

//Individual Customer

//Change Password
.change-password-wrapper{
    .sub-section-title{
        margin: 0 0 10px 0;
    }
    .suggestion-label{
        margin-bottom:10px;
    }
    .check-icon{
        color:#3d9b47;
        margin-right:10px;
    }
}

//Global
@mixin title{
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    line-height: 2.25rem;
    word-spacing: 0.24rem;
    font-weight: 600;
}
@mixin section-title {
    font-size: 1.375rem;
    letter-spacing: 0.05rem;
    line-height: 2.0625rem;
    word-spacing: 0.22rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
        font-size: 1rem;
        letter-spacing: normal;
        line-height: normal;
        word-spacing:normal;
    }
}
@mixin sub-section-title{
    font-size: 1.25rem;
    letter-spacing: 0.05rem;
    line-height: 1.875rem;
    word-spacing: 0.2rem;
    color: #3a424d;
    margin: 20px 0px;
    font-weight:600;
}

@mixin secondary_title{
    font-size: 1rem;
    letter-spacing: 0.05rem;
    line-height: 1rem;
    word-spacing: 0.24rem;
    font-weight: 600;
}
@mixin secondary_section-title{
    font-size: 12px;
    letter-spacing: 0.05rem;
    line-height: 1.25rem;
    word-spacing: 0.22rem;
    /*font-weight: 600;*/
}
@mixin secondary_sub-section-title{
    font-size: 12px;
    letter-spacing: 0.05rem;
    line-height: 1.25rem;
    word-spacing: 0.2rem;
    color: #3a424d;
    font-weight:600;
}


@mixin quaternary-title{
    font-size: 14px;
    letter-spacing: 0.05rem;
    line-height: 1.25rem;
    word-spacing: 0.2rem;
    color: #3a424d;
    font-weight:600;
}


@mixin ternary_title{
    font-size: 12px;
    letter-spacing: 0.05rem;
    line-height: 1.25rem;
    word-spacing: 0.2rem;
    font-weight: 400;
}

@mixin link-primary{
    font-size: 0.875rem;
    letter-spacing: 0.05rem;
    line-height: 1.25rem;
    word-spacing: 0.2rem;
    color: #2196F3;
    font-weight:400;
    text-decoration:underline;
    cursor:pointer;
}

@mixin status-text{
    font-size: 0.625rem;
    letter-spacing: 0.015rem;
    line-height: 1.25rem;
    word-spacing: 0.2rem;
    font-weight:500;
    text-transform:uppercase;
}

@mixin status{
    height: auto !important;
    margin-left:3px;
    font-size: 0.6rem;
    letter-spacing: normal !important;
    /*line-height: 1.25rem;*/
    font-weight:500;
    width:auto;
    padding: 0 8px;
    /*max-width: 110px;*/
    min-width:50px;
    height:16px;
    border:solid 1px #fff !important;
    border-radius:20px;
    color:#ffffff !important;
    text-align:center;
}

@mixin card-status{
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        color: white;
        padding: 5px;
        height:30px;
        width:auto;
        min-width:160px;
        margin-bottom: 5px;
        font-size:0.75rem;
            display: flex;
    justify-content: center;
    align-items: center;
}




/*.title{
    @include title;
    &-primary{
        @include title;
        color: #a7aaaf;
    }
    &-secondary{
        @include title;
        color: #ed3e84;
    }
}*/

.section-title{
    @include section-title;
    color: #3a424d;
    word-break: break-word;
    &-secondary{
        @include section-title;
        color: #ed3e84;
    }
}
.sub-section-title{
    @include sub-section-title;
}

.secondary_title{
    @include secondary_title;
    &-primary{
        @include secondary_title;
        color: #a7aaaf;
    }
    &-secondary{
        @include secondary_title;
        color: #ed3e84;
    }
}

.secondary_section-title{
         @include secondary_section-title;
         color: #000000;
        font-weight:400;
    
    &--medium{
         @include secondary_section-title;
          color: #000000;
        font-weight:500 !important;
    }
    &--semibold{
         @include secondary_section-title;
          color: #000000;
        font-weight:600 !important;
    }


    &-secondary{
        @include secondary_section-title;
        color: #616161;
        font-weight:400;

         
    &--medium{
         @include secondary_section-title;
         color: #616161;
        font-weight:500 !important;
    }
    &--semibold{
         @include secondary_section-title;
         color: #616161;
        font-weight:600 !important;
    }

    }

    &-ternary{
        @include secondary_section-title;
        color: #F44336;
        font-weight:400;

         
    &--medium{
         @include secondary_section-title;
         color: #F44336;
        font-weight:500 !important;
    }
    &--semibold{
         @include secondary_section-title;
         color: #F44336;
        font-weight:600 !important;
    }

    }
}
.secondary_sub-section-title{
         @include secondary_sub-section-title;
         color: #000000;
        font-weight:400;
    
    &--medium{
         @include secondary_sub-section-title;
          color: #000000;
        font-weight:500 !important;
    }
    &--semibold{
         @include secondary_sub-section-title;
          color: #000000;
        font-weight:600 !important;
    }


    &-secondary{
        @include secondary_sub-section-title;
        color: #616161;
        font-weight:400;

         
    &--medium{
         @include secondary_sub-section-title;
        color: #616161;
        font-weight:500 !important;
    }
    &--semibold{
         @include secondary_sub-section-title;
         color: #616161;
        font-weight:600 !important;
    }

    }
}

.ternary-title{
         @include ternary_title;
         color: #000000;
        font-weight:400;
    
    &--medium{
         @include ternary_title;
          color: #000000;
        font-weight:500 !important;
    }
    &--semibold{
         @include ternary_title;
          color: #000000;
        font-weight:600 !important;
    }

    &--bold{
         @include ternary_title;
          color: #000000;
        font-weight:700 !important;
    }


    &-secondary{
        @include ternary_title;
        color: #616161;
        font-weight:400;

         
    &--medium{
         @include ternary_title;
         color: #616161;
        font-weight:500 !important;
    }
    &--semibold{
         @include ternary_title;
         color: #616161;
        font-weight:600 !important;
    }
    &--bold{
         @include ternary_title;
         color: #616161;
        font-weight:700 !important;
    }

    }
}

.link-primary{
    @include link-primary;
}

.status-text{
     @include status-text;
    &-paid{
       
        color:#009688;
    }
    &-pending-approval{
        @include status-text;
        color:#FFC107;
    }
     &-approved{
        /*@include status;*/
         color:#4CAF50;
    }

     &-pending-payment{
        @include status-text;
         background-color:#8BC34A;
    }

    &-pending-cancel{
        @include status-text;
         background-color:#607D8B;
    }

    &-cancelled{
        @include status-text;
        color:#9E9E9E;
    }

     &-unpaid{
        @include status-text;
        background-color:#FF5722;
    }

      &.completed{
        @include status-text;
        background-color:#03A9F4;
    }
}

.status{
    background-image:none !important;
    max-width: 160px !important;
}

.status{
    @include status;
     &.paid{
        
        background-color:#009688;
        max-width: 160px;
    }
    &.pending-approval{
        /*@include status;*/
         background-color:#FFC107;
    }

    &.approved{
        /*@include status;*/
         background-color:#4CAF50;
    }

    &.pending-payment{
        /*@include status;*/
         background-color:#8BC34A;
    }

    &.pending-cancel{
        /*@include status;*/
         background-color:#607D8B;
    }

    &.cancelled{
        /*@include status;*/
        background-color:#9E9E9E;
    }

     &.unpaid{
        /*@include status;*/
        background-color:#FF5722;
        max-width: 110px !important;
    }

      &.completed{
        /*@include status;*/
        background-color:#03A9F4;
    }
}

.card-status {
     @include card-status;
        &.paid {
           
            background-color: #009688;
        }

        &.pending-approval{
            /*@include card-status;*/
            background-color: #FFC107;
        }

         &.approved{
        /*@include status;*/
         background-color:#4CAF50;
    }

        &.pending-payment{
            /*@include card-status;*/
            background-color: #8BC34A;
        }

        &.pending-cancel{
            /*@include card-status;*/
            background-color: #607D8B;
        }

        &.cancelled{
        /*@include card-status;*/
        background-color:#9E9E9E;
    }
         &.unpaid{
        /*@include status;*/
        background-color:#FF5722;
    }

          &.completed{
        /*@include status;*/
        background-color:#03A9F4;
    }
    }
    






.no-left-padding{
    padding-left:0px;
}
.no-right-padding{
    padding-right:0px;
}

.main-time-pick{
    .mat-form-field{
    &.mat-form-field-should-float{
        .mat-form-field-label{
            //top: 30px !important;
            font-size:0.875rem;
            color: $pw-text-color__primary !important;
            font-weight: 600 !important;    
        }
    }
}
}

.mat-form-field{
    &.mat-form-field-should-float{
        .mat-form-field-label{
            //top: 30px !important;
            font-size:0.875rem;
            color: $pw-text-color__tertiary;
            font-weight: 600 !important;    
        }
    }
}
//Buttons
.mmc-btn{
    &-primary{
    color: #006ff8 !important;
    text-align: center !important;
    justify-content:center;
    border-radius: 4px;
    border: 1px solid #006ff8 !important;
    transition: 0.3s !important;
    background-color: #ffffff;
    height:40px;
    width:auto;
    min-width:88px;
    margin: 0px 5px;
    &:hover{
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: #006ff8;
        color: #ffffff !important;
        border: 1px solid #006ff8 !important;
        text-align: center !important;
    }

    &.dissabled{
    color: #9e9e9e !important;
    text-align: center !important;
    justify-content:center;
    /*border: 1px solid #006ff8 !important;*/
    transition: 0.3s !important;
    background-color: #e9e9e9;
    height:40px;
    width:auto;
    min-width:88px;
    margin: 0px 5px;
    border:none !important;
    box-shadow:none !important;
    
    &:hover{
        cursor:not-allowed;
        border:none !important;
        box-shadow:none !important;
        /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: #006ff8;
        color: #ffffff !important;
        border: 1px solid #006ff8 !important;
        text-align: center !important;*/
    }
}

    &--small{
        @extend .mmc-btn-primary;
        height:36px !important;
        font-size:13px !important;

        &.dissabled{
    color: #9e9e9e !important;
    box-shadow:none !important;
    text-align: center !important;
    justify-content:center;
    /*border: 1px solid #006ff8 !important;*/
    transition: 0.3s !important;
    background-color: #e9e9e9;
    height:40px;
    width:auto;
    min-width:88px;
    margin: 0px 5px;
    border:none !important;
    
    &:hover{
        box-shadow:none !important;
        cursor:not-allowed;
        border:none !important;
        /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: #006ff8;
        color: #ffffff !important;
        border: 1px solid #006ff8 !important;
        text-align: center !important;*/
    }
}
    }
}

    &-secondary{
    color: #ffffff !important;
    text-align: center !important;
    border-radius: 4px;
    justify-content:center;
    border: 1px solid #006ff8 !important;
    transition: 0.3s !important;
    background-color: #006ff8;
    height:40px;
    width:auto;
    min-width:88px;
    margin: 0px 5px;
    &:hover{

        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: #006ff8;
        color: #ffffff !important;
        border: 1px solid #006ff8 !important;
        text-align: center !important;

        &.dissabled{
            box-shadow:none !important;
    color: #9e9e9e !important;
    text-align: center !important;
    justify-content:center;
    /*border: 1px solid #006ff8 !important;*/
    transition: 0.3s !important;
    background-color: #e9e9e9;
    height:40px;
    width:auto;
    min-width:88px;
    margin: 0px 5px;
    border:none !important;
    
    &:hover{
        box-shadow:none !important;
        cursor:not-allowed;
        border:none !important;
        /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: #006ff8;
        color: #ffffff !important;
        border: 1px solid #006ff8 !important;
        text-align: center !important;*/
    }
}
    }

    &--small{
        @extend .mmc-btn-secondary;
        height:36px !important;
        font-size:13px !important;

        &.dissabled{
            box-shadow:none !important;
    color: #9e9e9e !important;
    text-align: center !important;
    justify-content:center;
    /*border: 1px solid #006ff8 !important;*/
    transition: 0.3s !important;
    background-color: #e9e9e9;
    height:40px;
    width:auto;
    min-width:88px;
    border:none !important;
    margin: 0px 5px;
    
    &:hover{
        box-shadow:none !important;
        cursor:not-allowed;
        border:none !important;
        /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: #006ff8;
        color: #ffffff !important;
        border: 1px solid #006ff8 !important;
        text-align: center !important;*/
    }
}
    }
}

    &-ternary{
    color: #ffffff !important;
    text-align: center !important;
    justify-content:center;
    border: 1px solid #ed3e84 !important;
    transition: 0.3s !important;
    background-color: #ed3e84;
    height:40px;
    width:auto;
    min-width:88px;
    margin: 0px 5px;
    &:hover{
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: #ed3e84;
        color: #ffffff !important;
        border: 1px solid #ed3e84 !important;
        text-align: center !important;

        &.dissabled{
            box-shadow:none !important;
    color: #9e9e9e !important;
    text-align: center !important;
    justify-content:center;
    /*border: 1px solid #006ff8 !important;*/
    transition: 0.3s !important;
    background-color: #e9e9e9;
    height:40px;
    width:auto;
    min-width:88px;
    margin: 0px 5px;
    border:none !important;
    
    &:hover{
        box-shadow:none !important;
        cursor:not-allowed;
        border:none !important;
        /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: #006ff8;
        color: #ffffff !important;
        border: 1px solid #006ff8 !important;
        text-align: center !important;*/
    }
}
    }

    &--small{
        @extend .mmc-btn-ternary;
        height:36px !important;
        font-size:13px !important;

        &.dissabled{
            box-shadow:none !important;
    color: #9e9e9e !important;
    text-align: center !important;
    justify-content:center;
    /*border: 1px solid #006ff8 !important;*/
    transition: 0.3s !important;
    background-color: #e9e9e9;
    height:40px;
    width:auto;
    min-width:88px;
    margin: 0px 5px;
    border:none !important;
    
    &:hover{
        box-shadow:none !important;
        cursor:not-allowed;
        border:none !important;
        /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: #006ff8;
        color: #ffffff !important;
        border: 1px solid #006ff8 !important;
        text-align: center !important;*/
    }
}
    }
}

    &-dissable{
    color: #9e9e9e !important;
    text-align: center !important;
    justify-content:center;
    /*border: 1px solid #006ff8 !important;*/
    transition: 0.3s !important;
    background-color: #e9e9e9;
    height:40px;
    width:auto;
    min-width:88px;
    margin: 0px 5px;
    border:none !important;
    
    &:hover{
        cursor:not-allowed;
        border:none !important;
        /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: #006ff8;
        color: #ffffff !important;
        border: 1px solid #006ff8 !important;
        text-align: center !important;*/
    }

    &--small{
        @extend .mmc-btn-secondary;
        height:36px !important;
        font-size:13px !important;
    }
}


    }




.mmc-btn-xl {
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 96px;
    width: auto;
    line-height:1rem;
    font-size: 0.625rem;
    /*padding: 10px;*/
    justify-content: center;
    border: 1px dotted #707070 !important;
    border-radius: 5px;
    transition: 0.3s;
    text-transform:uppercase;
    background-color:#fff;
    line-height:18px !important;
    /*word-wrap:break-word;*/

    &--request_changes:focus ,&--rollover_booking:focus {
        border: 1px solid #000 !important;
    }

    @media only screen and (max-width: 768px) {
                                        min-width:70px;
                                    }




    &:hover {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);       
        color: #ffffff !important;
        }

     &--request_changes {
            @extend .mmc-btn-xl;
            background-color:#fff;
            color:#9C27B0;
             &:hover {
            background-color: #9C27B0;
            border: 1px dotted #fff !important;
        }
           
        }
        

      &--rollover_booking {
            @extend .mmc-btn-xl;
            background-color:#fff;
            color:#3F51B5;
             &:hover {
            background-color: #3F51B5;
            border: 1px dotted #fff !important;
        }
           
        }

        &--primary {
            @extend .mmc-btn-xl;
            background-color:#fff;
            color:#009688;
             &:hover {
            background-color: #009688;
            border: 1px dotted #fff !important;
        }
           
        }

        &--secondary {
            @extend .mmc-btn-xl;
            color:#2196F3;
            background-color:#fff;
             &:hover {
            background-color: #2196F3;
            border: 1px dotted #fff !important;
        }
        }

        &--ternary {
            @extend .mmc-btn-xl;
            color:#F44336;
            background-color:#fff;
             &:hover {
            background-color:#F44336;
            border: 1px dotted #fff !important;
           }
            .mat-button-wrapper{
                    .btn-label{
            width:90px !important;
            white-space:normal;
            text-align:left;
            margin-right:8px;
            font-size:0.625rem;
            }
                    }      
             }

             &--fourth {
            @extend .mmc-btn-xl;
            color:#ed3e84;
            background-color:#fff;
             &:hover {
            background-color:#ed3e84;
            border: 1px dotted #fff !important;
        }
        }

             &--quaternary {
            @extend .mmc-btn-xl;
            background-color:#FF5722 ;
            border: 1px dotted #fff !important;
            color:#fff !important;
             &:hover {
            background-color:#FF5722;
            border: 1px dotted #fff !important;
        }
        }

                &--request_changes{
            @extend .mmc-btn-xl;
            background-color:#fff;
            /*border: 1px dotted #fff !important;*/
            color:#3F51B5 !important;
             &:hover {
            background-color:#3F51B5;
            border: 1px dotted #fff !important;
            color:#fff !important;
        }
        }

                   &--rollover_booking{
            @extend .mmc-btn-xl;
            background-color:#fff;
            /*border: 1px dotted #fff !important;*/
            color:#9C27B0 !important;
             &:hover {
            background-color:#9C27B0;
            color:#fff !important;
            border: 1px dotted #fff !important;
        }
        }

            


    
}


             .btn-icon{
    margin-right:8px;
}
.btn-label{
    width:90%;
    white-space:normal;
    text-align:left;
    margin-right:8px;
    font-size:0.625rem;
}

.font__mcc{
    color:#ed3e84;
}

.font__white{
    color:#ffffff;
}

.mmc-btn-symbol{
    color: #91a0ad !important;
    display:flex;
    align-items:center;
    justify-content:center;
    border: 1px solid #91a0ad !important;
    transition: 0.3s !important;
    background-color: #ffffff;
    height:20px;
    width:20px;
    min-width:unset;
    line-height:20px;
    &:hover{
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: #006ff8;
        color: #ffffff !important;
        border: 1px solid #006ff8 !important;
        text-align: center !important;
    }
}
.error-message{
    font-size: 12px;
    font-size: 0.75rem;
    letter-spacing: 0.09rem;
    line-height: 1.125rem;
    word-spacing: 0.12rem;
    font-weight: 500;
    color: #f44336;
}

.expansion-loader{
    min-height: 121px;
    height: 100%;
    background-color: #9a9a9a;
    width: 100%;
    display: flex;
    align-content: stretch;
    position: absolute;
    margin-left: -24px;
    opacity: 0.5;
}


.sub_left{
    float:left;
    @media screen and (max-width: 415px) {
        flex-direction: column;
        align-items: start;
        .wp-mr-20 {
            // padding-top: 10px !important;
        }
        .header-parent {
            padding: 10px 0;
        }
        .header-before-regular {
            margin-bottom: 10px;
        }
    }
}

.user__home{
      cursor: pointer;
      /*color:#000000;*/
    background-color: unset;
    width: 40px;
    height: 32px;
    align-items: center;
     @media only screen and (max-width: 768px) {
         padding-right:5px;
         width: auto;
         height: auto;
                        }
}

.header__alter-menu{
    justify-content: space-between;
}

.userprofile-wrapper{
    display: flex;
    @media screen and (max-width:400px) {
    //   padding-top: 10px !important;
      flex-direction: row;
      width: 100%;
    //   justify-content: space-between;
    }
  }
  .sub {
    @media screen and (max-width:400px) {
      flex-direction: column;
      display: flex;
      align-items: flex-end;
    }
  }
  .user__left{
    @media screen and (max-width:400px) {
      border-left: none !important;
      padding-bottom: 10px;
    }
  }
  .wm-login-user-profile-icon-wrapper{
    @media only screen and (max-width:400px) {
      width: 12% !important;
    }
  }
  .wm-sign-in-btn{
    @media only screen and (max-width:400px) {
      width: 88% !important;
      justify-content: center;
      a {
        padding: 0 !important;
      }
    }
  }

@media only screen and (max-width: 768px) {
    .header__alter-menu{
        display:flex;
        align-items:center;
        flex-wrap:wrap;

        flex-direction: column;
        justify-content: center;
       
        
    }

    span.wp-txt-body1--small.wm-signIn-user-icon.wm-profile-img.ng-star-inserted {
        padding-right: unset;
        padding-bottom: unset;
        min-width: unset !important;
    }

    .sub_left {
        
          border-bottom: 1px solid #b7b7b7;
          min-height: 70px;
          width: 100% !important;
          flex-direction: column;
          align-items: flex-start;
          padding-bottom: 10px !important;
          padding-top: 10px;
        
      }
      .sub {
        
          min-height: 70px;
          width: 100% !important;
          justify-content: space-between !important;
        
      }
    // .sub_left{
    //     padding-bottom: 10px !important;
    //     padding-top: 10px;
    //     width: unset !important;
    //     min-height: unset !important;
    //     .wp-mr-20 {
    //         @media screen and (max-width: 768px) {
    //             width: 80px;
    //         }
    //     }
    // }
    // .sub{
    //     width: unset !important;
    // }
}

.btn-wrapper, .button-wrapper{
    a:hover{
        text-decoration:none !important;
    }
}


.pl-8{
    padding-left:8px;
}

.mat-button[disabled]{
    border: none !important;
    /*border: 1px solid #9e9e9e !important;*/
    color: #9e9e9e !important;
    box-shadow: none !important;
    background-color: #e9e9e9;
    &:hover{
            box-shadow: none !important;
            cursor: not-allowed;
            border: none !important;
    }
            }

            .w50{
                width:50px;
            }
            .w90{
                width:90px;
            }

.w120{
    width:120px;
}

.w130{
    width:130px;
}

.curser{
    cursor: pointer;
}

  .mat-calendar {
            @media only screen and (max-height: 530px) {
                width: 266px !important;
                height: 314px !important;
            }

             @media only screen and (max-height: 480px) {
                width: 236px !important;
                height: 284px !important;
            }
        }

   .request-access-txtarea{
        
        border-radius:5px !important;

        .mat-form-field-underline{
            display:none !important;
        }
        .mat-form-field-infix{
            background-color: #fff !important;
        padding:5px !important;
        border-radius:5px !important;

        .mat-input-element{
            min-height:100px !important;
        }
        }
    }


  .profile-image-row{
      .user-profile{
          background-color:#fff;
          padding:15px;
          border-radius:5px;
          height:150px;
          border:1px solid #eee;

             .profile-image{
      .img-circle{
          border-radius: 50%;
          border: 2px solid #044fa2 !important;
      }
  }

             .upload-require-message{
                 height:36px;
                 width:auto;
                 color:#2196F3;
                 background-color:#E3F2FD;
                 border:1px solid #2196F3;
                 border-radius:3px;
                 text-align:center;
                 margin-bottom:16px;
                 display:flex;
                 align-items:center;
                 justify-content:center;
             }
      }
    
  }
  .dp-upload-wrapper{
      display:flex;
      background-color: #fff;
      padding: 20px;
      align-items: center;
      margin-top:20px;
      //flex-wrap:wrap;
      .profile-image-row{
          width:100%;
          @media only screen and (max-width: 1024px) {
                width:100% !important;
          }
          .user-profile{
              padding: 15px 15px;
              height:120px;
              margin-top: 50px;
              display: flex;
              flex-direction: column;
              /* align-content: center; */
              align-items: center;
              justify-content: center;
              @media only screen and (max-width: 1024px) {
                 margin-top: 0px;
                 margin-bottom:15px;
              }
              @media only screen and (max-width: 768px){
                display: flex;
                flex-direction: column;
                height: 132px !important;
              }
              .mmc_flex__item--inherit.mmc_flex__item--vcenter{
                @media only screen and (max-width: 768px){
                    justify-content: center;
                    margin-top: 8px;
                  }
              }
              .mmc_flex__item.mmc_fd--col.mmc_flex__item--hcenter.ml-5{
                @media only screen and (max-width: 768px){
                    margin: 0 !important;
                }

                .mmc_flex__item{
                    @media only screen and (max-width: 768px){
                        justify-content: center;
                        display: flex;
                    }
                }
              }
          }
      }
      .document-upload{
          width: 100%;
          padding: 0;
          margin:0 15px;
          background: none !important;
          @media only screen and (max-width: 1024px) {
                width:100% !important;
          }
      }
  }


  //regular booking scss
  .changes-summery-wrapper{
        .mmc-btn-tertiary{
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                font-family: 'Roboto', sans-serif !important;
                font-size: 16px !important;
                text-align: center !important;
                border: 1px solid #2574A9;
                color: #2574A9 !important;
                background-color: #ffffff;
            }

            .mmc-btn-tertiary:hover{
                background-color: #2574A9;
                color: #ffffff !important;
}
  }


  .mmc-btn-tertiary{
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                font-family: 'Roboto', sans-serif !important;
                font-size: 16px !important;
                text-align: center !important;
                border: 1px solid #2574A9;
                color: #2574A9 !important;
                background-color: #ffffff;
            }

            .mmc-btn-tertiary:hover{
                background-color: #2574A9;
                color: #ffffff !important;
}

  /*<div class="row changes-summery-wrapper">
                <div class="col-sm-12">
                    <div class="mmc_flex__item mmc_flex__item--vcenter pw-txt-h5 pw-text-color__primary">9 Booking changed to be reviewed</div>
                    <div class="btn-wrapper"><button class="mmc-btn-tertiary" mat-button>View All</button></div>

                </div>
            </div>*/




  //file upload

  .mmc_corporate, individual_customer{
      .booking-document-upload{
          .upload-doc{
              margin-top:8px;

                 .file-upload--card{
            padding: 15px;
            text-align: center;

            .mmc_flex--container{
                justify-content:center !important;
            }

            .file-drop{
                height: 100px;
                border: 1px dotted #616161;
                border-radius: 5px;
                display:flex;
                align-items:center;
                justify-content:center;
                flex-direction:column;

                .drop-zone{
                    .content{
                        div:nth-child(3) {
                            display: none !important;
                        }
                    }
            }
            }
        }

        
          }
          .uploaded-file-list--card{
            padding:0 !important;
            box-shadow:none !important;
        }

        .mat-form-field-outline{
            /*height:120px !important;*/

            
        }

        .text-area{
                height:85px !important;
            }
     
    }
  }



  .upload-profile .file-drop .drop-zone {
        height: 135px!important;
    }

    .header-nav.wm-go-home{
        display: flex;
        align-items: center;
    }


    .header__alter-menu .sub.wm-top-name .wm-sign-in-btn i.ng-star-inserted{
        width: 100% !important;
        display: flex;
        justify-content: flex-end;
        padding-right: unset !important;
      }

      .header__user .header__alter-menu .sub.wm-top-name .wm-login-user-profile-icon-wrapper .wm-profile-avatar img.user__avatar{
        border-radius: 999px;
        width: 30px !important;
        height: 30px !important;
      }

      .header__alter-menu .sub.wm-top-name .wm-login-user-profile-icon-wrapper{

        @media screen and (max-width:768px) {
            width:unset !important;
        }
    }

    @media only screen and (max-width: 400px){
        .cdk-overlay-connected-position-bounding-box{
            align-items: flex-end !important;
        }
    }

    .homeCart{
        @media screen and (max-width:768px) {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
        .mmc_flex__item{
            flex: inherit;
        }
    }

    .profileCartSec {
        width: 100%;
    }

    @media (min-width: 769px) {
        .profileCartSec {
            width: auto;
            display: flex;
            justify-content: flex-end;
        }
        .header__alter-menu .sub.wm-top-name .wm-sign-in-btn .ng-star-inserted {
            width: auto;
            /* width: 80px; */
        }
        .header__alter-menu .sub.wm-top-name .wm-sign-in-btn a.pw-txt-b2 {
            padding-left: 13px !important;
        }
        .wm-home-cart {
            border: unset;
        }
        .user__account__text {
            padding-right: 5px !important;
        }
    }

    @media (min-width: 401px) and (max-width: 768px) {
        a.px-3.pw-txt-b2.pw-text-color__primary.link-primary {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .header__alter-menu .sub.wm-top-name .wm-login-user-profile-icon-wrapper {
            width: 40px !important;
            /* width: 100% !important; */
        }
        .user__account__text {
            padding-right: 5px !important;
        }
        .profileCartSec {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .mmc_flex__item.user__left.float-auto.float-right.wm-home-cart.d--flex.align--center.ng-star-inserted {
            margin-top: unset;
            border: none;
        }
    }

    @media (max-width: 400px) {
        .profileCartSec {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .header__alter-menu .sub.wm-top-name .wm-login-user-profile-icon-wrapper {
            width: 40px !important;
        }
        .user__account__text {
            padding-right: 5px !important;
            white-space: nowrap;
        }
    }

    i.cog-d-none.material-icons.btn-click.pw-ico-color__tertiary.pr-3.header-hamburger-icon.ng-star-inserted {
        justify-content: flex-start;
    }


    @media only screen and (max-width: 768px) {
        .profile-image-row .user-profile .profile-image .img-circle {
            //margin-top: 20px !important;
        }
    }
    
    .mmc_flex__item.mmc_fd--col.mmc_flex__item--hcenter.ml-5 {
        margin-left: 0rem !important;
    }

    @media (max-width: 640px) {
        .upload-profile .file-drop .drop-zone {
            height: 160px !important;
        }
    }