.man-con-page {
  background-color: #994198;
  height: 100vh;
  padding-top: 10%;
}
.man-logo {
  text-align: center;
}
.man-head-text {
  text-align: center;
  font-size: 5em;
  color: white;
}
.man-head-texT-1 {
  text-align: center;
  font-size: 2em;
  color: white;
  margin-top: 50px;
}
.page {
  background-color: aquamarine;
  border-radius: 230px;
  width: 45%;
  height: 55%;
  margin-left: auto;
  margin-right: auto;
}