@function font-weight($keyword) {
  $conversion-map: (
    thin: 100,
    hairline: 100,
    extra light: 200,
    extralight: 200,
    ultra light: 200,
    ultralight: 200,
    light: 300,
    book: 300,
    demi: 300,
    normal: 400,
    regular: 400,
    medium: 500,
    semi bold: 600,
    semibold: 600,
    demi bold: 600,
    demibold: 600,
    bold: 700,
    extra bold: 800,
    extrabold: 800,
    ultra bold: 800,
    ultrabold: 800,
    black: 900,
    heavy: 900,
    fat: 900,
    poster: 900,
    extra black: 900,
    extrablack: 900,
    ultra black: 900,
    ultrablack: 900
  );

  @if not map-has-key($conversion-map, $keyword) {
    @error "Unsupported `font-weight` keyword `#{$keyword}`";
    @return null;
  }
  @return map-get($conversion-map, $keyword);
}
// Font weight
@mixin font-weight($keyword) {
  font-weight: font-weight($keyword);
}
