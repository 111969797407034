.mmc_carousel {
    .slider-card {
        flex-direction: column;
        background-color: white !important;
        width: 100%;
        min-height: 328px;

        .slider-details {
            flex-direction: column;

            .details {
                height: 180px;
            }

            .item_name {
                .ctitle {
                    //width: auto;
                    // white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #0275d8;
                    word-wrap: break-word;
                    word-break: break-all;
                    white-space: normal;
                    margin-bottom: 10px;
                    margin-top: 10px;

                    a {
                        /*white-space: nowrap;*/
                    }

                    &:hover {
                        //overflow: visible;
                    }
                }
            }

            .item_description {
                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-height: 16px; /* fallback */
                    max-height: 50px; /* fallback */
                    -webkit-line-clamp: 3; /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
            }
        }

        .aminity-icons {
            img {
                margin-right: 15px;
            }
        }

        .image-cage {
            height: 183px;
            overflow: hidden;
            border: 3px solid rgb(249, 249, 250);
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease;

            .img {
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
                background-position: center center;
                overflow: hidden;
                cursor: pointer;
                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;
                height: 183px;
                object-fit: cover;

                &:hover {
                    border: none;
                    -webkit-transform: scale(1.2);
                    -moz-transform: scale(1.2);
                    -o-transform: scale(1.2);
                    -ms-transform: scale(1.2); /* IE 9 */
                    transform: scale(1.2);
                }
            }




            .overlay {
                background-color: #5050506e;
                height: 100%;
            }
        }
    }

    .ng-star-inserted {
        .slider-details {
            padding: 20px;
        }
    }

    .item {
        padding: 10px;
        box-sizing: border-box;
    }

    .tile {
        /*min-height: 200px;
  background-color: #ccc;*/
        // background-size: cover !important;
        display: flex;
        align-items: center;
        justify-content: center;
        //box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    }

    .leftRs {
        display: flex;
        justify-content: center;
        position: absolute;
        margin: auto;
        top: -145px;
        bottom: 0;
        width: 65px;
        height: 60px;
        //  box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, .3);
        //  border-radius: 999px;
        left: 4px;
        cursor: pointer;
        background-color: transparent;
        border: none;
        z-index: 1;

        // @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        //     left: -15px;
        // }

        // @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
        //     left: -15px;
        // }

        i {
            font-size: 70px;
            background-color: transparent;
            color: #ffffff;
            // -webkit-box-shadow: -2px 2px 5px -2px rgba(0,0,0,0.75);
            //-moz-box-shadow: -2px 2px 5px -2px rgba(0,0,0,0.75);
            //  box-shadow: -2px 2px 5px -2px rgba(0,0,0,0.75);
            &:hover {
                color: #e2e2e2;
            }
        }
    }

    .rightRs {
        /* position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        width: 50px;
        height: 50px;
        right: 0;*/
        background-color: transparent;
        border: none;
        cursor: pointer;
        position: absolute;
        margin: auto;
        top: -145px;
        bottom: 0;
        width: 65px;
        height: 60px;
        // right: 31px;
        right: 4px;
        display: flex;
        justify-content: center;

        
        // @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        //     right: 20px;
        // }

        // @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
        //     right: 20px;
        // }
        i {
            font-size: 70px;
            background-color: transparent;
            color: #ffffff;
            // -webkit-box-shadow: -2px 2px 5px -2px rgba(0,0,0,0.75);
            //  -moz-box-shadow: -2px 2px 5px -2px rgba(0,0,0,0.75);
            //  box-shadow: -2px 2px 5px -2px rgba(0,0,0,0.75);
            &:hover {
                color: #e2e2e2;
            }
        }
        //   box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, .3);
        //   border-radius: 999px;
    }


    .myPoint {
        list-style-type: none;
        text-align: center;
        padding: 12px;
        margin: 0;
        white-space: nowrap;
        overflow: auto;
        box-sizing: border-box;
    }

    .myPoint li {
        display: inline-block;
        border-radius: 50%;
        border: 2px solid rgba(0, 0, 0, 0.55);
        padding: 4px;
        margin: 0 3px;
        transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
        transition: 0.4s;
    }

    .myPoint li.active {
        background: #6b6b6b;
        transform: scale(1.2);
    }
}

/*@media (min-width: 1200px){
    .ngucarousel > .ngu-touch-container > .ngucarousel-items > .item {
        flex: 0 0 33.3% !important;
        width: 100%;

    }
}*/
