@import "../helpers/_mixins.scss";
@import "../base/_tc-md-mixins-new.scss";

.container{

  @include respond-to("x-small") {
    min-width:300px ;
  }
  @include respond-to("small") {
    min-width:600px ;
  }
  @include respond-to("medium") {
    min-width:900px ;
  }
  @include respond-to("large") {
    min-width:1000px ;
  }
  @include respond-to("x-large") {
    min-width:1300px ;
  }
  @include respond-to("xx-large") {
    min-width:1600px ;
  }
}

.container-fluid{

}

.body-search{
  background-color: $pw-bg-color__secondary;
  padding: 20px 0px;
}