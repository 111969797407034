﻿@import "../base/_tc-md-mixins-new.scss";
@import "../themes/default/_variables-new.scss";

//Text Classes

%tc-ellipsis{
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.wider-text{
white-space: normal !important;
    max-width: 220px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pw-txt-h1{
    @include typo-heading-1;
    /*@extend %tc-ellipsis;*/
}

.pw-txt-h2{
    @include typo-heading-2;
    /*@extend %tc-ellipsis;*/
}


.pw-txt-h3{
    @include typo-heading-3;
    /*@extend %tc-ellipsis;*/
}

.pw-txt-h3_small{
    @include typo-heading-3_small;
    /*@extend %tc-ellipsis;*/

    &--link{
        @include typo-heading-3_small;
        text-decoration:underline !important;
        cursor:pointer !important;
       -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
         &:hover{
            color:$pw-text-color__quaternary !important;
        }
       
       
    }
}

.pw-txt-h4{
    @include typo-heading-4;
    /*@extend %tc-ellipsis;*/
}

.pw-txt-h5{
    @include typo-heading-5;
    /*@extend %tc-ellipsis;*/
}


.pw-txt-b1{
    @include typo-body-1;
    /*@extend %tc-ellipsis;*/

}

.pw-txt-b1_small{
    @include typo-body-1_small;
    /*@extend %tc-ellipsis;*/
}


.pw-txt-b2{
    @include typo-body-2;
    /*@extend %tc-ellipsis;*/
     &.link{
          text-decoration:underline !important;
        cursor:pointer !important;
       -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
         &:hover{
            color:$pw-text-color__quaternary !important;
        }
    }

     &.link2{
          /*text-decoration:underline !important;*/
        cursor:pointer !important;
       -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
         &:hover{
            color:$pw-text-color__quaternary !important;
        }
    }
}

.link-txt{
    text-decoration:underline !important;
        cursor:pointer !important;
       -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
         &:hover{
            color:$pw-text-color__quaternary !important;
        }
}

.pw-txt-b2_small{
    @include typo-body-2_small;
    /*@extend %tc-ellipsis;*/
}

.pw-txt-b3{
    @include typo-body-3;
    /*@extend %tc-ellipsis;*/
}

.pw-txt-s1{
    @include typo-subtitle-1;
    /*@extend %tc-ellipsis;*/
    
}

.pw-txt-s1_small{
    @include typo-subtitle-1_small;
    /*@extend %tc-ellipsis;*/
}

.pw-txt-s2{
    @include typo-subtitle-2;
    /*@extend %tc-ellipsis;*/
}

.pw-txt-s2_small{
    @include typo-subtitle-2_small;
    /*@extend %tc-ellipsis;*/
}


.pw-txt-s3{
    @include typo-subtitle-3;
    /*@extend %tc-ellipsis;*/
}

.pw-txt-s3_small{
    @include typo-subtitle-3_small;
    @extend %tc-ellipsis;
}

.pw-txt-cap{
    @include typo-caption;
    @extend %tc-ellipsis;
}


.pw-txt-p{
    @include typo-para;
    
}


//Text Colour Classes

.pw-text-color{
    color:$pw-text-color;
    
}

.pw-text-color__primary{
    color:$pw-text-color__primary;
}

.pw-text-color__secondary{
    color:$pw-text-color__secondary;
}

.pw-text-color__tertiary{
    color:$pw-text-color__tertiary;
}

.pw-text-color__quaternary{
    color:$pw-text-color__quaternary;
}

.pw-text-color__quinary{
    color:$pw-text-color__quinary;
}

.pw-text-color__senary{
    color:$pw-text-color__senary;
}

.pw-text-color__special{
    color:$pw-text-color__special;
}


//Font Weight

.pw-text-light{
    font-weight:$pw-font-w-light;
}

.pw-text-regular{
    font-weight:$pw-font-w-regular;
}

.pw-text-medium{
    font-weight:$pw-font-w-medium;
}

.pw-text-semi-bold{
    font-weight:$pw-font-w-semi-bold;
}

.pw-text-bold{
    font-weight:$pw-font-w-bold;
}

.pw-text-extra-bold{
    font-weight:$pw-font-w-extra-bold;
}

.pw-text-black{
    font-weight:$pw-font-w-black;
}


//Text Transform

.pw-text-trans__upper{
    text-transform:uppercase;
}

.pw-text-trans__lower{
    text-transform:lowercase;
}

.pw-text-trans__sentence{
    text-transform:inherit;
}



//Icon Colour Classes

.pw-ico-color{
    color:$pw-text-color !important;
    
}

.pw-ico-color__primary{
    color:$pw-text-color__primary;
}

.pw-ico-color__secondary{
    color:$pw-text-color__secondary;
}

.pw-ico-color__tertiary{
    color:$pw-text-color__tertiary;
}

.pw-ico-color__quaternary{
    color:$pw-text-color__quaternary;
}

.pw-ico-color__quinary{
    color:$pw-text-color__quinary;
}


//Btn Colour Classes

.pw-btn-bg-color{
    background-color:$pw_btn-bg-color !important;
    
}

.pw-btn-bg__primary{
    background-color:$pw_btn-bg-color__primary !important;
}

.pw-btn-bg__secondary{
    background-color:$pw_btn-bg-color__secondary !important;
}

.pw-btn-bg__tertiary{
    background-color:$pw_btn-bg-color__tertiary !important;
}

.pw-btn-bg__quaternary{
    background-color:$pw_btn-bg-color__quaternary !important;
}




//Line height

.pw-txt-lh-1{
    line-height:1;
}

.pw-txt-lh-1nh{
    line-height:1.5;
}

.pw-txt-lh-2{
    line-height:2;
}

.pw-txt-b2_small.pw-txt-lh-1 {
    line-height: 1.5;
}


//Text allign

.pw-txt-algn-l{
    text-align:left;
}

.pw-txt-algn-r{
    text-align:right;
}

.pw-txt-algn-c{
    text-align:center;
}

//Mat Icon

.pw-icn-small{
    font-size:1rem;
}













