@import "../../sass/helpers/functions";
@import "../../sass/helpers/mixins";
@import "../../sass/base/color";

.mat-paginator-container{
    font-size: 14px;
    font-weight: 500;
}

.footer {
  /*background-color: $footer_bg;*/
  color: #EFF0F2;
    background-color: #eef0f1;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-top: 10px;

 //background: url(https://www.manningham.vic.gov.au/sites/default/themes/mcc_2018/images/bottom-bg.png) bottom right no-repeat;

  //  background-image: url(/Optimo.PrivateWeb/dist/assets/images/bottom-bg.png) bottom right no-repeat;
 // background-position:right bottom;
 // background-repeat:no-repeat;

 .footer_content {
    color: #484848;
    padding-top: 20px;
    font-weight: normal;
    overflow: hidden;
    /*width: 1130px;*/
    padding: 10px;
    /*margin-left: auto;*/
    /*margin-right: auto;*/

    .footer_left {
    float: left;
    width: 300px;
    color: #484848;
    line-height: 15.6px;
    font-weight: normal;
    text-align: left;
    padding-left: 10px;
    padding-left: 0px;

     @media only screen and (max-width: 768px) {
                text-align: center !important;
                width: 100% !important;
                }
    h1 {
    font-size: 19.2px;
    color: #1564A6;
    font-weight: normal;
    padding: 0;
    margin: 0.6em 0px;
    margin-bottom: 10px;
}
    p {
    font-size: 12px !important;
    font-weight: 400 !important;
}
}

    .footer_right {
    float: right;
    color: #484848;
    text-align: right;
    padding-top: 43px;

     @media only screen and (max-width: 768px) {
                text-align: center !important;
                width: 100% !important;
                }
    p {
    font-size: 12px !important;
}






}
}


  .container {
    padding: 0;
    @include font-size();
  }
  ul{
        list-style: none;
        padding-left: 0;
        color: #000;
  }
  &__pd {
    padding: 40px 40px 40px 0;
    .footer__content{
        ul{
            list-style-type: none;
            padding-left: 0;
        } 
        &.dark  {
            a{
            color: #fff;
        }
        }    
        font-size: 14px;
        a{
            color: #808080;
        }
    }
    &--lb {
      padding-bottom: 40px;
    }
    &--web {
      display: block;
    }
  }

  &__title {
    padding-bottom: 25px;
    @include font-size(20);
    @include font-weight(semibold);
    color: #373737;
  }
  &__content {
    @include rhythm(16, 45);
    @include font-weight(regular);
    color: #373737;
  }
  &__social {
    align-items: center;
    display: flex;
  }
  &__social-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__bg {
    background: $footer_bg;
  }
  &__bg--fix {
    background: $color-yellow;
  }
  &__bg--green {
   // background: $color-green;
    .footer__title {
      color: white;
    }
    
  }
  
  &--wrap {
    padding-top: 40px;
    padding-left: 30px;
  }
  &__nav {
    padding-top: 0;
    @include font-size(16);
    @include font-weight(semibold);
    color: #373737;
    &__follow {
      padding-bottom: 10px;
      @include font-size(14);
      @include font-weight(semibold);
      color: white;
    }
    &__link {
      margin-bottom: 10px;
      a {
        @include font-size();
        @include font-weight(semibold);
        color: #373737;
        margin-right: 15px;
      }
    }
  }
  &__top-left {
    padding-left: 100px;
  }
  &__top-right {
    padding-left: 50px;
    display: flex;
    flex-direction: column;
  }
  &__bottom-left {
    padding-left: 50px;
    flex-direction: column;
    padding-top: 20px;
    justify-content: center;
    display: flex;
    width: 100%;
  }
  &__bottom-right {
    padding-right: 50px;
    padding-left: 50px;
    display: flex;
    padding-bottom: 15px;
    position: relative;
   // background-color: #00a651;
   



      @include respond-to("x-small") {
          background-color:#3d9b47;
         background-image:none;
     }
     @include respond-to("small") {
         background-color:#3d9b47;
         background-image:none;
     }
     @include respond-to("medium") {
        background-color:#3d9b47;
        //pls change the url after fix path issue
         //background: url(https://www.manningham.vic.gov.au/sites/default/themes/mcc_2018/images/bottom-bg.png) bottom right no-repeat;
     
      //   background: url(/Optimo.PrivateWeb/dist/assets/images/bottom-bg-small.png) bottom right no-repeat;
         background-position: 100% 80%;
     }
     @include respond-to("large") {
        background-color:#3d9b47;
         //pls change the url after fix path issue
      background: url(https://www.manningham.vic.gov.au/sites/default/themes/mcc_2018/images/bottom-bg.png) bottom right no-repeat;
     
     }
     @include respond-to("x-large") {
         background-color:#3d9b47;
          //pls change the url after fix path issue
         background: url(https://www.manningham.vic.gov.au/sites/default/themes/mcc_2018/images/bottom-bg.png) bottom right no-repeat;
     
     }



   
    right: 0;
  }
  &__logo {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
     @include respond-to("x-small") {
          height: 140px;
     }
     @include respond-to("small") {
          height: auto;
     }
     @include respond-to("medium") {
          height: auto;
     }
     @include respond-to("large") {
          height: auto;
     }
     @include respond-to("x-large") {
          height: auto;
     }
    img {
      width: 151px;
      height: 80px;
    }
  }
}
.border--left {
  border-left: 1px solid #b1c052;
  padding: 0 30px;
}
.circle-wrap {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
  background: white;
  margin: 0 5px;
  i {
    margin: auto;
    font-size: 24px;
  }
}
.footer__bottom{
    list-style: none;
    padding-left: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    a{
    color: #808080;
    }
    li{
           display: inline-block; 
        &:not(:last-child):after {
            content:' |';
            padding: 0 5px;
        }
    }
}
.copy-right {
  padding: 15px 0 15px 10px;
  @include font-size(14);
  @include font-weight(light);
}
.powered__by--mb{
    background-color: #f6f7fb;
}

.powered__by {
  background-color: #f6f7fb;
  display: flex;
  justify-content: end;
  padding: 20px 10px;

  a{
    text-decoration: none !important;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
                justify-content:center !important;
                }

      font-size: 12px;

      img{
              height: 25px;
      }

  &__font {
    margin-right: 15px;
    padding-top: 5px;
  }
  .ml-auto{
      padding-right:5px;

       @media only screen and (max-width: 768px) {
                margin-left: unset !important;
                }
  }
}
.pd-left {
  padding-left: 30px;
}

@media screen and (max-width: 576px) {
  .footer--web {
    display: none;
  }
  .powered__by {
    bottom: 0;
    width: 100%;
    margin-left: 0;
    /*background: $color-grey;*/
    img {
      /*margin-right: 30px;*/
    }
    &--mb {
      background: $color-grey;
    }
  }
}

/*//New Design
.footer-full{
    position:relative;
    h2{
        @include font-size(40);
        text-align: left;
        margin-top: 69px;
    }
}
.footer-bootom-wrapper{    
        //position: relative;
        position: absolute;
        height: 438px;
        right: 0;
        bottom: 0;
        background-size: cover !important;
        content: ' ';
        display: block;
        width: 100%;
        height: 338px;
        z-index: 8;
        pointer-events: none;
    
    .white-logo{
        width:151px;
        height: 80px;
        position: absolute;
        right: 38px;
        bottom: 22px;
    }
    .region{
        position: absolute;
        bottom: -340px;
        .block{
            p{
                margin: 0 0 10px;
            }
            a{
                display: inline-block;
                margin-bottom: 0;
                margin-top: 0;
                border-right: 1px solid #000;
                padding-right: 13px;
                margin-right: 6px;
                font-weight: 300;
                @include font-size(18);
            }
            .copyrights{
                color: #666666;
                @include font-size(18);
            }
        }
    }
}

.footer{
    //position: absolute;
    width: 100%;
    bottom:0;
    background-size: cover !important;
    background-position: 68% center;
    padding-top: 137px;
    margin-top: -100px;
    padding-bottom: 294px;

    .container-fluid {
        padding-left: 40px;
        padding-right: 40px;
    }
    .container-fluid{
        position:relative;
    }
    .basic-body{
        p{
            font-weight: 300;
            color: #666666;
            @include font-size(14);
        }
        a{
            font-weight: 300;
            color: #666666;
            @include font-size(14);
            padding-right:5px;
        }
        i{
              top: 3px;
              padding-right: 5px;
              position: relative;
               @include font-size(18);
        }

    }
    h2{
        @include font-size(24);
        color: #666666;
        margin-bottom: 32px;
        font-weight: 500;
    }
    ul{
        list-style-type: none;
        padding-left: 0;
        li{
            margin-bottom: 24px;
        }
        a{
            font-weight: 300;
            color: #666666;
            @include font-size(14);

        }
    }
    .contact-us{
        &:after{
            content: 'keyboard_arrow_right';
            font-family: 'Material Icons' !important;
            position: static;
            display: inline;
            right: 0;
            top: 0;
            margin-left: 15px;
            font-size: 18px;
        }           
    }
    .tweeter{
        font-size: 28px;
        line-height: 53px;
        height: 51px;
        width: 51px;
        overflow: hidden;
        display: block;
        margin-right: 10px;
        &:before{
            content: '\e809';
            font-family: "fontello";
            text-align: center;
            color: #fff;
            background: #55ACEE;
            font-size: 20px;
            display: block;
            width: 51px;
            height: 51px;
            border-radius: 50%;
            float: left;
        }
    }
}

.footer-top-wrapper{
    background-size:cover !important;
    overflow: hidden;
    z-index: 5;
    background: #4B467F;
    position: relative;
    min-height: 694px;
    top: 19px;
    .basic-body{
        max-width: 603px;
        font-size: 16px;
        color: #fff;
        text-align: left;
        margin: 0;
    }
    span, a{
        font-size: 24px;
    line-height: 50px;
    height: 50px;
    }
    h2{
        font-size: 40px;
        text-align: left;
        margin-top: 69px;
            margin-bottom: 57px;
             color: #fff;
    }
    a{
        text-decoration:none;
        color: #fff;
    }
    .row{
        padding-bottom: 100px;
        margin: 0;
        text-align: left;
    }
}*/

@media screen and (max-width:768px) {
  .cog-footer-container{
    display: flex;
    flex-direction: column;
    align-content: center;

    .footer_right{
      width: 35% !important;
    }

    .footer_right{
      width: 36% !important;
    }
  }
}

// @media only screen and (max-width: 768px) {
//   .footer .footer_content .footer_left.payment-footer-left{
//   text-align: center !important;
//   width: 37% !important;
//   }
//   }