@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900");

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// font
//@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~@angular/material/theming';
@include mat-core();

$font-montserrat: "Montserrat", sans-serif;
@import "./base/_icon-font.scss";

//@import "./themes/default/main.scss";
@import "./themes/snow/main.scss";
//@import "./themes/snow-test/main.scss";

/*body [class^='mat-'], body [class*=' mat-'] {
    font-family: $font-montserrat !important;
}*/
html {
    font-size: 16px;

    body {
        font-size: 62.5%;
         font-size: 16px;
        //font-family: $font-montserrat !important;
    }
}

* {
    //font-family: 'Montserrat', sans-serif;
}
.mcc{
    * {
    font-family: 'Montserrat', sans-serif;
    }
}

.bs-timepicker-field{
     pointer-events: none !important;
        opacity: 0.5 !important;
}

.snow .main_search .date .mat-datepicker-toggle-default-icon {
    width: 30px !important;
    margin-left: -8px !important;
}

mat-button-wrapper{
    margin-left: -8px !important;
}

.opacity-0 {
    pointer-events: none;
    opacity: 0;
}


