@import "../helpers/mixins";

.filter--menu .mat-form-field-label {
    color: #616161 !important;
}



.cart_facility{ 
&.extras{

    .extra-result{
border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    padding-top: 20px;
    border-radius:5px;


    .icn-aminity{
        margin-right: 5px;
    }

   
    }

    .sub-head{
    
   height: 40px;
    color: #454545;
    background-color: #e6e6e6;
    padding: 10px 40px;
    position: absolute;
    margin-top: -40px;
    border-radius: 5px;
    font-weight: 600;
      }

    .image{
        width:80px;
        height:80px;
        background-color:#ccc;
    }

    .count{
            font-size: 35px;
    font-weight: 700;
    }

    .multi{
font-size: 20px;
    font-weight: 700;
        margin-left: 10px;
            color: #a5a5a5;
    }

    .title{
        font-size: 18px;
    font-weight: 700;
    padding-top:10px;
    
    }


      .details{
            .mmc_flex--container{
           flex-direction: column;
          // height:100%;
        }
        }

    .price-details{

      

        .mmc_flex--container{
           flex-direction: column;
         //  height:100%;
        }

        text-align:right;

        .item{
                color: #8e8c8c;
                font-weight: 600;
                 padding-top:10px;
                  justify-content: flex-end;
        }

        .total{
              font-size: 18px;
    font-weight: 700;
     justify-content: flex-end;

        }
    }
    
}
}
.google-card{
    padding: 15px;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #fff;
    max-width:450px;

    .google-card-btn{

    }

    .price{
        font-weight: 700;
    }

    .bottom-cage {
        margin-top:10px;
    }
    .google-card-btn{
        font-size: 14px;
        height: 35px;
    }
    .cage{
        display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
     height: 90px;

        .image{
            background-color:#454545;
            order: 0;
    flex: 0 1 100px;
    align-self: auto;
    border:1px solid #ccc;
        margin-right: 10px;
        }

        .content{
           
              order: 0;
            flex: 0 1 100%;
            align-self: auto;

            .name{
                h1{
                    padding:0px;
                    margin:0px;
                    font-size:18px;
                }

                .adress{
 font-size:14px;
                }
            }
        }

    }


}




.btn-footer{
justify-content: flex-end;
}








.mat-progress-spinner circle, .mat-spinner circle {
    stroke: #ed3e84 !important;
}

.mat-datepicker-toggle-active {
    color: #ed3e84;
}


.mat-calendar-body-selected {
    background-color: #ed3e84 !important;
    color: #fff;
}
.main_search {
    /*margin-bottom: 30px !important;
       margin-top: 35px !important;*/
    /*-webkit-box-shadow: inset -2px 3px 18px -9px rgba(0,0,0,0.75);
-moz-box-shadow: inset -2px 3px 18px -9px rgba(0,0,0,0.75);
box-shadow: inset -2px 3px 18px -9px rgba(0,0,0,0.75);*/
 -webkit-box-shadow: inset 0px 0px 4px 0px $pw-box-shadow-color__secondary;
-moz-box-shadow: inset 0px 0px 4px 0px $pw-box-shadow-color__secondary;
box-shadow: inset 0px 0px 4px 0px $pw-box-shadow-color__secondary;

  .date,.time{
      @include respond-to("x-small") {
        padding: 0 15px;
      }
      @include respond-to("small") {
        padding: 0 15px;
      }
      @include respond-to("medium") {
        padding: 0;
      }
      @include respond-to("large") {
        padding: 0;
      }
      @include respond-to("x-large") {
        padding: 0;
      }
    .mat-form-field-infix{
    //  max-width: 150px;
    font-weight:700;

      @include respond-to("x-small") {
        max-width: 100%;
      }
      @include respond-to("small") {
        max-width: 100%;
      }
      @include respond-to("medium") {
        max-width: 90%;
      }
      @include respond-to("large") {
        max-width: 150px;
      }
      @include respond-to("x-large") {
        max-width: 100%;
      }
    }
  }


  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field {
    width: 100%;

    padding-left: 5px;
    padding-right: 5px;

  }

  border: 1px solid #ccc;
  background-color: #fff;
  height: 60px;
  margin: 20px -15px 0px -15px;


  @include respond-to("x-small") {
    height: auto;
  }
  @include respond-to("small") {
    height: auto;
  }
  @include respond-to("medium") {
    height: 65px;
  }
  @include respond-to("large") {
    height: 60px;
  }
  @include respond-to("x-large") {
    height: 60px;
  }

  @include border-radius(5px);

  .mmc_flex--container {
    height: 100%;
    justify-content: flex-end;
    @include respond-to("x-small") {
      flex-direction: column;
    }
    @include respond-to("small") {
      flex-direction: column;
    }
    @include respond-to("medium") {
      flex-direction: row;
    }
    @include respond-to("large") {
      flex-direction: row;
    }
    @include respond-to("x-large") {
      flex-direction: row;
    }
  }

  .input_cage {
    flex: 0 0 35%;
    padding-left: 15px;
    color: #656565;

    @include respond-to("x-small") {
      flex: 0 0 100%;
      border-bottom: 1px solid #ccc;
    }
    @include respond-to("small") {
      flex: 0 0 100%;
    }
    @include respond-to("medium") {
      flex: 0 0 40%;
      border-bottom: none;
    }
    @include respond-to("large") {
      flex: 0 0 40%;
      border-bottom: none;
    }
    @include respond-to("x-large") {
      flex: 0 40%;
      border-bottom: none;
    }

    .mat-form-field {
      max-width: 100%;
    }
    .mat-facused, .mat-form-field-should-float{
        padding-top: 10px;
    }
    .mat-form-field-appearance-legacy .mat-form-field-underline {
      display: none;
    }
  }

  .filter_cage {
    flex: 0 0 65%;

    @media only screen and (min-width: 1024px) and (max-width: 1200px) {
      max-width: 65%;
    }
    
    .input_cage {
      background-color: #00bfa5;
    }
    input{
      font-size: 15px !important;
      @media screen and (max-width: 1450px) {
          font-size: 12px !important;
      }
    }

    .date {

      .mat-form-field-suffix{
          position: relative;
          
          @include respond-to("x-small") {
            bottom: 0;
          }
          @include respond-to("small") {
            bottom: 0;
          }
          @include respond-to("medium") {
            bottom: 10px;
            right: 10px;
          }
          @include respond-to("large") {
            bottom: 10px;
            right: 10px;
          }
          @include respond-to("x-large") {
            bottom: 10px;
            right: 10px;
          }
          input{
               font-weight: 600;
          }
      }
      .mat-form-field{
        padding-top: 10px;
        font-weight: 600;
      }
      @include respond-to("x-small") {
        width: 100%;
        border-bottom: 1px solid #ccc;
      }
      @include respond-to("small") {
        width: 100%;
        border-bottom: 1px solid #ccc;
      }
      @include respond-to("medium") {
        width: 30%;
        border-bottom: 1px solid #ccc;
      }
      @include respond-to("large") {
        width: 20%;
        border-bottom: none;
      }
      @include respond-to("x-large") {
        width: 20%;
        border-bottom: none;
      }
    }

    .mmc_flex--container{
      // justify-content: start;
    }
  }

  .btn--cage {
    width: 25%;

    .btn--search{
      @include respond-to("x-small") {
        min-height: 50px;
        border-radius: 0px 0px 5px 5px ;
      }
      @include respond-to("small") {
        min-height: 50px;
        border-radius: 0px 0px 5px 5px ;
      }
      @include respond-to("medium") {
        min-height: 50px;
        border-radius: 0px 0px 5px 5px ;
      }
      @include respond-to("x-large") {
        height: 100%;
        border-radius: 0px 5px 5px 0px;
      }
    }


    .mmc_flex--container{
         @media only screen and (max-width:768px) {
                                        flex-direction: column;
                                        width: 100%;
                                    }


      /*@include respond-to("medium") {
        flex-direction: column;
        width: 100%;
      }
      @include respond-to("large") {
        flex-direction: column;
        width: 100%;
      }*/
    }
    @include respond-to("x-small") {
      width: 100%;

    }
    @include respond-to("small") {
      width: 100%;

    }
    @include respond-to("medium") {
      width: 20%;
    }
    @include respond-to("large") {
      width: 20%;
    }
    @include respond-to("x-large") {
      width: 20%;
      border-bottom: none;
    }

  }


  .pax {
    &.check-box {
        border-left: 1px solid #ccc;
        border-bottom: transparent;
        background-color:#eee;
        font-weight:600;

        i{
            line-height: 30px;
        }

        &:hover{
            background-color:#e2e2e2;
        }

        .mat-checkbox {
            padding: 5px;

            .check-text {
                font-size: 14px;
                color: #616161;
                white-space: break-spaces;
            }
        }

        @include respond-to("x-small") {
            width: 100%;
            border-bottom: 1px solid #ccc;
        }
        @include respond-to("small") {
            width: 100%;
            border-bottom: 1px solid #ccc;
        }
        @include respond-to("medium") {
            width: 20%;
        }
        @include respond-to("large") {
            width: 20%;
        }
        @include respond-to("x-large") {
            width: 20%;
            border-bottom: none;
        }

        .mat-form-field {
            width: 100%;
        }
        .mat-form-field-appearance-legacy .mat-form-field-wrapper {
            margin-top: 9px;
        }
     }
  }


  .pax {

      i{
          line-height: 30px;
      }


    border-left: 1px solid #ccc;
   // border-right: 1px solid #ccc;
    border-bottom: transparent;
    background-color:#eee;
    font-weight:600;

    &:hover{
        background-color:#e2e2e2;
    }

    @include respond-to("x-small") {
      width: 100%;
      border-bottom: 1px solid #ccc;
    }
    @include respond-to("small") {
      width: 100%;
      border-bottom: 1px solid #ccc;
    }

    @include respond-to("medium") {
      width: 10%;
    }
    @include respond-to("large") {
      width: 10%;
    }
    @include respond-to("x-large") {
      width: 10%;
      border-bottom: none;
    }
    //padding: 0 5px;
    .mat-form-field {
      width: 100%;
    }
    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      margin-top: 9px;
    }
  }
  .date {
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    width: 30%;
   // padding: 0 5px;

    @include respond-to("medium") {
      width: 30%;
    }

    .mat-datepicker-toggle-default-icon {
      width: 30px !important;
    }
    .mat-icon-button {
     // width: 30px !important;
    //  height: 30px !important;
   //   top: -29px;
   //   position: absolute;
    //  left: -35px;
    }

    input.mat-input-element {
      @media screen and (min-width: 1024px) {
        font-size: 12px;
      }
    }

  }
  .filter {
      .mmc_flex--container{
          flex-direction: column !important;
          text-align: center;
      }
  }
  .time {
    width: 35%;
   // padding: 0 5px;
    .mat-form-field-suffix{
          position: relative;
          
            @include respond-to("x-small") {
              bottom: -4px;
              right: 7px;
            }
            @include respond-to("small") {
              bottom: -4px;
              right: 7px;
            }
            @include respond-to("medium") {
              bottom: 0px;
              right: 10px;
            }
            @include respond-to("large") {
              bottom: 0px;
              right: 10px;
            }
            @include respond-to("x-large") {
              bottom: 2px;
              right: 10px;
            }
      }

    @include respond-to("x-small") {
      width: 100%;
      border-bottom: 1px solid #ccc;
    }
    @include respond-to("small") {
      width: 100%;
      border-bottom: 1px solid #ccc;
    }
    @include respond-to("medium") {
      width: 25%;
      border-bottom: 1px solid #ccc;
    }
    @include respond-to("large") {
      width: 25%;
      border-bottom: none;
    }
    @include respond-to("x-large") {
      width: 25%;
      border-bottom: none;
    }

    .mat-datepicker-toggle-default-icon {
      width: 30px;
    }
    .mat-form-field {
      width: 100%;
      margin-top: 10px;
    }
    input.mat-input-element {
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }
  .btn--search {
    background-color: $opt_color_primary;
    color: #fff;
    width: 100%;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    &:hover {
      background-color: #992e98;
    }

    @include border-radius(0px 5px 5px 0px);
    font-size: 18px;
    font-weight: 600;

    span {
        @include respond-to("x-small") {
        display: none;
      }
      @include respond-to("small") {
        display: block;
      }
     

      /*@include respond-to("x-large") {
        display: block;
      }*/
    }

    i {
      font-size: 30px !important;

      font-size: 16px;
    }

     @media only screen and (max-width: 768px) {
         min-height: 50px !important;
                                   @include border-radius(0px 0px 5px 5px);     
                                    }
  }
}

//
//.man_ {
//  &search {
//
//    .mat-form-field-wrapper{
//
//    }
//
//    .items{
//      padding-left: 5px;
//      @include  align-content(center);
//    }
//    .mat-form-field-infix{
//      padding: 0;
//      border: 0;
//    }
//    .mat-form-field-wrapper{
//      padding: 0;
//    }
//    .search-input{
//       margin: 0px;
//      color: #4f4f4f;
//      font-size: 16px;
//      padding-left: 10px;
//
//    }
//    .container-fluid {
//      padding-right: 0 !important;
//      padding-left: 0 !important;
//      .container {
//        padding-right: 0 !important;
//        padding-left: 0 !important;
//      }
//    }
//    .man_outer-wrapper {
//      margin: 10px 0;
//      border: 1px solid lightgray;
//      border-radius: 3px;
//    }
//    .man_inner-wrapper {
//      display: flex;
//      justify-content: space-between;
//      flex-wrap: wrap;
//      .items {
//        // flex-grow: 1;
//        // @include padding-all(10);
//        //   @include padding-right(10);
//      }
//      .mat-form-field {
//        width: 100%;
//      }
//    }
//    .man_wrapper-items--left {
//      @include align-self(center);
//      // width: 60%;
//      flex-grow: 1;
//      @include respond-to("small") {
//       // border-bottom: 1px solid lightgray;
//      }
//      @include respond-to("xxx-large") {
//       // border-bottom: unset;
//      }
//    }
//    .man_wrapper-items--right {
//      @include align-self(center);
//      // width: 40%;
//      .inner-right {
//        display: flex;
//        // flex-grow: 1;
//        @include respond-to("x-small") {
//          // border-bottom: 1px solid lightgray;
//        }
//        @include respond-to("medium") {
//          width: 100%;
//        }
//        .mat-form-field-suffix {
//          @include padding-right(7);
//        }
//        .items {
//          flex-grow: 1;
//          flex-basis: 25%;
//          &.date,
//          &.time,
//          &.filter {
//            border-left: 1px solid lightgray;
//            // display: flex;
//            align-items: center;
//            .mat-form-field-infix {
//              @include respond-to("x-small") {
//                display: none;
//              }
//              @include respond-to("small") {
//                display: block;
//              }
//            }
//            .image-wrapper {
//              div {
//                @include respond-to("x-small") {
//                  display: none;
//                }
//                @include respond-to("small") {
//                  display: block;
//                }
//              }
//            }
//          }
//
//          &:last-child {
//            .serarch-btn {
//              height: 100%;
//              background-color: $color-pink;
//              color: #ffff;
//              .btn-text {
//                @include respond-to("x-small") {
//                  display: none;
//                }
//                @include respond-to("small") {
//                  display: block;
//                }
//              }
//              .mat-button-wrapper {
//                display: flex;
//                align-items: center;
//                flex-direction: row;
//                i {
//                  padding-right: 5px;
//                }
//              }
//            }
//            .mat-button {
//              border-radius: 0 3px 3px 0 !important;
//            }
//          }
//          .image-wrapper {
//            display: flex;
//            flex-direction: column;
//            .mat-button {
//              min-width: unset;
//            }
//            img {
//              height: 25px;
//            }
//          }
//        }
//      }
//    }
//    .mat-form-field {
//      &.remove_underline {
//        .mat-form-field-underline {
//          height: 0px !important;
//          display: none !important;
//        }
//      }
//    }
//  }
//}

    .slid-menu-wrap{
    border: 1px solid #fff;
    height:300px;
}



.time-popup {
  padding: 10px;
  overflow:hidden;
  //max-width: 100% !important;
  .clock {
    padding-right: 5px;
    display: flex;
    align-items: center;
  }
  .time-container{
      @include respond-to("x-small") {
        flex-direction:column;
    }
    @include respond-to("small") {
        flex-direction:row;
    }
    @include respond-to("medium") {
        flex-direction:row;
    }
    @include respond-to("large") {
        flex-direction:row;
    }
    @include respond-to("x-large") {
        flex-direction:row;
    }
  }
  .radio-container{
      padding: 10px;
    /*padding-bottom: 0 !important;*/
    background-color: #f5f5f5;
    margin-bottom: 15px;
    border: 1px dashed #9e9e9e;
    border-radius: 5px;
  }

    .add-padding{
      padding-right:20px;
  }
    i{
        margin-top: 22px;
    }

  .date-select{
      width: 100%;
  }
  .btn-content {
    justify-content: flex-end !important;
    .btn__card{
        padding: 0 40px;
            margin-left: 5px;
    }
  }
  .material-icons {
    display: inline !important;
  }
}
.mat-font--change {
  font-family: $font-montserrat;
  @include font-size();
}
.mat-form-field-infix {
  @include font-size();
}
.mat-form-field-infix{
    line-height:1.2;
}
.mat-button {
  font-family: $font-montserrat;
  @include font-size();
}
.mat-icon-button,
.mat-icon {  
font-size: 25px;
}
.filter {
    @include font-size(14);
  text-align: -webkit-right;
  &--menu {
    background-color: #202020;
    max-width: 100% !important;
    color: #fff;
    .mat-form-field-label {
        color: #fff;
    }
    .mat-select-value-text {
        span {
            // color: #fff;
        }
    }
        
    .mmc_flex--container {
      flex-direction: column;
      @include padding-all(20);
    }
    .mat-form-field {
      width: 100%;
    }
    .mat-form-field-appearance-legacy {
        .mat-form-field-underline {
            background-color: #a0a0a0 !important;
        }
    }
    .btn-section {
      @include padding-top(10);
      // justify-content: flex-end !important;
    }
    .close-btn {
      text-align: -webkit-right;
      @include padding-bottom(10);
    }
  }
}

.mat-menu-panel{
-webkit-border-radius: 3px;
-webkit-border-top-left-radius: 0;
-moz-border-radius: 3px;
-moz-border-radius-topleft: 0;
border-radius: 3px;
border-top-left-radius: 0;
}

.search-filter{
    cursor: pointer;
    @media only screen and (max-width: 1024px) {
        height:50px !important;
    }
    .icon{
        padding-top: 7px;
    }
    .text{
        @include font-size(13);
    }
    &[aria-expanded="true"]{
        color:#ccc;
        background-color: #202020;
        cursor:pointer;      
        &:hover{
            cursor:pointer;
            color:#ed3e84;
            i{
                color:#ccc;
            }
        }
        i{
            color:#ccc;
        }
        i.icn-aminity{
            visibility: hidden;
            color: #ffffff !important;
        }
    }
    .icon-clear {
        &:before {
        font-family: 'Material Icons';
        content: '\e5cd';
        font-size: 18px;
        font-weight: bold;
        }
    }
}

.search-filter[aria-expanded="true"] {
  .pw-text-color__quinary {
    color: #ffffff !important;
  }
}

.mat-paginator{
    background: #fff;
}

.mat-form-field-suffix{
    cursor: pointer;
}

.btn-click{
    cursor : pointer;
}

.count-cage{
          @include respond-to("x-small") {
            flex-direction: column;
          }
          @include respond-to("small") {
            flex-direction: column;
          }
          @include respond-to("medium") {
            flex-direction:row;
          }
          @include respond-to("large") {
            flex-direction:row;
          }
          @include respond-to("x-large") {
            flex-direction:row;
          }
      .no-venu{
          @include respond-to("x-small") {
            border-bottom: none;
            padding-bottom: 10px;
            width: 100%;
          }
          @include respond-to("small") {
            border-bottom: none;
            padding-bottom: 10px;
            width: 100%;
          }
          @include respond-to("medium") {
            border-bottom: none;
            padding-bottom: 0px;
            width: 75%;
          }
          @include respond-to("large") {
            border-bottom: none;
            padding-bottom: 0px;
            width: 75%;
          }
          @include respond-to("x-large") {
            border-bottom: none;
            padding-bottom: 0px;
            width: 75%;
          }
      }
      .sortby{
        white-space: nowrap;
        @include respond-to("x-small") {
          width: 100%;
          // border-top: 1px solid #ccc;
          // padding-top: 10px;
          // margin-top: 10px;
        }

    
        @include respond-to("small") {
          width: 100%;
          // border-top: none;
          // border-top: 1px solid #ccc;
          // padding-top: 10px;
          // margin-top: 10px;
        }
        @include respond-to("medium") {
          width: 25%;
          border-top: none;
          padding-top: 10px;
          margin-top: 10px;
        }
        @include respond-to("large") {
          width: 25%;
          border-top: none;
          padding-top: 0px;
          margin-top: 0px;
        }
        @include respond-to("x-large") {
          width: 25%;
          border-top:none;
          padding-top: 0px;
          margin-top: 0px;
        }
    }

    span.sortby{
      &:before{
        @include respond-to("x-small") {
          content: "";
          width: 70%;
          height: 1px;
          background: #ccc;
          align-self: center;
          margin-right: 10px;
        }
        @include respond-to("small") {
          content: "";
          width: 89%;
          height: 1px;
          background: #ccc;
          align-self: center;
          margin-right: 10px;
        }
        @include respond-to("medium") {
          content: none;
        }
        @include respond-to("large") {
          content: none;
        }
        @include respond-to("x-large") {
          content: none;
        }
      }
    }
  }
.facility-search-input{
    flex-direction:row !important;
    i{
        padding-right: 20px;
        cursor:pointer;
        font-size:23px;
    }
    .mmc_flex__item--inherit{
        display:none;
    }
    .mat-form-field-flex{
        width: calc(100% - 15px);
        margin-left: 15px;
    }
}


.search-info-panel{
    width:40vw !important;
    @media only screen and (max-width:768px) {
        width:90vw !important;
    }
    padding:15px;
    .mat-menu-content:not(:empty){
        padding:0px !important;
    }
    ul{
        margin:0px !important;
    }
    li{
        color: #616161;
        list-style-type:disc !important;
        margin-left:18px;
    }
}

.main_search {
    .search-filter {
	    i.icn-aminity {
		    visibility: visible;
      }
      .icon-filter-filled-tool-symbol {
        font-size: 10px;
      }
    }

    .facility-search-input-cage {
      flex: 0 0 35% !important;
    }
}

.filter--menu {
  input {
    color: black;
  }
}

.cal-view-open{
  .mat-paginator-container{
      justify-content: flex-start !important;
  }

  .legend-pagi-wrapper {
      justify-content: space-between !important;
      
  }
}

.legend-pagi-wrapper.cal-view-open {
  justify-content: space-between !important;
  width: 100% !important;
    &.mmc_card{
      background-color: white !important;
    }
}

.legend-pagi-wrapper.mmc_card {
  box-shadow: unset;
}


.availability-legend-wrapper.cal-view-open {
  width: 35%;

  fieldset.availabiblity-legend {
      border: 1px solid #9e9e9e !important;
      border-radius: 3px;
      padding: 15px;
      /* width: 90%; */
      margin: 10px;
      position: relative;
  }

  legend.pw-txt-s2 {
      width: 20%;
      border-bottom: none;
  }
  .mmc_flex--container.key-container {
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: -25px;
  }
  .mmc_flex__item.key-item {
      position: relative;
      justify-content: center;
      display: flex;
      align-items: center;
  }
  .key-color {
      margin-right: 8px;
      height: 10px;
      width: 15px;
      border: 1px solid #bababa;
  }
}

@media screen and (max-width:768px) {
  .legend-pagi-wrapper.cal-view-open{
      flex-direction: column-reverse;
  }

  .availability-legend-wrapper.cal-view-open {
      width: 100%;
  }

  .mat-paginator{
      .mat-paginator-container{
          flex-wrap: unset !important;
          .mat-paginator-page-size {
              width: 100%;
          }
  
          .mat-paginator-range-actions {
              width: 55%;
              justify-content: space-between;
          }
  
          .mat-paginator-range-label {
              margin: 0 1px 0 21px;
              width: 43px;
          }
          
      } 
  }

}
.filter-search-wrapper{
  padding: 5px !important;
  position: sticky !important;
  top: 0px;
  z-index: 999999999999 !important;
  background: white;
}

//this style only applying for venue-select-search
.cdk-overlay-container .cdk-overlay-pane .mat-select-panel.venue-select-panel {
  width: auto !important;
  min-width: 320px !important;
  margin-left: 1.2em;

  @media screen and (max-width: 768px) {
    width: 80% !important;
    min-width: unset !important;
  }
}
//this style only applying for venue-category-select-search
.cdk-overlay-container .cdk-overlay-pane .mat-select-panel.venue-category-select-panel {
  margin-left: 0.2em;
  width: auto !important;
  min-width: 310px !important;
}
.filter-apply-btn-wrapper{
  position: sticky !important;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  width: 100%;
  background: white;
  border-top: 1px solid #cfcfcf;
  box-shadow: 0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f;
}
.apply-btn {
  // position: sticky !important;
  width: 70px !important;
  border-radius: 50px !important;
  margin: 5px 10px !important;
  // bottom: 15px;
  // right: 10px;
  // float: right;
  display: flex !important;
  align-items: center;
  height: 35px !important;
}

.cdk-overlay-pane{
  overflow-y: hidden !important;
}
.mat-datepicker-content {
  border: 2px solid #bebebe82 !important;
}

.search-map {
  .agm-map-container-inner {
    .gm-style-iw-c {
      @include respond-to("x-small") {
        max-width: 400px !important;
      }

      @include respond-to("small") {
        max-width: 400px !important;
      }

      @include respond-to("medium") {
        max-width: 700px !important;
      }

      @include respond-to("large") {
        max-width: 1100px !important;
      }

      @include respond-to("x-large") {
        max-width: 1100px !important;
      }

      .agm-info-window-content {
        .aminity-icons--cage {
          .available-cage {
            position: unset !important;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .mat-paginator .mat-paginator-container .mat-paginator-range-label {
    margin: 0 1px 0 6px;
  }
}