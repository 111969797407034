@import "../helpers/mixins";

 .mat-menu-panel{
      
      @include respond-to("x-small") {
              width:100% !important;
            }
             @include respond-to("small") {
            width:100% !important;            }
            @include respond-to("medium") {
                width:100% !important; 
            }
            @include respond-to("large") {
                  width:inherit !important;
            }
            @include respond-to("x-large") {
                 width:inherit !important;
            }
  }
.date-range-picker-card-template{
              height: unset;
               position: unset;
               overflow: unset;
               top: unset;
               left: unset;
               right: unset;
}
@media screen and (max-width: 768px) {
  .date-range-picker-card-template{
    height: 500px;
     position: absolute;
     overflow: auto;
     top: 20px;
     left: 40px;
     right: 0;
  }
}

 .cdk-overlay-pane{
  overflow: auto;
      /*width:100% !important;


             @include respond-to("x-small") {
              width:100% !important;
            }
             @include respond-to("small") {
            width:100% !important;            }
            @include respond-to("medium") {
                width:100% !important; 
            }
            @include respond-to("large") {
                  width:auto !important;
            }
            @include respond-to("x-large") {
                 width:auto !important;
            }*/

 }

.main_filter {
  .mat-form-field-underline {
    display: none;
  }
  border: 1px solid #ccc;
  background-color: #fff;
  height: 60px;
  margin: 20px 0;
  @include respond-to("x-small") {
    height: auto;
  }
  @include respond-to("small") {
    height: auto;
  }
  @include respond-to("medium") {
    height: auto;
  }
  @include respond-to("large") {
    height: 60px;
  }
  @include respond-to("x-large") {
    height: 60px;
  }

  @include border-radius(5px);

  .mmc_flex--container {
    height: 100%;
    @include respond-to("x-small") {
      flex-direction: column;
    }
    @include respond-to("small") {
      flex-direction: column;
    }
    @include respond-to("medium") {
      flex-direction: row;
    }
    @include respond-to("large") {
      flex-direction: row;
    }
    @include respond-to("x-large") {
      flex-direction: row;
    }
  }

  .input_cage {
    flex: 0 0 60%;
    padding-left: 15px;
    color: #656565;

    @include respond-to("x-small") {
      flex: 0 0 100%;
      border-bottom: 1px solid #ccc;
    }
    @include respond-to("small") {
      flex: 0 0 60%;
    }
    @include respond-to("medium") {
      flex: 0 0 60%;
      border-bottom: none;
    }
    @include respond-to("large") {
      flex: 0 0 60%;
      border-bottom: none;
    }
    @include respond-to("x-large") {
      flex: 0 0 60%;
      border-bottom: none;
    }

    .mat-form-field {
      width: 100%;
    }
  }

  .filter_cage {
    flex: 0 0 40%;
    .input_cage {
      background-color: #00bfa5;
    }

    .btn--cage {
      @include respond-to("x-small") {
        width: 100%;
        border-bottom: 1px solid #ccc;
      }
      @include respond-to("small") {
        width: 100%;
        border-bottom: 1px solid #ccc;
      }
      @include respond-to("medium") {
        width: 50%;
        border-bottom: 1px solid #ccc;
      }
      @include respond-to("large") {
        width: 50%;
        border-bottom: none;
      }
      @include respond-to("x-large") {
        width: 50%;
        border-bottom: none;
      }
    }
  }

  .btn--cage {
      > .mmc_flex--container {
        flex-direction: row;
        flex-wrap: nowrap;
      }
    width: 50%;

    @include respond-to("medium") {
      width: 50%;
    }
    @include respond-to("large") {
      width: 50%;
    }
  }

  .pax {
    border-left: 1px solid #ccc;
   // border-right: 1px solid #ccc;
    width: 50%;
    padding: 0 5px;
    background-color: #eee;
    .mat-form-field {
      width: 100%;
    }
    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      margin-top: 9px;
    }
  }
  .date {
    border-right: 1px solid #ccc;
    width: 50%;
    padding: 0 5px;

    @include respond-to("medium") {
      width: 50%;
    }

    .mat-datepicker-toggle-default-icon {
      width: 50px !important;
    }
    .mat-icon-button {
      width: 30px !important;
      height: 30px !important;
      top: -29px;
      position: absolute;
      left: -35px;
    }
    .mat-form-field {
      width: 100%;
      margin-top: 10px;
    }
  }

  .btn--search {
    text-transform: uppercase;
    text-align: center;
    justify-content: center;

    &.--facility {
      //   cursor: pointer;
      border-bottom: 5px solid #2ecd73;
      color: #fff;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      background-color: #80d4a3;

      width: 80%;
    }
    &.--venue {
      cursor: pointer;
      color: #656565;
      width: 100%;
    }

    &._icon {
      justify-content: center;
      background-color: #30b469;
      color: #fff;
      width: 20%;
      text-align: center;
      justify-content: center;
      &:hover {
        background-color: #992e98;
      }
    }

    @include border-radius(1px);
    @include font-size(18);
    font-weight: 600;

    span {
      @include respond-to("medium") {
        display: none;
      }

      @include respond-to("x-large") {
        display: block;
      }
    }

    i {
      font-size: 30px !important;
      margin-right: 10px;
      font-size: 16px;
    }
  }
}

//
//.man_ {
//  &search {
//
//    .mat-form-field-wrapper{
//
//    }
//
//    .items{
//      padding-left: 5px;
//      @include  align-content(center);
//    }
//    .mat-form-field-infix{
//      padding: 0;
//      border: 0;
//    }
//    .mat-form-field-wrapper{
//      padding: 0;
//    }
//    .search-input{
//       margin: 0px;
//      color: #4f4f4f;
//      font-size: 16px;
//      padding-left: 10px;
//
//    }
//    .container-fluid {
//      padding-right: 0 !important;
//      padding-left: 0 !important;
//      .container {
//        padding-right: 0 !important;
//        padding-left: 0 !important;
//      }
//    }
//    .man_outer-wrapper {
//      margin: 10px 0;
//      border: 1px solid lightgray;
//      border-radius: 3px;
//    }
//    .man_inner-wrapper {
//      display: flex;
//      justify-content: space-between;
//      flex-wrap: wrap;
//      .items {
//        // flex-grow: 1;
//        // @include padding-all(10);
//        //   @include padding-right(10);
//      }
//      .mat-form-field {
//        width: 100%;
//      }
//    }
//    .man_wrapper-items--left {
//      @include align-self(center);
//      // width: 60%;
//      flex-grow: 1;
//      @include respond-to("small") {
//       // border-bottom: 1px solid lightgray;
//      }
//      @include respond-to("xxx-large") {
//       // border-bottom: unset;
//      }
//    }
//    .man_wrapper-items--right {
//      @include align-self(center);
//      // width: 40%;
//      .inner-right {
//        display: flex;
//        // flex-grow: 1;
//        @include respond-to("x-small") {
//          // border-bottom: 1px solid lightgray;
//        }
//        @include respond-to("medium") {
//          width: 100%;
//        }
//        .mat-form-field-suffix {
//          @include padding-right(7);
//        }
//        .items {
//          flex-grow: 1;
//          flex-basis: 25%;
//          &.date,
//          &.time,
//          &.filter {
//            border-left: 1px solid lightgray;
//            // display: flex;
//            align-items: center;
//            .mat-form-field-infix {
//              @include respond-to("x-small") {
//                display: none;
//              }
//              @include respond-to("small") {
//                display: block;
//              }
//            }
//            .image-wrapper {
//              div {
//                @include respond-to("x-small") {
//                  display: none;
//                }
//                @include respond-to("small") {
//                  display: block;
//                }
//              }
//            }
//          }
//
//          &:last-child {
//            .serarch-btn {
//              height: 100%;
//              background-color: $color-pink;
//              color: #ffff;
//              .btn-text {
//                @include respond-to("x-small") {
//                  display: none;
//                }
//                @include respond-to("small") {
//                  display: block;
//                }
//              }
//              .mat-button-wrapper {
//                display: flex;
//                align-items: center;
//                flex-direction: row;
//                i {
//                  padding-right: 5px;
//                }
//              }
//            }
//            .mat-button {
//              border-radius: 0 3px 3px 0 !important;
//            }
//          }
//          .image-wrapper {
//            display: flex;
//            flex-direction: column;
//            .mat-button {
//              min-width: unset;
//            }
//            img {
//              height: 25px;
//            }
//          }
//        }
//      }
//    }
//    .mat-form-field {
//      &.remove_underline {
//        .mat-form-field-underline {
//          height: 0px !important;
//          display: none !important;
//        }
//      }
//    }
//  }
//}
.time-popup {
  padding: 10px;
  max-width: 100% !important;
  background-color:#fff;
  .clock {
    padding-right: 5px;
    display: flex;
    align-items: center;
  }
  .btn-content {
    justify-content: flex-end !important;
  }
  .material-icons {
    display: inline !important;
  }
}
//
//.mat-font--change {
//  font-family: $font-montserrat;
//  @include font-size();
//}
//.mat-form-field-infix {
//  @include font-size();
//}
//.mat-button {
//  font-family: $font-montserrat;
//  @include font-size();
//}
//.mat-icon-button,
//.mat-icon {
//  font-size: 25px !important;
//}
.filter {
  text-align: -webkit-right;

 
  i{
      margin-right:10px;
  }



  &--menu {
    background-color: #202020;
    max-width: 100% !important;
    color: #fff;
    .mmc_flex--container {
      flex-direction: column;
      @include padding-all(30);
    }
    .mat-form-field {
      width: 100%;
    }
    .btn-section {
      @include padding-top(30);
      // justify-content: flex-end !important;
    }
    .close-btn {
      text-align: -webkit-right;
      @include padding-bottom(30);
    }
  }
  .mat-button{
    background-color: #3fba74;
    border-radius: 3px;
    padding: 5px;
    font-size: 14px;
    margin-left: 10px;
    span{
        color: white !important;
    }
  }
}
.past-booking-filter{
    min-width:300px;
    max-width:708px;
    padding: 12px 20px;
    @include font-size(14);
    font-weight:600;
    .list-view{
        flex-direction:column;
        font-weight:400;

        .mat-form-field-infix{
            font-size:12px;

            
        }
        .mat-icon-button{
            font-size:16px !important;
        }
        .mat-icon{
                font-size:16px !important;
            }
    }
    .title{
        margin-bottom: 10px;
    }

    .mat-button-wrapper{
        .mat-icon-button{
            font-size:16px !important;
        }
    }
}

@media screen and (max-width: 1024px) {
  .mmc_flex__item.pr-3 {
    padding-right: unset !important;
  }
}
