/*@import "../helpers/mixins.scss";*/

.font {
  //   &__sz--12 {
  //     font-size: 12px;
  //   }
  //   &__sz--16 {
  //     font-size: 16px;
  //   }
  //   &__sz--18 {
  //     font-size: 18px;
  //   }
  //   &__sz--24 {
  //     font-size: 24px;
  //   }
  //   &__sz--32 {
  //     font-size: 32px;
  //   }
  //   &__sz--33 {
  //     font-size: 33px;
  //   }

  //   &__w--300 {
  //     font-weight: 300;
  //   }
  //   &--w--400 {
  //     font-weight: 400;
  //   }
  //   &__w--500 {
  //     font-weight: 500;
  //   }
  //   &__w--600 {
  //     font-weight: 600;
  //   }
  //   &__w--700 {
  //     font-weight: 700;
  //   }
  //   &__w--800 {
  //     font-weight: 800;
  //   }
  //   &__w--900 {
  //     font-weight: 900;
  //   }
  &__upper {
    text-transform: uppercase;
  }

  //font weight classes
  &__light {
    @include font-weight(light);
  }
  &__regular {
    @include font-weight(regular);
  }
  &__medium {
    @include font-weight(medium);
  }
  &__semibold {
    @include font-weight(semibold);
  }
  &__bold {
    @include font-weight(bold);
  }
  &__xbold {
    @include font-weight(extrabold);
  }
  /*&__black {
    @include font-weight(black);
  }*/

  //font size classes
  &__xsmall {
    @include font-size(12);
  }
  &__small {
    @include font-size(13);
  }
  &__normal {
    @include font-size(14);
  }
  &__large {
    @include font-size(16);
  }
  &__xlarge {
    @include font-size(18);
  }
  &__h6 {
    @include font-size(20);
  }
  &__h5 {  

      @include respond-to("x-small") {
       @include font-size(22);
    }
    @include respond-to("small") {
      @include font-size(22);
    }
    @include respond-to("medium") {
       @include font-size(22);
    }
    @include respond-to("large") {
     @include font-size(22);
    }
    @include respond-to("x-large") {
      @include font-size(24);
    }
  }
  &__h4 {
    @include font-size(28);
  }
  &__h3 {
    @include font-size(32);
  }
  &__h2 {
    @include font-size(36);
  }
  &__h1 {
    @include font-size(40);
  }

  //color
  &__yellow {
      color: #d5c51c;
  }
  &__available {
      color: #2ecd73;
  }
  &__nt-available {
      color: #ee3e84;
      //color: #f44336;
  }
  &__blue{
      color: #0275d8;
  }
  &__gray{
      color: #696969;
  }
  &__black{
      color: #000;
  }
   &__white{
      color: #fff;
  }
}

.dark-on-light {
      @include font-smoothing(off);
    }

    .light-on-dark {
      @include font-smoothing(on);
    }