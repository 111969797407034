﻿
.past-booking-details{
    .past-booking-details-container{
        .secondary-header-row.button-wrapper{
            @media (min-width: 769px){
                flex-direction: row;
            }

        }
    }
    .past-booking-details--card-secondary{
        .mcc-expansion-panel-outter{
            .mat-expansion-panel-body{
                .mcc-expansion-panel-inner{
                    .mat-expansion-panel-body{
                        .mcc-expansion-panel-inner-inner{
                            .mat-expansion-panel-content{

                                @media only screen and (min-width : 768px) {
                                    .mmc_expansion__body--row.body-row-height{
                                        .mmc_flex--container{
                                            flex-direction: row !important;
                                            .mmc_flex__item.mmc_flex__item--vcenter.p40{
                                                width: 30% !important;
                                            }
                                            .extras-value{
                                                width: 60%;
                                            }

                                            .mmc_flex__item.mmc_fd--col.mmc_flex__item--hcenter.p20.cog-d-none{
                                                width: 60% !important;
                                            }
                                            .mmc_flex__item.mmc_flex__item--vcenter.p15{
                                                width: 5% !important;
                                            }
                                            .mmc_flex__item.mmc_flex__item--vcenter.mmc_just-content-start.p20.ng-star-inserted{
                                                width: 10% !important;
                                            }
                                            .mmc_flex__item.mmc_flex__item--vcenter.w90{
                                                width: 45px !important;
                                            }
                                        }
                                    }
                                }
                                @media only screen and (max-width : 768px) {
                                    .mmc_expansion__body--row.body-row-height{
                                        .mmc_flex--container{
                                            flex-direction: row !important;
                                            .mmc_flex__item.mmc_flex__item--vcenter.p40{
                                                width: 50% !important;
                                            }
                                            .mmc_flex__item.mmc_fd--col.mmc_flex__item--hcenter.p20.cog-d-none{
                                                width: 40% !important;
                                            }
                                            .mmc_flex__item.mmc_flex__item--vcenter.p15{
                                                width: 5% !important;
                                            }
                                            .mmc_flex__item.mmc_flex__item--vcenter.mmc_just-content-start.p20.ng-star-inserted{
                                                width: 10% !important;
                                            }
                                            .mmc_flex__item.mmc_flex__item--vcenter.w90{
                                                width: 45px !important;
                                                margin-left: 30px;
                                                margin-top: -10px;
                                            }
                                        }
                                    }
                                }

                                @media only screen and (max-width : 590px) {
                                    .mmc_expansion__body--row.body-row-height{
                                        height: 130px !important;
                                        padding-top: 0px !important;
                                        padding-right: 15px !important;
                                        padding-left: 15px !important;
                                        .mmc_flex--container{
                                            flex-direction: column !important;
                                            .mmc_flex__item.mmc_flex__item--vcenter.p40{
                                                width: 50% !important;
                                                .mmc_flex__item.mmc_flex__item--vcenter.p10{
                                                    width: 100% !important;
                                                }
                                                .mmc_flex__item.mmc_flex__item--vcenter.p20{
                                                    width: 100% !important;
                                                    min-width: 220px !important;
                                                    margin-left: 5px;
                                                }
                                            }
                                            .mmc_flex__item.mmc_fd--col.mmc_flex__item--hcenter.p20.cog-d-none{
                                                width: 40% !important;
                                                padding-left: 0px !important;

                                            }
                                            .mmc_flex__item.mmc_flex__item--vcenter.p15{
                                                width: 5% !important;
                                            }
                                            .mmc_flex__item.mmc_flex__item--vcenter.mmc_just-content-start.p20.ng-star-inserted{
                                                width: 10% !important;
                                            }
                                            .mmc_flex__item.mmc_flex__item--vcenter.w90{
                                                width: 45px !important;
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}

mat-expansion-panel-header {
  .facility-expansion-header--container {
    .facilityExpansionHeaderDropdownIcon {

        justify-content: flex-end;

      @media only screen and(max-width: 991.98px) {
        flex: unset;
        display: contents;
        width: 30% !important;
        margin-left: unset !important;
      }
    }
    .mmc_flex__item.mmc_flex__item--vcenter.p15.item-w33-mobile{
        padding: 10px;
    }
  }
}

.facilityExpansionHeaderWidth{
    @media only screen and(max-width: 991.98px) {
        width: 60% !important;
      }
}

.justifyContetntStart{
    @media only screen and(max-width: 991.98px) {
        justify-content: flex-start !important;
    }
  }

.ptMobile{
    @media only screen and(max-width: 991.98px) {
        padding-top: 10px !important;
    }
}

.past-booking-details{
    .alert.success--btn {
  position: relative;
  background: #222;
  margin: 10px 0 30px 0;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 17px;
  font-size: 12px;
      color: #856404;
    background: #fff3cd;
    border: 1px solid #ffeeba;
  /*font-family: Raleway;*/
  font-weight: 600;
  letter-spacing: 0.05rem;
  border-radius: 0;
  -webkit-border-radius: 0;

          >span{
              color:#c34118;
          }
        }

.alert.success:before {
  content: '\f00c';
  font-family: "FontAwesome";
  font-size: 15px;
  padding-right: 5px;
  color: #436332;
}




.ternary-title--semibold {
@media only screen and (max-width: 768px) {
    font-size: 0.75rem;
}
}

.secondary_sub-section-title--medium {
@media only screen and (max-width: 768px) {
    font-size: 0.625rem;
}
}

.secondary_sub-section-title-secondary {
@media only screen and (max-width: 768px) {
    font-size: 0.75rem;
}
}

.secondary_section-title--medium {
@media only screen and (max-width: 768px) {
    font-size: 0.625rem;
}
}


.mmc_flex--container {
width: 100%;
height: 100%;
}

.pr5 {
padding-right: 5px;
}

.icon {
&.f-size-md {
    font-size: 20px;
}
}
.primary-header-row{
margin: 32px 0px !important;
}
.secondary-header-row {
margin: 32px 0px;
margin-top:20px;
&.button-wrapper {
    @include respond-to("x-small") {
        flex-direction: column;
    }

    @include respond-to("small") {
        flex-direction: column;
    }

    @include respond-to("medium") {
        flex-direction: row;
    }

    @include respond-to("large") {
        flex-direction: row;
    }

    @include respond-to("x-large") {
        flex-direction: row;
    }
}

.mmc-btn-xl {
    margin-left: 24px;

            @media only screen and (max-width: 1024px) {
                margin: 16px 5px 0 5px;
            }

            @media only screen and (max-width: 768px){
                margin: 16px 0px 0 0px;
            }
        }
        .link-primary{
            padding-left:20px;
        }

        .mmc_just-content-end {
            @media screen and (max-width: 415px) {
                flex-direction: column;

                .item-w25-mobile {
                    min-width: 150px !important;
                }
            }
        }
    }

    .icon-button {
        .mat-button-wrapper {
            display: flex;
            align-items: center;
        }
    }
    &.icon-button:focus{
        border: 1px solid #000 !important;
      }

.inner-container {
background-color: $pw-bg-color__secondary;
padding: 32px 20px;
/*margin-bottom: 30px;*/
}

.past-booking-details--card {
@include respond-to("x-small") {
    min-height: 200px;
}

@include respond-to("small") {
     min-height: 200px;
}

@include respond-to("medium") {
    min-height: 110px;
}

@include respond-to("large") {
    min-height: 110px;
}

@include respond-to("x-large") {
    min-height: 110px;
}

margin-bottom: 30px;
box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
padding: 20px;
border-radius: 4px;
position: relative;
background-color: #ffffff;
display: flex;

.card-wrapper {
    @include respond-to("x-small") {
        flex-direction: column;
        height: auto;
    }

    @include respond-to("small") {
        flex-direction: column;
        height: auto;
    }

    @include respond-to("medium") {
        flex-direction: row;
        height: auto;
    }

    @include respond-to("large") {
        flex-direction: row;
        height: auto;
    }

    @include respond-to("x-large") {
        flex-direction: row;
        height: auto;
    }

    .status-wrapper {
        @include respond-to("x-small") {
            flex-direction: row-reverse;
            justify-content: flex-end;
        }

        @include respond-to("small") {
            flex-direction: row-reverse;
            justify-content: flex-end;
        }

        @include respond-to("medium") {
            flex-direction: row;
            justify-content: flex-end;
        }

        @include respond-to("large") {
            flex-direction: row;
            justify-content: flex-end;
        }

        @include respond-to("x-large") {
            flex-direction: row;
            justify-content: flex-end;
        }

        .card-status {
            @include respond-to("x-small") {
                margin-left: 0;
                margin-right: 16px;
            }

            @include respond-to("small") {
                margin-left: 0;
                margin-right: 16px;
            }

            @include respond-to("medium") {
                margin-right: 0;
                margin-left: 32px;
            }

            @include respond-to("large") {
                margin-right: 0;
                margin-left: 32px;
            }

            @include respond-to("x-large") {
                margin-right: 0;
                margin-left: 32px;
            }
        }
    }

    .total-field {
        @include respond-to("x-small") {
            justify-content: flex-start;
        }

        @include respond-to("small") {
            justify-content: flex-start;
        }

        @include respond-to("medium") {
            justify-content: flex-end;
        }

        @include respond-to("large") {
            justify-content: flex-end;
        }

        @include respond-to("x-large") {
            justify-content: flex-end;
        }
    }

}
.b-terms-n-conditions{
    flex-direction: column !important;
}

&-secondary {
    margin-bottom: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 20px;
    border-radius: 4px;
    position: relative;
    background-color: #ffffff;
    display: flex;

    .past-booking-facility--card{
        .config-img{
            >img{
                height:16px;
            }
        }
    }

            .mat-expansion-panel-header {
                padding:0 8px 0 24px;
                @media only screen and (max-width: 425px) {
                    padding:0 8px 0 15px;
                }
            }


    .expansion-header-icons {
        margin: 0 32px;
    }

    .mcc-expansion-panel-outter {
        .mat-expansion-panel-body {
            padding: 0px !important;

            .mcc-expansion-panel-inner {
                .mat-expansion-panel-header {
                    @media only screen and (max-width: 1024px) {
                        height: auto !important;
                    }



                    .icon__expand {
                        @media only screen and (max-width: 1024px) {
                            align-items: flex-start;
                        }
                    }
                }


                .mat-expansion-panel-body {
                    padding: 0px 0px 0px 24px !important;

                    .mmc_expansion__body--row-header {
                        height: 65px !important;

                    .mat-checkbox{
                         >label{
                        margin-bottom:0 !important;
                    }
                    }

                    }


                    .mmc_expansion__body--row {
                        margin: 0 0px 0 -24px;
                        padding: 0px 24px 0 24px;

                        @media only screen and (max-width: 1024px) {
                            height: 250px !important;
                        }

                        .expansion-header-icons {
                            margin: 0 16px;


                            @media only screen and (max-width: 1024px) {
                                margin: 0 16px;
                            }
                        }
                    }

                    .mcc-expansion-panel-inner-inner {
                        /*margin: 0 -24px 0;
                        padding: 0px 24px 0 0px;*/
                        .mat-expansion-panel {
                            margin-left: -24px;
                        }

                                .mat-expansion-panel-header{
                                    padding-right:24px;

                                    @media only screen and (max-width: 425px) {
                                        padding-right: 15px;
                                    }

                                    background-color:#FCF6F8 !important;
                                    .expansion-header-icons{
                                        margin: 0 16px !important;
                                    }

                        }

                        .mat-expansion-panel-content {
                            padding: 0px 24px 0px 0px;

                            .mmc_expansion__body--row {
                                margin: 0 -24px 0 -24px;
                                @media only screen and (max-width: 1024px) {
                                    height: 65px !important;
                                }
                            }
                        }
                        .extrasMobile.body-row-height{
                            height: auto !important;
                            .extrasBottomContent{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: row;
                                padding: 10px;
                                gap: 5px;
                                .p20.cog-d-none{
                                    flex-direction: row;
                                    width: 100%;
                                    gap: 10px;
                                    .pw-txt-s3.col-value{
                                        width: max-content;
                                    }
                                }
                                .optionButtonsMobile{
                                    width: auto;
                                    .mmc_flex--container{
                                        flex-direction: row !important;
                                        padding-top: 0 !important;
                                        .mmc_flex__item--vcenter.w50{
                                            width: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .mat-paginator {
                        margin-left: -24px;
                    }
                }
            }
        }
    }



    .mmc_expansion__body--row {
        height: 65px;
        display: flex;
        margin: 0 -24px 0;
        padding: 0 24px 0;
        background-color: #FCF6F8;

        &-header {
            @extend .mmc_expansion__body--row;
            background-color: #FBFBFB;
            height: 110px !important;
            border-bottom: 1px solid #eee;
        }
    }

    .past-booking-facility--card {
        width: 100%;

        .facility-expansion-header--container {
            .mmc_flex__item:nth-child(1) {
            }

            .mmc_flex__item:nth-child(2) {
                .mmc-btn-xl--fourth {
                    @include respond-to("x-small") {
                        min-width: 40px !important;
                    }

                    @include respond-to("small") {
                        min-width: 40px !important;
                    }

                    @include respond-to("medium") {
                        min-width: unset;
                    }

                    @include respond-to("large") {
                        min-width: unset;
                    }

                    @include respond-to("x-large") {
                        min-width: unset;
                    }

                    .btn-icon {
                        margin-right: 8px;

                        @media only screen and (max-width: 1024px) {
                                margin-right:unset;
                            }
                    }

                            .btn-label {
                                @include respond-to("x-small") {
                                    display: unset;
                                    width: unset !important;
                                    margin-right: unset !important;
                                }

                        @include respond-to("small") {
                            display:unset;
                        }

                        @include respond-to("medium") {
                            display: unset;
                        }

                        @include respond-to("large") {
                            display: unset;
                        }

                        @include respond-to("x-large") {
                            display: unset;
                        }
                    }
                }
            }

            .mmc_flex__item:nth-child(3) {
                .expansion-header-icons {
                    @include respond-to("x-small") {
                        margin: 0 16px;
                    }

                    @include respond-to("small") {
                        margin: 0 16px;
                    }

                    @include respond-to("medium") {
                        margin: 0 32px;
                    }

                    @include respond-to("large") {
                        margin: 0 32px;
                    }

                    @include respond-to("x-large") {
                        margin: 0 32px;
                    }
                }
            }
        }

                .mmc_expansion__body--row-header {
                    @include respond-to("x-small") {
                        height: auto !important;
                    }

                    @include respond-to("small") {
                        height: auto !important;
                    }

            @include respond-to("medium") {
                height: 110px !important;
            }

            @include respond-to("large") {
                height: 110px !important;
            }

            @include respond-to("x-large") {
                height: 110px !important;
            }




            .past-booking-facility--container {
                padding-left: 0px;

                @include respond-to("x-small") {
                    flex-wrap: wrap;
                }

                @include respond-to("small") {
                    flex-wrap: wrap;
                }

                @include respond-to("medium") {
                    flex-wrap: unset;
                }

                @include respond-to("large") {
                    flex-wrap: unset;
                }

                        @include respond-to("x-large") {
                            flex-wrap: unset;
                        }
                        .col-value{
                            min-height:20px;
                        }
                        .mmc_flex__item:nth-child(1) {
                            .expanded-row-img {
                                @include respond-to("x-small") {
                                    width: 130px;
                                    object-fit: cover;
                                }

                                @include respond-to("small") {
                                    width: 130px;
                                    object-fit: cover;
                                }

                        @include respond-to("medium") {
                            width: unset;
                            object-fit: cover;
                        }

                        @include respond-to("large") {
                            width: unset;
                            object-fit: cover;
                        }

                                @include respond-to("x-large") {
                                    max-width: 130px;
                                    object-fit: fill;
                                    border-radius: 5px;
                                }
                            }
                        }

                        .mmc_flex__item:nth-child(8) {
                            @media only screen and (max-width: 1024px) {
                                text-align: left;
                            }
                        }

                        .mmc_flex__item.mmc_flex__item--vcenter.item-w100-mobile {
                            width: 130px;
                            padding-right: 10px;
                        }
                    }
                }
            }

            .cancellation-fee-card{
                display: flex;
                height:65px;
                align-items: center;
                margin-bottom: 0;
                margin-top: 20px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                padding: 20px;
                border-radius: 4px;
                position: relative;
                background-color: #ffffff;
            }


        }

.section-subheading {
    margin-right: 5px;
}

&-ternary {
    background-color: #f7faff;
    border: 1px solid #cee0f9;
    border-radius: 4px;
    padding: 20px;
    /*margin-bottom: 30px;*/

    .payment-summery--card {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        margin-bottom: 30px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        padding: 20px;
        border-radius: 4px;
        position: relative;
        background-color: #ffffff;

        @include respond-to("x-small") {
            min-height: 250px;
            align-items: stretch;
        }

                @include respond-to("small") {
                    min-height: 100px;
                    align-items: stretch;
                }

        @include respond-to("medium") {
            min-height: 110px;
            align-items: center;
        }

        @include respond-to("large") {
            min-height: 110px;
            align-items: center;
        }

        @include respond-to("x-large") {
            min-height: 110px;
            align-items: center;
        }


        .payment-summery-wrapper {
            width: 100% !important;
            .booking-payment-card-one {
                width: 40% !important;
                @media screen and (max-width: 767.98px) {
                    width: 100% !important;
                    margin-right: unset;
                }
            }
            .booking-payment-card {
                width: 30% !important;
                @media screen and (max-width: 767.98px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100% !important;
                    margin-right: unset;
                    align-self: center;
                }
            }
            @include respond-to("x-small") {
                text-align: center;
                flex-direction: column;
                height: auto;
            }

            @include respond-to("small") {
                text-align: unset;
                flex-direction: row;
                height: auto;
            }

            @include respond-to("medium") {
                text-align: unset;
                flex-direction: row;
                height: auto;
            }

            @include respond-to("large") {
                text-align: unset;
                flex-direction: row;
                height: auto;
            }

            @include respond-to("x-large") {
                text-align: unset;
                flex-direction: row;
                height: auto;
            }

            .mmc_flex__item{
                 @include respond-to("x-small") {
                border-bottom: 1px solid $pw-border-color;
            }

            @include respond-to("small") {
                 border-bottom: 1px solid $pw-border-color;
            }

            @include respond-to("medium") {
                border-bottom: 1px solid $pw-border-color;
            }

            @include respond-to("large") {
                 border-bottom: none;
            }

            @include respond-to("x-large") {
                border-bottom: none;
            }

            }


        }

        .btn-wrapper {
            @media only screen and (max-width: 1024px) {
                width: 100%;
                display: flex;
            }

            > button {
                @media only screen and (max-width: 1024px) {
                    flex-grow: 1;
                }
            }
        }
    }



     .detailed-information-wrapper{
        height: 65px;
        display: flex;
        /*margin: 0 -24px 0;*/
        padding: 0 48px 0 24px;
        background-color: #FBFBFB;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.11);
        margin-bottom: 5px;

        .mmc_flex__item {
            height: 100%;
        }
    }

     .mat-expansion-panel-header {
        @media only screen and (max-width: 1024px) {
            height: 350px !important;
            align-items: stretch;
        }

        .icon__expand {
            @media only screen and (max-width: 1024px) {
                align-items: flex-start;
            }
        }

        .status {
            margin-left: 0;
        }

        .item-btn-wrapper {
            @media only screen and (max-width: 1024px) {
                width: 100%;
                padding: 2px 0 20px 0;
            }

            .btn-wrapper {
                @media only screen and (max-width: 1024px) {
                    display: flex;
                    width: 100%;
                }

                > button {
                    @media only screen and (max-width: 1024px) {
                        flex-grow: 1;
                    }
                }
            }
        }
    }


      .detailed-information-expansion-body {
        @include respond-to("x-small") {
            height: auto;
        }

        @include respond-to("small") {
            height: auto;
        }

        @include respond-to("medium") {
            height: 65px;
        }

        @include respond-to("large") {
            height: 65px;
        }

        @include respond-to("x-large") {
            height: 65px;
        }
    }

      .mat-expansion-panel-body {
        background-color: #b2dfdb30 !important;
        border-left: 4px solid #009688;
    }


}

&-fourth {
    @extend .past-booking-details--card-ternary;

    .mmc_expansion__header--row {
        height: 65px;
        display: flex;
        /*margin: 0 -24px 0;*/
        padding: 0 48px 0 24px;
        background-color: #FBFBFB;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.11);
        margin-bottom: 5px;

        .mmc_flex__item {
            height: 100%;
        }
    }

    .mat-expansion-panel-header {
        @media only screen and (max-width: 1024px) {
            height: 250px !important;
            align-items: stretch;
        }

        .icon__expand {
            @media only screen and (max-width: 1024px) {
                align-items: flex-start;
            }
        }

        .status {
            margin-left: 0;
        }

        .item-btn-wrapper {
            @media only screen and (max-width: 1024px) {
                width: 100%;
                padding: 2px 0 20px 0;
            }

            .btn-wrapper {
                @media only screen and (max-width: 1024px) {
                    display: flex;
                    width: 100%;
                }

                > button {
                    @media only screen and (max-width: 1024px) {
                        flex-grow: 1;
                    }
                }
            }
        }
    }

    .detailed-information-expansion-body {
        @include respond-to("x-small") {
            height: auto;
        }

        @include respond-to("small") {
            height: auto;
        }

        @include respond-to("medium") {
            height: 65px;
        }

        @include respond-to("large") {
            height: 65px;
        }

        @include respond-to("x-large") {
            height: 65px;
        }
    }

    .mat-expansion-panel-body {
        background-color: #b2dfdb30 !important;
        border-left: 4px solid #009688;
    }
}

.date-time-caption {
    margin-top: 10px;
}
}

.img-icon {
margin-right: 5px;
width: 20px;
height: 20px;
}

.expanded-row-img {
border-radius: 5px;
width: 100px;
height: 80px;

&--addextra {
    @extend .expanded-row-img;
    width: 50px !important;
    height: 50px !important;
}
}

    .facility-img {
        border-radius: 2px;
        width: 80px;
        height: 40px;
        margin-right: 20px;
        object-fit: cover;

        @media screen and (max-width:400px) {
            width: 60px;
            margin-right: 10px;
        }
    }
    .facility-expansion-header--container .mmc_flex__item.pw-txt-s2.pw-text-color__primary {
        font-size: 10px !important;
    }

.mmc-btn-xl--ternary {
margin-left: 24px;
}

.bold {
font-weight: 600;
}

.total-label {
margin-right: 5px;
}

.card-status {
margin-left: 32px;
}

.file-upload--card {
margin-bottom: 30px;
box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
padding: 20px;
border-radius: 4px;
position: relative;
background-color: #ffffff;
display: flex;

@include respond-to("x-small") {
    text-align: center;
}

@include respond-to("small") {
   text-align: center;
}

@include respond-to("medium") {
    text-align: unset;
}

@include respond-to("large") {
    text-align: unset;
}

@include respond-to("x-large") {
    text-align: unset;
}

.file-drop{
    width:100%;

    .drop-zone{
            margin: auto;
            height: 100px;
            border: 1px dotted $pw-border-color__secondary;
            border-radius: 5px;

                    .content{
                        flex-direction:column;

                        div:nth-child(4) {
                            display: none !important;
                        }
                    }
            }
        }
    }

.mcc-file-upload {
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
height: 40px;
width: 40px;
background-color: #9e9e9e;
}

.upload-doc-heading {
margin-bottom: 20px;

.txt-colored {
    color: #ed3e84;
}
}

.document-upload-section {
border: 1px dotted #696969;
border-radius: 3px;
padding: 10px;
}

.uploaded-file-list--card {
margin-bottom: 30px;
box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
padding: 20px;
border-radius: 4px;
position: relative;
background-color: #ffffff;
display: flex;

@include respond-to("x-small") {
    flex-direction: column;
    height: auto;
}

@include respond-to("small") {
    flex-direction: column;
    height: auto;
}

@include respond-to("medium") {
    flex-direction: column;
    height: auto;
}

@include respond-to("large") {
    flex-direction: column;
    height: auto;
}

@include respond-to("x-large") {
    flex-direction: column;
    height: auto;
}


.file-list-row {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin-bottom: 10px;

    &:nth-last-child(1) {
        margin-bottom: 8px !important;
    }
}

.icon-col {
    background-color: #006ff8;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    .material-icons {
        color: #fff;
    }
}

.label-col {
    padding: 10px 0px 10px 10px;
}

.download-col {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    .material-icons {

        &:hover {
            cursor: pointer;
            color: #ED3E84;
        }
    }
}
}

.btn-cancel-booking {
color: #F44336;
height: 36px;
width: 100%;
background-color: #fff;
border: 1px solid #F44336;
border-radius: 4px;
transition: 0.3s !important;

&:hover{
    background-color: #F44336;
    color: #fff;
}

@media only screen and (max-width: 768px) {
    font-size: 12px !important;
}
}

.section-heading {
margin-bottom: 15px;
margin-top: 15px;
}

.note-message {
font-weight: 500;
color: #000;
font-size: 0.75rem;
margin-bottom: 30px;
text-align: center;
}

.bordered-btn {
display: flex;
align-items: center;
max-width: 150px;
padding: 5px;
justify-content: center;
border: 1px dotted #000;
color: #ed3e84;
border-radius: 5px;
transition: 0.3s;
max-height: 20px;
margin-right: 10px;

.icon-calendar, .material-icons {
    margin-right: 3px;
}

&:hover {
    background-color: #ed3e84;
    color: #fff;
    cursor: pointer;
    border: 1px dotted #ed3e84;
}
}

.mat-expansion-panel {
.mat-expansion-panel-header {
    &.mat-expanded {
        border-bottom: 1px solid $pw-border-color;
    }

    .mat-expansion-panel-header-title {
        align-items: center;
    }

    .mat-expansion-panel-header-description {
        justify-content: flex-end;
    }
}

.mat-expansion-panel-body {
    padding: 0px 24px 0px !important;
}
}

.expansion-header-icons {
display: flex;
align-items: center;
position: relative;

.count {
    background-color: $pw-badge-bg-color__primary;
    color: $pw-badge-text-color;
    border-radius: 50px;
    padding: 2px;
    height: 12px;
    width: 12px;
    font-size: 8px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -6px;
    top: -8px;
}
}

// .navbar-collapse{
// margin:0 -40px !important;
// }
.document-select-dropdown{
background-color:#fff;
padding: 15px;
padding-bottom: 10px;
.mat-form-field{
    margin-bottom:3px;
    top:4px;
    .mat-form-field-wrapper{
        padding-bottom:2px;
    }
    .mat-form-field-label-wrapper{
        min-height: 50px;
        top: -21px;
    }
    .mat-form-field-infix{
        padding:0px;
        border-top:none;
        font-size: 16px;
        letter-spacing: normal;
        word-spacing: 0;
    }
    .mat-form-field-underline{
        bottom:0px;
    }
    &.mat-form-field-should-float{
        .mat-form-field-label{
            top:25px;
            font-weight:500;
            color:#7d7d7d;
        }
    }
}
}
/*Changes summery expansion*/

.changes-summery-wrapper{
.mat-expansion-panel-body{
   padding:0 15px !important;
}

.changes-summery-expansion-body{
   padding:15px;

   .changes-summery-row{
       background:#fff;
       min-height:56px;
       height:auto;
   }

   .changes-summery-row:not(:last-child){
       margin-bottom:4px;
   }
}
}
}

@media only screen and (max-width: 768px){
    .past-booking-details .secondary-header-row.button-wrapper .mmc_flex__item.mmc_just-content-end {
        flex-direction: column;
        width: 100%;

        button {
            width: 100% !important;

            .btn-label {
                width: 90% !important;
            }
            .btn-icon {
                width: unset !important;
            }
        }
    }
}

@media only screen and (max-width: 767.98px) {
  .iconSetBottom {
    width: 100%;
    padding-bottom: 15px;
    .btn-label {
      width: unset !important;
      margin-right: unset !important;
    }
  }
  mat-expansion-panel-header {
    .facilityExpansionHeaderIcons {
        display: none !important;
    }
  }

  .mmc_expansion__body--row {
    .mmc_flex--container {
      .upsellName {
        width: 20% !important;
      }
      .upsellTotal {
        margin-left: 20px;
      }
      .p15 {
        .pw-txt-s3.col-value.upsellRate {
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
  }

}

@media (max-width: 992px){
    .extrasDesktop{
        display: none !important;
    }
}

@media only screen and (min-width: 991.98px) {
  .iconSetBottom, .extrasMobile {
    display: none !important;
  }
}


//Global

.pw-txt-bold{
font-weight:700 !important;
}

.pw-txt-semibold{
font-weight:600 !important;
}

.multiple-choice-container{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.mmc_fd--col {
    flex-direction: column;
    .answer-row.mmc_flex__item.multi-choice-w-text{
        display: flex;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            gap: 20px;
        }

        .answer-row.mmc_flex__item{
            display: flex;
            
        }
        .mat-form-field {
            width: auto !important;
            .mat-form-field-underline{
                display: none !important;
            }
            .mat-form-field-infix{
                border: 2px solid #0000006b;
                border-radius: 8px;
                input{
                    padding-left: 5px;
                    width: 95%;
                }
                .mat-form-field-label{
                    margin-left: 5px;
                }
            }
        }
    }
}



.past-booking-details--card-ternary{
    .mmc_fd--col {
        flex-direction: column;
        margin-right:2rem;
    }
    @media only screen and (max-width: 768px) {
        .mmc_fd--col {
            flex-direction: row;
            margin-left: unset;
        }
        .mmc_txt-align--right {
            margin-right: auto;
            text-align: left !important;
        }

        .pw-text-semi-bold.mmc_txt-align--right{
            margin-left: auto;
            margin-right: unset;
            text-align: right !important;
        }
    }
}

.mmc_txt-align--right {
text-align: right;
}

.mmc_txt-align--center {
text-align: center;
}

.mmc_flex__item--vcenter.doc-up-text--mobile.d-mobile.ng-star-inserted {
    text-align: center;
}

.mmc_flex__item--hcenter {
display: flex;
justify-content: center;
}

.mmc_flex__item--hstart {
display: flex;
justify-content: flex-start;
}

.mmc_flex__item--hend {
display: flex;
justify-content: flex-end;
}

.mmc_flex__item--hcenter-mobile {
@media only screen and (max-width: 768px) {
                               display: flex;
                                justify-content: center !important;
                            }


}

.mmc_just-content-end {
display: flex;
justify-content: flex-end;
}

.mmc_just-content-start {
display: flex;
justify-content: flex-start;
}

.mmc_just-content-center {
display: flex;
justify-content: center;
}

.mmc_txt--link {
color: #0275d8;
text-decoration: none;

&:hover {
text-decoration: underline;
cursor: pointer;
}
}

.gray-bg {
background-color: $pw-bg-color__tertiary;
padding: 20px 0px;
}

.booking-document-upload{
.container{
min-width:100%;
}

}






/*.navbar-collapse

.container{

}*/

.mat-expansion-panel-header[aria-disabled=true] {
color: unset !important;
padding-right: 8px;
}

.mmc_expansion__body--row {
height: 55px;
display: flex;
border-bottom:1px solid $pw-border-color;

&-header {
@extend .mmc_expansion__body--row;
background-color: #FBFBFB;
margin: 0 -24px 0;
padding: 0 24px 0;
border-bottom: 1px solid $pw-border-color;
}
}

.mat-expansion-panel {
.mat-expansion-panel-header {
&.mat-expanded {
    border-bottom: 1px solid $pw-border-color;
}

.mat-expansion-panel-header-title {
    align-items: center;
}

.mat-expansion-panel-header-description {
    justify-content: flex-end;
}
}

.mat-expansion-panel-body {
padding: 0px 0px 0px 24px;
}
}




.mat-accordion {
width: 100%;
}

.h-mobile{
@include respond-to("x-small") {
    display:none !important;
}

@include respond-to("small") {
     display:none !important;
}

@include respond-to("medium") {
   display:block !important;
}

@include respond-to("large") {
   display:block !important;
}

@include respond-to("x-large") {
    display:block !important;
}
}

.d-mobile{
@include respond-to("x-small") {
     display:block !important;
}

@include respond-to("small") {
    display:block !important;
}

@include respond-to("medium") {
     display:none !important;
}

@include respond-to("large") {
     display:none !important;
}

@include respond-to("x-large") {
      display:none !important;
}
}

.item-w25-mobile{
@media only screen and (max-width: 1024px) {
width: 25% !important;
}
}

.item-w50-mobile {
@media only screen and (max-width: 1024px) {
width: 50% !important;
text-align: left !important;
}
}

.item-w33-mobile {
@media only screen and (max-width: 1024px) {
width: 33.33% !important;
text-align: left !important;
}

/*@include respond-to("x-small") {
width: 50% !important;
text-align: left;
}

@include respond-to("small") {
width: 50% !important;
text-align: left;
}

@include respond-to("medium") {
width: unset;
text-align: unset;
}

@include respond-to("large") {
width: unset;
text-align: unset;
}

@include respond-to("x-large") {
width: unset;
text-align: unset;
}*/
}

.item-w75-mobile{
@media only screen and (max-width: 1024px) {
width: 75% !important;
}
}

.item-w100-mobile{
@media only screen and (max-width: 1024px) {
width: 100% !important;
}
/*@include respond-to("x-small") {
     width: 100% !important;
     text-align: left;
}

@include respond-to("small") {
    width: 100% !important;
     text-align: left;
}

@include respond-to("medium") {
     width: unset;
     text-align: unset;
}

@include respond-to("large") {
     width: unset;
     text-align: unset;
}

@include respond-to("x-large") {
      width: unset;
     text-align: unset;
}*/
}
.mcc-wrap-items{
               @media only screen and (max-width:1024px) {
                                flex-wrap:wrap;
                            }

                }


.mat-dialog-container{
padding:0px !important;

.mat-dialog-content{
padding:0;
margin:24px;

 @media only screen and (max-width: 768px) {
            margin:0;
    }
}
}

.mat-horizontal-stepper-header{
position: relative;
z-index: 998;
}

.edit-booking-modal{
.mmc-modal-header{
.material-icons {
   color: #bebebe !important;
}
}
.mmc-modal-body{
padding:16px 32px 32px 32px !important;
}
}

/*.add-extras-modal{
.mmc-modal-header{

}
.mmc-modal-body{
padding:32px !important;
}
}*/


.cancel-booking-modal{
.mmc-modal-header{

}
.mmc-modal-body{
padding:50px 32px 0 32px !important;
height:auto;
overflow:hidden;

@media screen and (max-width:768px) {
    padding: 32px !important;
}

.inner{
    margin:20px 0;
}

.btn-wrapper{
    height:100%;
    align-items:flex-end;
display:flex;
justify-content:flex-end;
/*padding:32px;*/

>button{
    margin:0 16px;
}
@media screen and (max-width:768px) {
    padding-bottom: 12px !important;
}
}
}
.mmc-btn-secondary--small{
    background-color: #044fa2;
    border:1px solid #044fa2 !important;

    &:hover{
        background-color: #fff;
        color:#044fa2 !important;
        border:1px solid #044fa2 !important;
    }
}

.session-timerout-title{
    justify-content: flex-start !important;
    font-size: 20px;
    font-weight: bold;
}
.session-timerout-title-no{
display: none !important;
}
.title-close-wrapper{
    display: flex;
    justify-content: space-between;
    background: #00507c;
    height: 45px;
    color: white;
    font-weight: bolder;
    font-size: 20px;
    padding: 0px 20px;
}

}


.booking-list {
    .mmc-btn-secondary--small.main{

      @include respond-to("x-small") {
        margin: unset !important;
      }
      @include respond-to("small") {
        margin: unset !important;
      }
      @include respond-to("medium") {
        margin: 0px 5px !important;
      }
      @include respond-to("large") {
        margin: 0px 5px !important;
      }
      @include respond-to("x-large") {
        margin: 0px 5px !important;
      }

    }
}

.icon-replace{
width:20px;
height:40px;
}

.mat-expansion-panel-header{
border-bottom: 1px solid $pw-border-color;
}


.fd-col{
@media only screen and (max-width: 1024px) {
flex-direction:column;
}
}

.pr5{
padding-right:5px;
}


.clear--pm{
padding:0 !important;
margin:0 !important;
}

.d-none{
display:none;
}


// .btn-label{
// @media only screen and (max-width: 768px) {
//         display:none;
//     }
// }

/*.user__home{
@media only screen and (max-width: 1024px) {
        display:none;
    }
}*/

.wfixed{
width:20px;
}

.multiplication{
margin-left:3px !important;
margin-right:3px !important;
}


.edit-row{
.item-secondary{
.mat-input-element{
        /*font-family: "Montserrat", sans-serif;
font-size: 0.75rem;
font-weight: 600;

letter-spacing: 0.05em;
word-spacing: 0.2rem;
-webkit-font-smoothing: antialiased;*/
}

 .mat-form-field-label{
     top:22px;
        font-family: "Montserrat", sans-serif;

font-size: 14px;
font-weight: 600;
/* line-height: 0.75rem; */
letter-spacing: 0.05em;
word-spacing: 0.2rem;
-webkit-font-smoothing: antialiased;
}
}
}




//booking cart

.booking-cart {
    .alert.success--btn {
  position: relative;
  background: #222;
  margin: 10px 0 30px 0;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 17px;
  font-size: 12px;
      color: #856404;
    background: #fff3cd;
    border: 1px solid #ffeeba;
  /*font-family: Raleway;*/
  font-weight: 600;
  letter-spacing: 0.05rem;
  border-radius: 0;
  -webkit-border-radius: 0;

  >span{
      color:#ff5722;
  }
}

.alert.success:before {
  content: '\f00c';
  font-family: "FontAwesome";
  font-size: 15px;
  padding-right: 5px;
  color: #436332;
}
}

@media screen and (max-width:1024px) {
    .past-booking .booking-list .mat-expansion-panel-header .item-btn-wrapper{
        margin-bottom: 10px;
    }
}
.bond-item-card{
       flex-direction: column;
}

.bond-item-card-panel{
       margin-bottom: 5px !important;
}


.mcc-expansion-panel-inner-inner {
    .body-row-height {
        height: 100% !important;
        padding: 5px 24px 5px 24px !important;

    }
.privacy-condition{
    p{
        padding-bottom: 20px;
    }
}

.payment-summery--card {
    .booking-payment-card-one {
        width: 40% !important;
    }

    .booking-payment-card {
        flex: 0 0 30% !important;
    }
}
button.mat-button:focus{
    border: 1px solid #000 !important;
}
}

.search-filter-btn{
    width: 36px !important;
    height: 36px !important;
    margin-right: 10px;

    button{
        border: unset !important;
        display: flex;
        align-items: center;
        justify-content: center;
        background: unset !important;
        cursor: pointer;
    }
}


.filter--menu{
    .venue-event-wrapper{
        display: flex;

        .venue-select-dropdown {
            margin-top: 20px;
        }
    }

    .start-end-date-wrapper{
        display: flex;
    }

    .start-date{
        padding-right: 1rem;
    }

    .date-clear-wrapper,.stat{
        color: black !important;
        font-weight: 700 !important;
        padding: unset !important;
    }

    .stat {
        padding: unset !important;
    }

    .clear-all-btn{
        position: relative;
        top: -9px;
        right: 45px;
        display: flex;
        width: 10%;

        .mat-icon-button{
            width:100% !important;
        }

    }


    .date-clear-wrapper{
        display: flex !important;
        flex-direction: row !important;
        .event-date{
            font-weight: 700 !important;
        }
    }

    @media screen and (max-width:768px) {
        .venue-event-wrapper {
            display: flex;
            flex-direction: column;

            .wrap.mmc_flex__item.pr-5{
                padding: unset !important;
            }
        }
        .start-end-date-wrapper {
            display: flex;
            flex-direction: column;
        }
        .start-date {
            padding-right: 0rem !important;
        }

        // .clear-all-btn{
        //     left: -65px !important;
        // }
        .date-clear-wrapper {
            flex-direction: column !important;
        }
        .clear-all-btn {
            left: unset !important;
            right: unset !important;
        }
    }
    .filter-btn-apply{
        padding-left: unset !important;
        padding-right: unset !important;
    }
}

.txt-extra{
    margin-left: 5px;
}

button.mat-button:focus{
    border: 1px solid #000 !important;
}

.mobileItemsCenter{
  @media (max-width: 767.98px) {
    align-self: center !important;
    text-align: left !important;
  }

}

.mobileGap{
  @media (max-width: 767.98px) {
    gap: 0.5rem;
  }
}

@media (max-width: 991.98px){
  .flexMobile{
      align-items: center;
      justify-content: unset;
  }
}

@media (max-width: 767.98px){
  .flexMobile{
      align-items: center;
      justify-content: space-between;
  }
}

@media (max-width: 767.98px){
.mobileItemsAlignCenter{
  align-items: center;
}
}

.past-booking-details .past-booking-details--card-secondary{
    display: block !important;
}

@media screen and (max-width: 577px){
    .facility-expansion-header--container .mmc_flex__item .mcc-wrap-items .mmc_flex__item .mat-icon.mat-icon.notranslate.material-icons.mat-icon-no-color {
      margin-left: 200px;
      margin-top: -80px;
    }
}
.mmc_flex__item.mmc_flex__item--vcenter.ng-star-inserted i.material-icons.icon.f-size-md.curser {
    margin-left: -20px;
}
.spw-txt-s3 {
    font-size: 0.75rem;
}

@media screen and (min-width: 820px) and (max-width: 820px) {
    button.mat-focus-indicator.btn__card.secondary.icon-button.mmc-btn-ternary--small.mat-button.mat-button-base {
        width: auto;
    }
}

.facility-expansion-header--container {
    .mmc_flex__item {
      .mcc-wrap-items {
        @media screen and (max-width: 577px) {
          display: flex;
          flex-direction: column !important;
        }

        .mmc_flex__item {
          @media screen and (max-width: 577px) {
            display: flex;
            flex-direction: row !important;
            width: 100% !important;
            justify-content: space-between !important;
          }
        }
      }
    }
  }
