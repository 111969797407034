
.without-tab{
    margin-top:40px;
    @include respond-to("x-small") {
              padding-left: 0.5rem !important;
              padding-right: 0.5rem !important;
            }
            @include respond-to("small") {
              padding-left: 1.5rem;
              padding-right: 1.5rem;
            }
            @include respond-to("medium") {
              padding-left: 1.5rem;
              padding-right: 1.5rem;
            }
            @include respond-to("large") {
              padding-left: 1.5rem;
              padding-right: 1.5rem;
            }
            @include respond-to("x-large") {
              padding-left: 1.5rem;
              padding-right: 1.5rem;
            }
}
.uplod-cl-image{
    width: 50%;
    height: 116px;
    background-color: #fff;
    border-left: 1px solid #eee;
    &.msg{
        display: flex;
        justify-content: center;
        align-self: center;
        align-items: center;
    }
    .not-found{
        @include font-size(12);
        font-weight: 600;
        color: #ce6b6b;
    }
    .form-control{
    border: none;
    margin: 2px;

    }

    img {
    height: 113px;
    width: auto;
    margin-top: 0px;
    margin-left: auto !important;
    //margin-right: auto;

    border: none;
    outline: none;
}

}
.file-upload{
    cursor: pointer;
    position: relative;
	top: -80px;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
    padding: 0;
    //height: 120px;
}
.upload-cage {
   
    height: 80px;
    border: 1px dashed #999;
    border-radius: 3px;
    text-align: center;

}

.upload-img{
    width:30%;
    border: 1px dashed #999;

   
}

.dropzone{
    text-align: center;
    background-color: #fff;
    padding: 15px;
        height: 116px;
        cursor:pointer;
        
        @include respond-to("x-small") {
              width: 90%;
            }
            @include respond-to("small") {
              width: 70%;
            }
            @include respond-to("medium") {
              width: 70%;
            }
            @include respond-to("large") {
              width: 70%;
            }
            @include respond-to("x-large") {
              width: 70%;
            }
        &:hover{
            background-color:#2196F3;
            color:#fff;

            .content{
                  color:#fff;
            }
        }
        input, select, textarea {
                max-width: none!important;
            }

        .img-upload i{
            @include respond-to("x-small") {
              line-height:20px;
            }
            @include respond-to("small") {
              line-height:56px;
            }
            @include respond-to("medium") {
              line-height:56px;
            }
            @include respond-to("large") {
              line-height:56px;
            }
            @include respond-to("x-large") {
              line-height:56px;
            }
        }

    i{
 //line-height: 56px;
    font-size: 50px;
    }

    .content{
            line-height: initial;

            
    
    @include respond-to("x-small") {
              font-size: 13px;
            }
            @include respond-to("small") {
              font-size: 14px;
            }
            @include respond-to("medium") {
              font-size: 14px;
            }
            @include respond-to("large") {
              font-size: 14px;
            }
            @include respond-to("x-large") {
              font-size: 14px;
            }
    color: #454545;
    }
}
.img-upload{

    width:100%;

    .main-wrap{
        width:100%;
    }
}

.individual-additinal1{
    position: relative;
    .address-loader{
        position:absolute;
        display: flex;
        flex: 1 1 0;
        flex-grow: 0;
        right: 27px;
        top: 10px;
        opacity: 0.5;
        justify-content: flex-end;
    
    }
    .qas-loader{
        display: flex;
        justify-content: center;
        opacity: 0.5;
    }
    .document-upload{
        background-color: #ffffff !important;
        .drop-zone{
            height: 135px !important;
        }
    }
}

.mmc_corporate{
    .align-details{
        align-self: flex-end;
    }
    &.client_wrapper{
        margin-bottom: 40px !important;

         .mat-form-field-label{
               line-height: 1;
             }
         .img-upload i{
            line-height:56px;
        }

        @media screen and (max-width: 375px) {
          .contact-detail {
            flex-direction: column !important;
                .drop-area {
                  flex: auto !important;
                }
                .add-more {
                  flex: 0 1 85px !important;
                }
            }
        }
    }


    

} 

.mmc_corporate{
/*  .mat-form-field-label{
      line-height: 1;
  }
  .img-upload i{

     line-height:56px
}

*/

  .img-col{
        padding-bottom: 1.34375em;
        margin: .25em 0;
        .img-upload{
            top: 0;
            margin:0;
        }
  }
  .img-row{
      padding-bottom: 1.34375em;
        margin: .25em 0;



  }
  .mat-form-field-disabled {
    .mat-form-field-infix{
       .mat-input-element {
            color: #454545;
        }
       .mat-form-field-label{
           color: rgba(0,0,0,0.65);
        }
    }
  }
   // &.individual_customer {

        .img-upload{
            //line-height: 56px;
        

            &:nth-child(1){
                /*margin: 0.5px 0;*/
                //padding-bottom: 1.34375em;
            }
            &.client{
                border: 2px dashed #b5b5b5;
                border-radius: 4px;
                //height: 100%;
              //  margin-bottom: 30px;
            }


            input{
                border:none;
                background-color:transparent;
            }
       // }

        .remove_left_padding {
    
             @include respond-to("x-small") {
          padding-left:0;
        }
        @include respond-to("small") {
          padding-left:0;
        }
        @include respond-to("medium") {
          padding-left:0;
        }
        @include respond-to("large") {
          padding-left:10px;
        }
        @include respond-to("x-large") {
          padding-left:10px;
        }
}
    }

    .full-width{
        width: 100%;
        padding: 0 15px;
    }

    .mmc_flex__item{
        .mat-form-field-infix{
            //max-width: 100px !important;
            width: auto !important;
        }
    }

    &.client_wrapper{
        background-color: #eee !important;
        border-radius: 3px;
        box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.65);
        margin: 5px;

        @include respond-to("x-small") {
          padding:30px 10px !important;
        }
        @include respond-to("small") {
          padding: 30px 10px !important;
        }
        @include respond-to("medium") {
          padding: 50px !important;
        }
        @include respond-to("large") {
          padding: 50px !important;
        }
        @include respond-to("x-large") {
          padding: 50px !important;
        }
    }
    .mat-form-field-outline{
        //min-width:0;
        background-color:#fff;
        border-radius:5px;
       
    }
    .btn-content{
        justify-content:flex-end;

        button{
            //margin-left:20px;
        }

        .btn__card{
                
                @include respond-to("x-small") {
                  padding: 0 40px;
                }
                @include respond-to("small") {
                  padding: 0 40px;
                }
                @include respond-to("medium") {
                  padding: 0 60px;
                }
                @include respond-to("large") {
                  padding: 0 60px;
                }
                @include respond-to("x-large") {
                  padding: 0 60px;
                }
        }
        a{
            padding-right: 15px;
            cursor: pointer;
        }
        .mmc_flex__item{
            &:last-child{
                padding-left:20px;
            }
        }
    }
    
    section{
        padding-bottom:30px;
    }
    .remove_left_padding{       
        @include respond-to("x-small") {
          padding-left:15px;
        }
        @include respond-to("small") {
          padding-left:0;
        }
        @include respond-to("medium") {
          padding-left:0;
        }
        @include respond-to("large") {
          padding-left:0;
        }
        @include respond-to("x-large") {
          padding-left:0;
        }
    }
    .remove_right_padding{
        @include respond-to("x-small") {
          padding-right:15px !important;
        }
        @include respond-to("small") {
         padding-right:15px !important;
        }
        @include respond-to("medium") {
          padding-right:15px !important;
        }
        @include respond-to("large") {
          padding-right:0;
        }
        @include respond-to("x-large") {
          padding-right:0;
        }
    }
    .img-upload{
        height:58px;
        border: 1px dashed $grey-dark-color;
        //padding:20px;
        //background-clip:border-box;
        border: 1px dashed $grey-dark-color;
        border-radius: 3px;
        //line-height: 56px;
        position: relative;
        top: 6.5px;
        display: flex;
        justify-content: space-around;

         input{
                border:none;
                background-color:transparent;
            }


        .mmc_flex__item {
            margin:5px;
        }
    }
    .mat-form-field {
      width: 100%;
    }
    .mat-form-field-flex{
      height: 65px !important;
    }
    .title {
    @include font-size(20);
    margin-bottom: 50px;
    padding-bottom: 1px;
    position: relative;
    color: $opt-link-color__primary;
    &:after {
      content: "";
      width: 100%;
      height: 2px;
      border-bottom: 1px solid $icon_darker;
      position: absolute;
      left: 0;
      top: 50%;
      z-index: 1;
    }

    .name {
      &:before {
        content: "";
        width: 100%;
        height: 2px;
        border-bottom: 1px solid $icon_darker;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 1;
      }

      span {
        color: #ed3e84;
      }
    }
  }
  .name__inner {
    background-color: #eee;
    position: absolute;
    left: 0;
    z-index: 100;
    position: -webkit-sticky;
    margin-top: -15px;
    &.desktop{
         @include respond-to("x-small") {
          display: none;
        }
        @include respond-to("small") {
         display: block;
        }
        @include respond-to("medium") {
          display: block;
        }
        @include respond-to("large") {
          display: block;
        }
        @include respond-to("x-large") {
          display: block;
        }
    }
    &.mobile{
        @include respond-to("x-small") {
          display: block;
        }
        @include respond-to("small") {
         display: none;
        }
        @include respond-to("medium") {
          display: none;
        }
        @include respond-to("large") {
          display: none;
        }
        @include respond-to("x-large") {
          display: none;
        }
    }
  }
}

/*recaptcha*/
.recaptcha {
  margin-bottom: 20px;
  justify-content: center;
  @include respond-to("x-small") {
          margin-left: -8px;
        }
        @include respond-to("small") {
         margin-left:0;
        }
        @include respond-to("medium") {
          margin-left:0;
        }
        @include respond-to("large") {
          margin-left:0;
        }
        @include respond-to("x-large") {
          margin-left:0;
        }
}
.contact-detail{
    .drop-area{
        flex: 0 1 180px;
    }
    .add-more{        
        flex: 0 1 47px;
        
        .img-upload{
            width: 58px;
            //min-width: 12px;
            margin-left: 12px;
            &:hover{
                background-color: #b5b5b5;
                i{
                  color: black;
                }
            }
            i{
                width: -moz-max-content;
                  width: -webkit-max-content;
                  width: -o-max-content;
            }
        }
        .mat-button{
            min-width: unset;
        }
    }
    .mat-form-field-infix{
        width: 168px;
    }
}
.privacy-policy{


    .mat-checkbox-label{
          white-space: -moz-pre-wrap !important; 
    white-space: -pre-wrap;     
    white-space: -o-pre-wrap;   
    white-space: pre-wrap;      
    word-wrap: break-word;     
    white-space: -webkit-pre-wrap; 
    //word-break: break-all;
    white-space: normal;

    }

    .mat-checkbox-txt{
      display: block;
      margin-top: -23px;
      margin-left: 25px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      white-space: -moz-pre-wrap !important; 
      white-space: -pre-wrap;     
      white-space: -o-pre-wrap;   
      white-space: pre-wrap;      
      word-wrap: break-word;     
      white-space: -webkit-pre-wrap;
      white-space: normal;
    }

    color: black;
    margin-bottom: 20px;
    a{
        color: #0275d8;
        text-decoration: none;
        padding: 0 5px;
        cursor: pointer;
    }
}
.mat-option {
    &.mat-active{
        background: rgba(0,0,0,.30) !important;
    }
    &:focus, &:hover{
        &:not(.mat-option-disabled){
            background: rgba(0,0,0,0.09) !important;
        }
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   #imageInput {
        margin-right: 30px;
   }
   .add-more{
       flex:0 1 69px;
       
       .img-upload{
           padding-left: 0;
           padding-right: 0;
           min-width: 45px;
       }
   }
   .address-lookup{
        .mat-form-field-outline-thick{
            .mat-form-field-outline-start{
                width:42px !important;
            }
        }

        
           
        
        
    }

    
   
}
.mat-form-field-disabled{
    .mat-form-field-outline{
        background: #e0e0e0;
    }
}

.effect-show {
    .individual-additinal1{
        .mat-form-field-outline {
            background: #fff !important;
             animation: none !important;
        }
    }
    
    .mat-form-field-outline {
         background: #fff;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            background: #fff9c4;
        }

        -webkit-animation: fadeinout 1s linear forwards;
        animation: fadeinout 1s linear forwards;
        -ms-animation: fadeinout 1s linear forwards;
        -o-animation: fadeinout 1s linear forwards;


    }
    @supports (-ms-ime-align:auto) {
        .mat-form-field-outline {
            background-color: #fff9c4;
        }
    }

    @-webkit-keyframes fadeinout {
      0% { opacity: 1; }
      50% { opacity: 1; background: #fff9c485; }
      100% { opacity: 1; background: #fff9c485; }
    }

    @keyframes fadeinout {
      0% { opacity: 1; }
      50% { opacity: 1; background: #fff9c485; } 
      100% { opacity: 1; background: #fff9c485; }
    }
    @-o-keyframes fadeinout {
      0% { opacity: 1; }
      50% { opacity: 1; background: #fff9c485; } 
      100% { opacity: 1; background: #fff9c485; }
    }
    @-ms-keyframes fadeinout {
      0% { opacity: 1; }
      50% { opacity: 1; background: #fff9c4; } 
      100% { opacity: 1; background: #fff9c4; }
    }
    @-o-keyframes fadeinout {
      0% { opacity: 1; }
      50% { opacity: 1; background: #fff9c485; } 
      100% { opacity: 1; background: #fff9c485; }
    }
}
/*.effect-hide{
    .mat-form-field-outline {
            background: #fff;
        }
}*/

/*My profile*/
.my-account{
    margin-top: 30px;
    .title{
        padding: 15px;
        @include font-size(24);
    }
}

/*My profile*/
.change-pw{
    margin-top: 30px;
    .title{
        padding: 15px;
        @include font-size(24);
        color:$icon_darker;
        &--txt-colr{
            color:$opt_color_primary;
        }
        .name{
            .title--sub{
                @include font-size(20) ;
                color: $opt-text-color__primary;
            }
        }
        
    }
    .btn-content{
        &:not(:first-child){
            .mmc_flex__item--vcenter {
                margin-left: 20px;
            }
        }
    }

}

.address-loader{
            position:relative;
            top:7px;

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            position:relative;
           top:-17px;

}
        }

/*@media screen and (min-width:0\0) and (min-resolution: +72dpi) {
    
    .ie9up { 
        position:relative;
            top:-10px;
    }
}

@media screen and (min-width:0\0) {
    .ie9up { 
        position:relative;
           top:-10px;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     .ie9up { 
        position:relative;
            top:-10px;
    }
}

@media all and (-ms-high-contrast:none)
     {
     .ie9up { background-color: green } 
     *::-ms-backdrop, .ie9up { background-color: red } 
     }*/

  .mat-checkbox-layout {
      white-space: normal !important;
  }
  .mat-checkbox-inner-container {
    margin: 5px !important;
}