$fontpath: "/src/assets/fonts/";

/*@font-face {
    font-family: 'icomoon thakshila';   
   
    src:  url('/dist/assets/fonts/icomoon.eot?1irofb');
  src:  url('/dist/assets/fonts/icomoon.eot?1irofb#iefix') format('embedded-opentype'),
  url('/dist/assets/fonts/icomoon.ttf?1irofb') format('truetype'),
  url('/dist/assets/fonts/icomoon.woff?1irofb') format('woff'),
  url('/dist/assets/fonts/icomoon.svg?1irofb#icomoon') format('svg');
 
    /*src:url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/icomoon.eot?8pq1w');
	src:url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/icomoon.eot?#iefix8pq1w') format('embedded-opentype'),
		url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/icomoon.woff?8pq1w') format('woff'),
		url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/icomoon.ttf?8pq1w') format('truetype'),
		url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/icomoon.svg?8pq1w#icomoon') format('svg');

    font-weight: normal;
    font-style: normal;
  }*/
  
/*@font-face {
  font-family: 'icomoon';
  src:  url('/dist/assets/fonts/icomoon.eot?22777n');
  src:  local("?"),url('/dist/assets/fonts/icomoon.eot?22777n#iefix') format('embedded-opentype'),
    url('/dist/assets/fonts/icomoon.ttf?22777n') format('truetype'),
    url('/dist/assets/fonts/icomoon.woff?22777n') format('woff'),
    url('/dist/assets/fonts/icomoon.svg?22777n#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}*/

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  /*font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;*/

  /* Better Font Rendering =========== */
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    // Better Font Rendering =========== 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

    [class^="icon-p2-"], [class*=" icon-p2-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon-p2' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    // Better Font Rendering =========== 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }



  .icon-mug:before {
	content: "\e602";
}
  .icon-stats-bars:before {
  content: "\e99c";
}
  .icon-breakfast-time:before {
    content: "\e900";
  }
  .icon-calendar:before {
    content: "\e901";
  }
  .icon-clock-circular-outline:before {
    content: "\e902";
  }
  .icon-coffee-cup:before {
    content: "\e903";
  }
  .icon-document:before {
    content: "\e904";
  }
  .icon-down-arrow-1:before {
    content: "\e905";
  }
  .icon-down-arrow:before {
    content: "\e906";
  }
  .icon-download:before {
    content: "\e907";
  }
  .icon-drop-up-arrow:before {
    content: "\e908";
  }
  .icon-edit:before {
    content: "\e909";
  }
  .icon-email:before {
    content: "\e90a";
  }
  .icon-error:before {
    content: "\e90b";
  }
  .icon-facebook-letter-logo:before {
    content: "\e90c";
  }
  .icon-fashion:before {
    content: "\e90d";
  }
  .icon-file:before {
    content: "\e90e";
  }
  .icon-filter-filled-tool-symbol:before {
    content: "\e90f";
  }
  .icon-housekeeper:before {
    content: "\e910";
  }
  .icon-icons8-microsoft-word-2:before {
    content: "\e911";
  }
  .icon-instagram:before {
    content: "\e912";
  }
  .icon-layout:before {
    content: "\e913";
  }
  .icon-left-arrow-1:before {
    content: "\e914";
  }
  .icon-left-arrow:before {
    content: "\e915";
  }
  .icon-lock:before {
    content: "\e916";
  }
  .icon-menu-button:before {
    content: "\e917";
  }
  .icon-parking-3:before {
    content: "\e918";
  }
  .icon-parking-4:before {
    content: "\e919";
  }
  .icon-pdf:before {
    content: "\e91a";
  }
  .icon-photo:before {
    content: "\e91b";
  }
  .icon-placeholder:before {
    content: "\e91c";
  }
  .icon-refrigerator:before {
    content: "\e91d";
  }
  .icon-right-arrow:before {
    content: "\e91e";
  }
  .icon-shopping-cart:before {
    content: "\e91f";
  }
  .icon-soap:before {
    content: "\e920";
  }
  .icon-sort-1:before {
    content: "\e921";
  }
  .icon-sort:before {
    content: "\e922";
  }
  .icon-sort-arrows-couple-pointing-up-and-down:before {
    content: "\e923";
  }
  .icon-swimming-silhouette:before {
    content: "\e924";
  }
  .icon-television:before {
    content: "\e925";
  }
  .icon-trash:before {
    content: "\e926";
  }
  .icon-tv:before {
    content: "\e927";
  }
  .icon-twitter:before {
    content: "\e928";
  }
  .icon-user:before {
    content: "\e929";
  }
  .icon-users:before {
    content: "\e92a";
  }
  .icon-user-silhouette:before {
    content: "\e92b";
  }
  .icon-verified:before {
    content: "\e92c";
  }
  .icon-wifi-connection-signal-symbol:before {
    content: "\e92d";
  }
  .icon-icn-package-1:before {
  content: "\e92e";  }    .icon-p2-balance:before {
	content: "\e900";
}    .icon-p2-bill:before {
  content: "\e901";
}    .icon-p2-card:before {
  content: "\e902";
}
.icon-p2-checked:before {
  content: "\e903";
}
.icon-p2-delete:before {
  content: "\e904";
}
.icon-p2-file-sharing:before {
  content: "\e905";
}
.icon-p2-funnel:before {
  content: "\e906";
}
.icon-p2-invoice:before {
  content: "\e907";
}
.icon-p2-questions:before {
  content: "\e908";
}
.icon-p2-star:before {
  content: "\e909";
}.icon-p2-star-circle:before {
  content: "\e90a";
}.icon-p2-update:before {
  content: "\e90b";
}.icon-p2-file-1:before {
  content: "\e90c";
}

.icon-p2-not-found:before {
  content: "\e90e";
}

.icon-p2-warning:before {
  content: "\e90f";
}

.icon-p2-danger:before {
  content: "\e910";
}