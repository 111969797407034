@import "../helpers/_flexbox.scss";
@import '../helpers/_mixins.scss';
@import '..//themes/default/_variables-new.scss';

.mat-menu-content{
i{
    margin-right:20px;
    color: #7b807d;
}
}


.search--result {
    .mobile-card { 
        @include respond-to("x-small") {
            flex-direction: column;
        }

        @include respond-to("small") {
            flex-direction: column;
        }

        @include respond-to("medium") {
            flex-direction: row;
        }

        @include respond-to("large") {
            flex-direction: row;
        }

        @include respond-to("x-large") {
            flex-direction: row;
        }
    }

    .item_name {
        @include font-size(18);
    }

    .icn-aminity {
        margin-right: 15px;
        font-size: 18px;
        color: #ed3e84;
    }

    .location_icon {
        i {
            font-size: 16px;
        }
    }

    .item_details {
        > div {
            padding-top: 10px;
            //@include font-size_defalt(16);
            display: -webkit-box;
            /*line-height: 20px;*/
            padding-bottom: 5px;
            height: auto;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            /*-webkit-line-clamp: 2;*/ /* number of lines to show */
            text-align: justify !important;
            margin-bottom: 0;
            /*color: #737373;*/
        }

        label {
            &:hover {
                cursor: pointer;
            }
        }
        /*@include respond-to("x-small") {
      display: none;
    }

    @include respond-to("small") {
      display: none;
    }

    @include respond-to("medium") {
      display: block;
    }
    @include respond-to("large") {
      display: block;
    }*/
    }
    /*.item_details_expanded {
      p{
        padding-top: 10px;
        //@include font-size_defalt(16);
        display: -webkit-box;
        line-height: 20px;
        padding-bottom: 5px;
        height: auto; 
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align:justify !important;
        margin-bottom: 0;
            color: #737373;
      }
  }*/
    .item_address {
        padding-top: 5px;
    }

    .image-cage {
        width: 225px;
        height: 180px;
        overflow: hidden;
        text-align: center;
        border: 2px solid $pw-border-color__quinary;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;

        @include respond-to("x-small") {
            min-width: 100%;
            width:auto;
        }

        @include respond-to("small") {
            min-width: 100%;
            width:auto;
            
        }

        @include respond-to("medium") {
            min-width: 200px;
            max-width: 200px;
        }

        @include respond-to("large") {
            min-width: 225px;
            max-width: 225px;
        }

        &:hover {
            border: 2px solid $pw-border-color__secondary;
        }

        .img {
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            background-position: center center;
            overflow: hidden;
            // width: 100%;
            height: 100%;
            cursor: pointer;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease;
            width: 100%;
            object-fit: cover;

            &:hover {
                -webkit-transform: scale(1.2);
                -moz-transform: scale(1.2);
                -o-transform: scale(1.2);
                -ms-transform: scale(1.2); /* IE 9 */
                transform: scale(1.2);
            }
        }

        .overlay {
            background-color: #454545;
            height: 100%;
        }
    }

    .content {
        width: 100%;
        padding: 15px 15px 0px 15px;

        h1 {
            a {
                text-decoration: underline;
                color: #000;
                font-weight: 600;
                text-transform: uppercase;

                &:hover {
                    color: #006ff8;
                }
            }
        }
    }

    .butn__cage {
        width: 265px;
        height: auto;
        /*display: flex;
    justify-content: center;*/
        margin-top: 20px;
        flex-direction: column;

        @include respond-to("x-small") {
            width: 100%;
        }

        @include respond-to("small") {
            width: 100%;
        }

        @include respond-to("medium") {
            width: 320px;
        }

        @include respond-to("large") {
            width: 320px;
        }

        .discount_cage {
            min-height: 55px;

            @include flexbox();
            @include justify-content(flex-end);

            .btn__card {
                text-transform: uppercase;
                line-height: 45px;
                height: 50px;
            }
        }

        .price {
            text-align: right;

            @include respond-to("x-small") {
                text-align: center;
                padding-top: 10px;
                padding-bottom: 10px;
                margin-bottom: 17px;
            }

            @include respond-to("small") {
                text-align: right;
                padding-top: 0px;
                padding-bottom: 0px;
                margin-bottom: 17px;
            }

            @include respond-to("medium") {
                text-align: right;
                padding-top: 0px;
                padding-bottom: 0px;
                margin-bottom: 17px;
            }

            @include respond-to("large") {
                text-align: right;
                padding-top: 0px;
                padding-bottom: 0px;
                margin-bottom: 17px;
            }

            .currency__type {
                color: #ccc;
                font-weight: 700;
                @include font-size_defalt(18);
            }

            .total__amount {
                /*@include font-size_defalt(20);*/
                /*font-weight: bold;*/
                /*line-height: auto;*/
                margin-bottom: 10px;
            }
        }

        .lbl_discount {
            @include font-size_defalt(35);
            padding: 5px 10px;
            background-color: #ed3e84;
            color: #fff;
            text-align: right;
            font-size: 14px;
            position: relative;
            height: 40px;
            width: 100px;

            &::before {
                content: "";
                width: 0;
                height: 0;
                border-top: 20px solid transparent;
                border-left: 20px solid #ffffff;
                border-bottom: 20px solid transparent;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    .aminity-icons--cage {
        position: relative;
        margin-top: 5px;

        @media (max-width: 576px) {
            &.venue-card{
                
                height: 120px;

                .avblt-btn{
                    position: absolute;
                    top: 50px;
                }
            }
        }

        .aminity-icons {
            &::before {
                content: "";
                width: 100%;
                height: 2px;
                border-bottom: 1px solid #e3e3e3;
                position: absolute;
                left: 0;
                top: 50%;
                z-index: 1;
                @media (max-width: 576px) {
                    content: none !important;  
                }
            }
        }
        .mmc_flex__item--inherit {
            @media screen and (max-width:768px) {
                display: flex;
                align-items: center; 
        }
        }
    }

    .icon-list {
        background-color: #fff;
        position: absolute;
        left: 0;
        z-index: 1;
        position: -webkit-sticky;
        top: 13px;
        height: 26px;

        img {
            height: 22px;
            width: 22px;
            margin-right: 15px;
        }

        @media screen and (max-width:768px) {
            height: unset !important;
            position: relative !important;
            top: 0 !important;
        }
    }

    .available-cage {
        background-color: $pw-bg-color;
        min-width: 150px;
        padding-left: 5px;
        z-index: 1 !important;
        position: -webkit-sticky;
        position: sticky;
        /*background-color: #fff;*/
        border: 1px solid #fff;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        position: relative;
        -webkit-box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.75);
        box-shadow: 0px 1px 1px 1px rgba(202, 193, 193, 0.75);


        &:hover {
            // background-color: #3fba74 !important;
            //color: #3fba74 !important;
            background-color: $pw_btn-bg-color__quaternary !important;
            border: 1px solid #eee;

            .link {
                //color: #ffffff !important;
                .icn-aminity, .pax {
                    //  color: #ffffff !important;
                }

                .pax {
                    color: #ffffff !important;
                }
            }
        }

        .pax {
            color: #ed3e84;
            @include font-size(12);
            white-space: nowrap;
        }

        &.fully-available {
            background-color: #fff;
            // padding-left: 2px;
            z-index: 100;
            position: sticky;

            .link {
                color: #3fba74;
                width: 89px;
            }
        }

        &.partially-available {
            background-color: #fff;
            // padding-left: 2px;
            z-index: 100;
            position: sticky;

            .link {
                color: #3b6ee4;
                max-width: 100px;
            }
        }
        //  width: 130px;
        .icn {
            height: 20px;
            display: flex;

            i {
                font-size: 22px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .pax {
            font-size: 14px;
            text-align: center;
            font-weight: 700;
        }

        .link {
            font-size: 12px;
            padding-left: 10px;
            font-weight: 600;
        }
    }

    .alternative-list {
        .header-container{
            width:100%;
        }
        .mmc_expansion__body--row-header {
            position:relative;
            height:auto;
            /*.btm-line:after {
                content: "";
                width: 3px;
                height: 90%;
                border-left: 1px dashed #a5a5a5;
                position: absolute;

            }*/

            .alternative-list-progress{
                padding-right: 24px !important;
                left: -24px !important;
            }

            .alternative-radio-group {
                display: flex;
                flex-direction: column;
                 .f-end{
                        justify-content:flex-end;
                        padding-right:24px;

                         @media only screen and (max-width: 768px) {
                                       justify-content:flex-start;
                                       padding-left:24px;
                                       padding-bottom:16px;
                                    }
                    }

                .alternative-radio {
                    display: flex;
                    height: 55px;
                    align-items: center;  

                    .mat-radio-label{
                        display:flex !important;
                        align-items:center !important;
                        margin-bottom:0 !important;

                        .mat-radio-container{
                            top:0 !important;
                        }

                        .mat-radio-label-content{
                            white-space:normal !important;
                        }
                    }
                }

                .body-container{
                      @media only screen and (max-width: 768px) {
                                       flex-direction:column;
                                    }
                }

                .body-container:nth-child(1) {
                      border-left: 4px solid #00897B;
                        margin-left: -24px;
                        padding-left: 24px;
                        }

                 .body-container:nth-child(2) {
                      border-left: 4px solid #1E88E5;
                        margin-left: -24px;
                        padding-left: 24px;
                        }

                  .body-container:nth-child(3) {
                      border-left: 4px solid #e53935;
                        margin-left: -24px;
                        padding-left: 24px;
                        }

                .body-container:after {
               content: "";
                width: 100%;
                /* height: 90%; */
                border-bottom: 1px solid #e4e4e4;
                position: absolute;
                left: 0px;

            }

                
            }
        }
    }
}


.count-cage{
  /*font-size: 26px;*/
  /*font-weight: 600;*/
  padding: 15px 0;
  /*color: #ed3e84;*/
  margin-bottom: 15px;
  span{
    /*color: #454545;*/
  }
  .sortby{
      display: flex;
      justify-content: flex-end !important;
  }
  .confirmation{
      align-items: flex-start;
      display: flex;
      vertical-align: middle;
      i{
          color: #ed3e84;
          padding-right: 5px;
      }
      .font__nt-available{
          display:flex;
      }
  }
}
agm-map{
    .search--result {
        .mobile-card{
            .image-cage{
                min-width: 200px;
                max-width: 200px;
                width: 35%;
            }
            .content{
                width: 40%;
                padding: 0px 10px 0px 10px;
                .aminity-icons--cage {
                    margin-top: 15px;

                    .mmc_flex__item.aminity-icons {
                        flex: 0 0 80%;
                        .icon-list {
                            height: auto;
                        }
                    }
                }

                
                
            }
            @media only screen and (max-width: 1023px){
                .content{
                    width: 100%;
                    .item_name{
                        font-size: 1rem;
                        letter-spacing: unset !important;
                        h1.font__h6{
                            font-size: 1rem;
                            letter-spacing: unset !important;
                        }
                    }
                    .aminity-icons--cage{
                        flex-direction: column;
                        row-gap: 10px;
                    }
                    
                }
            }
            @media only screen and (max-width: 1023px){
                .butn__cage{
                    width: 100% !important;
                }
                .image-cage{
                    min-width: unset !important;
                    max-width: unset !important;
                    width: 100% !important;
                }
            }

            @media only screen and (max-width: 767.98px){
                .image-cage{
                    min-width: unset !important;
                    max-width: unset !important;
                    width: auto;
                }
            }
            .butn__cage{
                width: 25%;
            }
        }
    }
}

.mobile-calendar-today .mat-calendar-body-cell-content {
    color: rgb(0, 0, 0);
    background-color: rgba(135, 207, 235, 0.132) !important;
  }
  
  .mobile-calendar-selected .mat-calendar-body-cell-content {
    color: #fff;
    background-color: #2E7D32 !important;
  }
  
  .mobile-calendar-unavailble .mat-calendar-body-cell-content {
    background-color:#DC2A2A !important;
    color: #fff;
  }
  
  .mobile-calendar-partiallyavailble .mat-calendar-body-cell-content {
    background-color:#4F1C03 !important;
    color: #fff;
  }
  
  .mobile-calendar-availble .mat-calendar-body-cell-content {
    background-color:#fff !important;
    color: rgb(0, 0, 0);
  }
  
  .mobile-calendar-Selected .mat-calendar-body-cell-content {
    background-color: #FBAB00 !important;
    color: rgb(0, 0, 0);
  }

  .showInMobile{
    .facility-name-items{
        margin-left: 10px;
        margin-bottom: 10px;
    }
  }

.mmc_flex__item--inherit.avblt-btn {
    z-index: 100;
}