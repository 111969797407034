@import "tc-md-variables";
@import "tc-md-mixins";
@import "./../helpers/variables";

$base-text-color: #000;

$color-base: $base-text-color;

$light: lighten($color-base,60%);
$dark: darken($color-base,15%);


$lighter: lighten($color-base,30%);
$darker: darken($color-base,30%);

body{
  color:$base-text-color ;
  //font-family: $font-family-rubik;
  //font-family: 'Roboto', sans-serif;
}

//circle-icon defalt colors
$ci-bg--color :#eee !default;
$ci-col-hover:#eee !default;
$background-item:#fff;

// state text  colors ...........................................

.opt-txt__primary {
  color: $opt_text_color_primary !important;
}
.opt-txt__secondary {
  color: $opt_text_color_tertiary !important;
}
.opt-txt__tertiary {
  color: $opt_text_color_secondary !important;
}
.opt-txt__quaternary {
  color: $opt_text-color_quaternary !important;
}


//State link colors...........................................
.opt-link--primary{
  @include typo-body-1();
  font-style: normal;
  line-height: 1.5;
  text-decoration: underline;
  color: $opt-link-color__primary;
  cursor: pointer;

  &.__bold{
    font-weight: 500;
  }

  &:hover{
    color: darken($opt-link-color__primary,30%);
  }
}



.opt-link--secondary{
  @include typo-body-1();
  font-style: normal;
  line-height: 1.5;
  text-decoration: underline;
  color: $opt-link-color__secondary;

  &.__bold{
    font-weight: 500;
  }

  &:hover{
    color: darken($opt-link-color__secondary,30%);
  }
}


// state background colors ...........................................


.no-color__state_background{
 background-color:$no-color--state  !important;
  i,p,&.chip span,span{
    color:$background-item !important;
  }
}
.active-color--state_background{
  background-color:$active-color--state  !important;
  i,p,&.chip span,span{
    color:$background-item !important;
  }
}
.mute-color--state_background{
  background-color:$mute-color--state  !important;
  i,p,&.chip span,span{
    color:$background-item !important;
  }
}
.firm-color--state_background{
  background-color:$firm-color--state !important;
  i,p,&.chip span,span{
    color:$background-item !important;
  }
}
.alert-color--state_background{
  background-color:$alert-color--state !important;
  i,p,&.chip span,span{
    color:$background-item !important;
  }
}
.definite-color--state_background{
  background-color:$definite-color--state !important;
  i,p,&.chip span,span{
    color:$background-item !important;
  }
}
.quote-color--state_background{
  background-color:$quote-color--state !important;
  i,p,&.chip span,span{
    color:$background-item !important;
  }
}

// state icons and fonts  colors ...........................................

.no-color--state{
  color:$no-color--state !important;
  i,p,&.chip span,span{
    color:$no-color--state !important;
  }
}
.active-color--state{
  color:$active-color--state !important;
  i,p,&.chip span,span{
    color:$active-color--state !important;
  }
}
.mute-color--state{
  color:$mute-color--state !important;
  i,p,&.chip span,span{
    color:$mute-color--state !important;
  }
}
.firm-color--state{
  color:$firm-color--state !important;
  i,p,&.chip span,span{
    color:$firm-color--state !important;
  }
}
.alert-color--state{
  color:$alert-color--state !important;
  i,p,&.chip span,span{
    color:$alert-color--state !important;
  }
}
.definite-color--state{
  color:$definite-color--state !important;
  i,p,&.chip span,span{
    color:$definite-color--state !important;
  }
}

.quote-color--state{
  background-color:$quote-color--state !important;
  i,p,&.chip span,span{
    color:$background-item !important;
  }
}


.package-color--product_background {
  background-color: $package-color--product !important;
  i ,p,&.chip span,span{
    color: $background-item !important;
  }
}

.package-color--product{
  color: $package-color--product;
}
.package-color--item{
  color: $package-color--type;

}
p {
     @include typo-body-1;
     margin-bottom: 16px;
   }


%tc-ellipsis{
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.opt-txt__underline{
  text-decoration: underline;
}
.opt-txt__line-through{
  text-decoration: line-through;
}
.opt-txt__line-overline{
  text-decoration: overline;
  font-size: 12px;
  line-height: 16px;
}

.opt-txt__white-space-normal{
  overflow-wrap: inherit;
  white-space: inherit;
}

.opt-txt{
  &__light {
    color: $light; //black 60%
  }
  &__dark {
    color: $dark; //black
  }
  &__lighter {
    color: $lighter;
  }
  &__darker {
    color: $darker;
  }
  &__align-right {
   text-align: right;
  }

  &__right{
        text-align: right;
  }
  &__left{
    text-align: left;
  }

  &__center{
    text-align: center;
  }


}

fieldset.demo{
  background-color: #fff;
  legend{
    background-color: #eee;
    border: 1px solid #ccc;
    font-size: 16px;
    font-weight: 500;
  }
  margin-bottom: 20px;

}

    h1, h2, h3, h4, h5, h6, p {
      margin: 0;
      padding: 0;
    }

     h1.opt__heading-1 small,   h2.opt__heading-2 small, h3.opt__heading-3 small, h4.opt__heading-4 small,   h5.opt__heading-5 small, h6.opt__heading-6 small {
      @include typo-display-3($colorContrast: false);
      font-size: 0.6em;
    }

  h1.opt__heading-1 {
  @include typo-display-3;

    margin-top: 0;
    margin-bottom: 0;
    @extend %tc-ellipsis;
  }

  h2.opt__heading-2 {
    @include typo-display-2;
    margin-top: 0;
    margin-bottom: 0;
  }

  h3.opt__heading-3 {
    @include typo-display-1;

    margin-top: 0;
    margin-bottom: 0;
  }

  .opt__subtitle-1 {
    @include typo-headline;
    font-size: 14px ;
    line-height: 14px;
    margin-top: 0;
    margin-bottom: 0;


    &-small{
      @include typo-headline;
      font-size: 12px ;
      line-height: 12px;

    }
    &-large{
      @include typo-headline;
      font-size: 16px ;
      line-height: 16px;
    }
  }

  .opt__subtitle-2 {
    @include typo-title;
    font-size: 16px;
    line-height: 16px;


    &-small{
      @include typo-title;
      font-size: 14px ;
      line-height: 14px;
    }

    &-large{
      @include typo-title;
      font-size: 18px ;
      line-height: 18px;
    }
  }

  /*h4.opt__heading-4 {
    @include typo-headline;

    margin-top: 0;
    margin-bottom: 0;
  }

  h5.opt__heading-5 {
    @include typo-title;

    margin-top:0;
    margin-bottom: 0;
  }

  .opt__heading-title {
    @include typo-title;

    margin-top: 0;
    margin-bottom: 0;
  }

  h6.opt__heading-6 {
    @include typo-subhead;

    margin-top: 0;
    margin-bottom: 0;
  }*/

p {
    @include typo-body-1;
    margin-bottom: 16px;
    line-height: 20px;
  
  }

.lead{
  @include typo-title;
  margin-top: 0;
  margin-bottom: 16px;
}
/*
.opt__heading__caption {
      @include typo-caption;
      font-style: normal;
      color: #909cac;
   }*/
.opt-txt__caption{
  @include typo-caption;
  font-style: normal;
  color: #909cac;
}
.opt-txt__link{
  @include typo-caption;
  font-style: normal;
  line-height: 16px;
  color: #27bfc2;
  text-decoration: none;
}


.opt-txt__body2{
  @include typo-body-2;
  @extend %tc-ellipsis;
  line-height: 1.5;

  &-small{
    @include typo-body-2;
    @extend %tc-ellipsis;
    font-size: 12px !important;
  }

  &-large{
    @include typo-body-2;
    @extend %tc-ellipsis;
    font-size: 14px;
  }

}

.opt-txt__body1{
  @include typo-body-1;
  @extend %tc-ellipsis;
  //margin-bottom: 10px;

  &-small{
    font-size: 12px;
   // @include typo-body-1;
    @extend %tc-ellipsis;
  }

  &-large{
    font-size: 14px;
   // @include typo-body-1;
    @extend %tc-ellipsis;
  }


  /* &.__link{
     @include typo-body-1();
     font-style: normal;
     line-height: 1.5;
     text-decoration: underline;

     &.__bold{
       font-weight: 500;
     }

     &:hover{
       color: #0d47a1;
     }

   }*/
}

/*.opt-txt__body1-large{
  @include typo-subhead;
  @extend %tc-ellipsis;
  color: $central-brand-primary;
  &#TRD_PER_INFO_CLI_NAME {
    color: #000 !important;
  };
  &#PROFILE_TYPE_WHS {
    color: #000 !important;
  };
  &#PROFILE_TYPE_GRP {
    color: #000 !important;
  }
}*/

.tc-subheading {
      @include typo-subhead;
      @extend %tc-ellipsis;
    }

//Custom Classes
// To be removed
/*.expanded{
.tc-body1--txt{
  font-size: 14px;
}

}*/

.my-bordered-list {
  /* remove the side padding. we'll be placing it around the item instead. */
  padding-right: 0;
  padding-left: 0;
}
.my-bordered-list .mdc-list-item {
  /* Add the list side padding padding to the list item. */
  padding: 0 16px;
  /* Add a border around each element. */
  border: 1px solid rgba(0, 0, 0, .12);
}
/* Ensure adjacent borders don't collide with one another. */
.my-bordered-list .mdc-list-item:not(:first-child) {
  border-top: none;
}
//Font Weight

.opt-fontweight{
  &__normal{
    font-weight: 400 !important;
  }

  &__medium{
    font-weight: 500 !important;
  }

  &__bold{
    font-weight: 600 !important;
  }

  &__bolder{
    font-weight: 600 !important;
  }
}


///**
// * Copyright 2015 Google Inc. All Rights Reserved.
// *
// * Licensed under the Apache License, Version 2.0 (the "License");
// * you may not use this file except in compliance with the License.
// * You may obtain a copy of the License at
// *
// *      http://www.apache.org/licenses/LICENSE-2.0
// *
// * Unless required by applicable law or agreed to in writing, software
// * distributed under the License is distributed on an "AS IS" BASIS,
// * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// * See the License for the specific language governing permissions and
// * limitations under the License.
// */
//
//@import "tc-md-variables";
//@import "tc-md-mixins";
//
//@if $target-elements-directly == true {
//  html, body {
//    font-family: $performance_font;
//    font-size: 14px;
//    font-weight: 400;
//    line-height: 20px;
//  }
//
//  h1, h2, h3, h4, h5, h6, p {
//    margin: 0;
//    padding: 0;
//  }
//
//  /**
//  * Styles for HTML elements
//  */
//
//  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
//    @include typo-display-3($colorContrast: true);
//    font-size: 0.6em;
//  }
//
//  h1 {
//    @include typo-display-3;
//
//    margin-top: 24px;
//    margin-bottom: 24px;
//  }
//
//  h2 {
//    @include typo-display-2;
//
//    margin-top: 24px;
//    margin-bottom: 24px;
//  }
//
//  h3 {
//    @include typo-display-1;
//
//    margin-top: 24px;
//    margin-bottom: 24px;
//  }
//
//  h4 {
//    @include typo-headline;
//
//    margin-top: 24px;
//    margin-bottom: 16px;
//  }
//
//  h5 {
//    @include typo-title;
//
//    margin-top: 24px;
//    margin-bottom: 16px;
//  }
//
//  h6 {
//    @include typo-subhead;
//
//    margin-top: 24px;
//    margin-bottom: 16px;
//  }
//
//  p {
//    @include typo-body-1;
//    margin-bottom: 16px;
//  }
//
//  a {
//    color: $text-link-color;
//    font-weight: 500;
//  }
//
//  blockquote {
//    @include typo-blockquote;
//  }
//
//  mark {
//    background-color: #f4ff81;
//  }
//
//  dt {
//    font-weight: 700;
//  }
//
//  address {
//    @include typo-caption;
//    font-style: normal;
//  }
//
//  ul, ol {
//    @include typo-body-1;
//  }
//}
//
///**
// * Class Name Styles
// */
//
//.mdl-typography--display-4 {
//  @include typo-display-4;
//}
//
//.mdl-typography--display-4-color-contrast {
//  @include typo-display-4($colorContrast: true);
//}
//
//.mdl-typography--display-3 {
//  @include typo-display-3;
//}
//
//.mdl-typography--display-3-color-contrast {
//  @include typo-display-3($colorContrast: true);
//}
//
//.mdl-typography--display-2 {
//  @include typo-display-2;
//}
//
//.mdl-typography--display-2-color-contrast {
//  @include typo-display-2($colorContrast: true);
//}
//
//.mdl-typography--display-1 {
//  @include typo-display-1;
//}
//
//.mdl-typography--display-1-color-contrast {
//  @include typo-display-1($colorContrast: true);
//}
//
//.mdl-typography--headline {
//  @include typo-headline;
//}
//
//.mdl-typography--headline-color-contrast {
//  @include typo-headline($colorContrast: true);
//}
//
//.mdl-typography--title {
//  @include typo-title;
//}
//
//.mdl-typography--title-color-contrast {
//  @include typo-title($colorContrast: true);
//}
//
.mdl-typography--subhead {
  @include typo-subhead;
}
//
//.mdl-typography--subhead-color-contrast {
//  @include typo-subhead($colorContrast: true);
//}
//
//.mdl-typography--body-2 {
//  @include typo-body-2;
//}
//
//.mdl-typography--body-2-color-contrast {
//  @include typo-body-2($colorContrast: true);
//}
//
//.mdl-typography--body-1 {
//  @include typo-body-1;
//}
//
//.mdl-typography--body-1-color-contrast {
//  @include typo-body-1($colorContrast: true);
//}
//
//.mdl-typography--body-2-force-preferred-font {
//  @include typo-body-2($usePreferred: true);
//}
//
//.mdl-typography--body-2-force-preferred-font-color-contrast {
//  @include typo-body-2($colorContrast: true, $usePreferred: true);
//}
//
//.mdl-typography--body-1-force-preferred-font {
//  @include typo-body-1($usePreferred: true);
//}
//
//.mdl-typography--body-1-force-preferred-font-color-contrast {
//  @include typo-body-1($colorContrast: true, $usePreferred: true);
//}
//
//.mdl-typography--caption {
//  @include typo-caption;
//}
//
//.mdl-typography--caption-force-preferred-font {
//  @include typo-caption($usePreferred: true);
//}
//
//.mdl-typography--caption-color-contrast {
//  @include typo-caption($colorContrast: true);
//}
//
//.mdl-typography--caption-force-preferred-font-color-contrast {
//  @include typo-caption($colorContrast: true, $usePreferred: true);
//}
//
//.mdl-typography--menu {
//  @include typo-menu;
//}
//
//.mdl-typography--menu-color-contrast {
//  @include typo-menu($colorContrast: true);
//}
//
//.mdl-typography--button {
//  @include typo-button;
//}
//
//.mdl-typography--button-color-contrast {
//  @include typo-button($colorContrast: true);
//}
//
//.mdl-typography--text-left {
//  text-align: left;
//}
//
//.mdl-typography--text-right {
//  text-align: right;
//}
//
//.mdl-typography--text-center {
//  text-align: center;
//}
//
//.mdl-typography--text-justify {
//  text-align: justify;
//}
//
//.mdl-typography--text-nowrap {
//  white-space: nowrap;
//}
//
//.mdl-typography--text-lowercase {
//  text-transform: lowercase;
//}
//
//.mdl-typography--text-uppercase {
//  text-transform: uppercase;
//}
//
//.mdl-typography--text-capitalize {
//  text-transform: capitalize;
//}
//
//.mdl-typography--font-thin {
//  font-weight: 200 !important;
//}
//
//.mdl-typography--font-light {
//  font-weight: 300 !important;
//}
//
//.mdl-typography--font-regular {
//  font-weight: 400 !important;
//}
//
//.mdl-typography--font-medium {
//  font-weight: 500 !important;
//}
//
//.mdl-typography--font-bold {
//  font-weight: 700 !important;
//}
//
//.mdl-typography--font-black {
//  font-weight: 900 !important;
//}
//
.material-icons {
    /* Support for IE */
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga';
    ms-font-feature-settings:'liga'
}
// Icon moon icon library set clasess
