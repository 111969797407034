.item_details{
    .facility-short-description{
        .description-text{
            &::after{
                content: "...   ";
            }
        }
        .read-more-link{
            cursor:pointer;
            display:inline-block;
            @media screen and (max-width: 768px) {
                display: contents;
            }  
            &:hover{
                text-decoration:underline;
            }
        }
    }
    .facility-long-description{
        .description-text{
            &::after{
                content: " ";
            }
        }
        .show-less-link{
            cursor:pointer;
            &:hover{
                text-decoration:underline;
            }
        }
    }
    .description-text {
        display: inline;
    }
}

.booking--details .title .name span {
    color: #000000 !important;
}