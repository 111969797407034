@import '../base/_tc-md-mixins-new.scss';

.mmc_card {
 // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border: 1px solid #eee;
  border-radius:4px;
 box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover {
   -webkit-box-shadow: 0px 0px 5px 3px $pw-box-shadow-color__primary;
   -moz-box-shadow: 0px 0px 5px 3px $pw-box-shadow-color__primary;
   box-shadow:  0px 0px 5px 3px $pw-box-shadow-color__primary;
  }

  &.search--result {
    padding: 15px;
    margin-bottom: 20px;
    background-color: #fff;

  }
  &.payment {
      margin-top:100px;
    
     @include respond-to("x-small") {
          max-width: 90%;
     }
     @include respond-to("small") {
          max-width: 70%;
     }
     @include respond-to("medium") {
          max-width: 70%;
     }
     @include respond-to("large") {
          max-width: 60%;
     }
     @include respond-to("x-large") {
          max-width: 60%;
     }
  }
}

.disable-div{
    cursor: not-allowed !important;
    opacity: 1;
    &:hover{
        opacity: 0.5 !important;
    }
}