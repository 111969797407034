.loader{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .sniper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #fff !important;
        max-width: 550px;
        max-height: 250px;
        z-index: 9999;
        padding: 30px;
    }
    
}


//...............................

.mmc_alert{
      //border-radius: 5px;

    

     @include respond-to("x-small") {
          width: 100%;
        
    }
    @include respond-to("small") {
          width: 100%;
    }
    @include respond-to("medium") {
         width: 715px;
    }
    @include respond-to("large") {
         width: 715px;
    }
    @include respond-to("x-large") {
        width: 715px;
    }



     .font__h5{
  @include respond-to("x-small") {
            font-size: 16px;
    line-height: 20px;
    margin: 5px 0;
      font-weight: 700;
    }
    
    @include respond-to("small") {
      font-size: 16px;
      line-height: 20px;
      margin: 5px 0;
    }
    
    @include respond-to("medium") {
      font-size: 16px;
      line-height: 20px;
      margin: 5px 0;
    }
    
    @include respond-to("large") {
      font-size: 16px;
      line-height: 20px;
      margin: 5px 0;
    }
    @include respond-to("x-large") {
           font-size: 16px;
    line-height: 20px;
    margin: 5px 0;
    }
     }

      &.__success {
        background-color: green;
      }
      &.__error {
        background-color: #FFA726;
      }
      &.__warning-alert {
        background-color: #e53935;
        border-radius: 5px;
      }
    .inner-wrapper{
          padding: 8px;
          min-height: 80px;
          width: 100%;
          display: flex;
          color: #fff;        
    }
    .alert--icon{
        font-size: 3rem !important;
      //  border-right: 2px solid #fff;
        padding-right: 24px;
        // margin-right:24px;
        color: #fff !important; 
        width: 100%; 
        height: 100%; 
        display: flex; 
        align-items: center;
    }
    .text-content{
       .wider-text{
           max-width: 800px !important;
           -webkit-line-clamp: unset !important;
               display: block !important;
       }
       // padding-left: 20px;
    }
    .middle-content{
        flex-grow: 1;
    }

}
.mat-snack-bar-container{
  background: none;
  width: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
  //position: absolute;
  left: 0;
  margin:24px;
  padding:0;
}
.after-date-warning { 
  background-color: #FFA726 !important;
  .col{ 
    padding: 0 !important; 
  } 
  .font__h5{ 
    margin: 0 !important; 
  }
  .inner-wrapper { 
    .material-icons { 
      color: rgb(255, 255, 255) !important; 
    }
    .middle-content { 
      .text-content { 
        .wider-text { 
          // letter-spacing: 0 !important; 
          // line-height: 1.4 !important; 
          color: rgb(255, 255, 255); 
          max-width: 100% !important; 
        } 
      } 
    } 
  } 
}
