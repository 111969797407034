@import "../components/buttons";

.header__alter-menu{
    justify-content: space-between;

    .sub.wm-top-name{
        @media screen and (max-width:400px) {
            flex-direction: row !important;
            // align-items: stretch;
        }

        .wm-sign-in-btn{
            @media screen and (max-width:400px) {
                // margin-bottom:1rem !important;
            }

            @media screen and (max-width:768px) {
                width: 80%;
            }
            .ng-star-inserted{
               // width: auto;
                // overflow: hidden !important;
                overflow: unset !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                width: 12ch !important;

            }
            a.pw-txt-b2{
                padding-left: 0 !important;
            }
        }

        .wm-login-user-profile-icon-wrapper{

            @media screen and (max-width:400px) {
                width:10% !important;
                margin-bottom: 0.5rem !important;
                margin-top: 0.5rem;
            } 

            @media screen and (max-width:768px) {
                width:40px !important;
            }

            .wm-profile-avatar{
                height: 100% !important;
                width: 100% !important;
                img.user__avatar{
                    width:30px !important;
                    height: unset !important;
                }
            }
        }

        .wm-home-cart{
            @media screen and (max-width:400px) {
                width: 88%;
            }
            @media screen and (max-width:768px) {
                width: 88%;
            }
        }
    }


    .sub_left{
        @media screen and (max-width:768px) {
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start;
        }
        .wp-mr-20 {
            font-size: 16px !important;
            @media screen and (max-width:768px) {
                padding: 5px 0;
                padding-bottom: 10px !important;
                font-size: 14px !important;
            }
        }
        .waste-header-flex{
            display: flex;
            align-items: center;

            @media screen and (max-width:768px) {
                flex-direction: column;
                margin-top: -5px;
                align-items: flex-start;
            }
        }
    }
}
::ng-deep .cdk-overlay-container .cdk-overlay-pane.mat-select-panel {
    min-width: 100% !important;
}

.mat-select-panel {
min-width: 100% !important;
}

div:focus {
    outline:none !important;
}

.without-step{
    margin-top: 50px;
}
.booking-snipper {
    margin-top:16px;
  .mat-form-field {
    width: 100%;
  }
}  
.snipper {
  .mat-horizontal-stepper-header-container{
      justify-content: center;
      @include respond-to("x-small") {
                display: flex;                
            }
            @include respond-to("small") {
                display: flex;                
            }
            @include respond-to("medium") {
                display: flex;
            }
            @include respond-to("large") {
                display: flex;
            }
            @include respond-to("x-large") {
                display: flex;
            }
  }
  .mat-horizontal-stepper-header {
  //  padding-bottom:50px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .number{
                    position: absolute;
                    top: 11px;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    color: white;
                    z-index: 9999;
               }
    .mat-step-icon {
      height: 40px !important;
      width: 40px !important;

      margin-right: 0 !important;
      flex: unset !important;
            @include respond-to("x-small") {
                display: none;                
            }
            @include respond-to("small") {
                display: none;                
            }
            @include respond-to("medium") {
                display: block;
            }
            @include respond-to("large") {
                display: block;
            }
            @include respond-to("x-large") {
                display: block;
            }
      &.mat-step-icon-state-edit{
          //position: relative !important;
          display: block;
          
          mat-icon{
              display: none !important;             
          }
          
           @include respond-to("x-small") {
                //border-bottom: 5px solid #000;
                position: absolute;
                border-radius: unset;
                height: 5px !important;
                width: 100% !important;
                bottom: 0px;
            }
            @include respond-to("small") {
                //border-bottom: 5px solid #000;
                position: absolute;
                border-radius: unset;
                height: 5px !important;
                width: 100% !important;
                bottom: 0px;
            }
            @include respond-to("medium") {
                border-bottom: none;
                position: static;
                border-radius: 50%;
                height: 40px !important;
                width: 40px !important;
                bottom: 0;
            }
            @include respond-to("large") {
                border-bottom: none;
                position: static;
                border-radius: 50%;
                height: 40px !important;
                width: 40px !important;
                bottom: 0;
            }
            @include respond-to("x-large") {
                border-bottom: none;
                position: static;
                border-radius: 50%;
                height: 40px !important;
                width: 40px !important;
                bottom: 0;
            }
          }
      &.mat-step-icon-selected{
          display: block !important;
        //   span{
        //       display: none;
        //   }
         
           @include respond-to("x-small") {
                position: absolute;
                border-radius: unset;
                height: 5px !important;
                width: 100% !important;
                bottom: 0px;
            }
            @include respond-to("small") {
                position: absolute;
                border-radius: unset;
                height: 5px !important;
                width: 100% !important;
                bottom: 0px;
            }
            @include respond-to("medium") {
                border-bottom: none;
                position: static;
                border-radius: 50%;
                height: 40px !important;
                width: 40px !important;
                bottom: 0;
            }
            @include respond-to("large") {
                border-bottom: none;
                position: static;
                border-radius: 50%;
                height: 40px !important;
                width: 40px !important;
                bottom: 0;
            }
            @include respond-to("x-large") {
                border-bottom: none;
                position: static;
                border-radius: 50%;
                height: 40px !important;
                width: 40px !important;
                bottom: 0;
            }
          }
      /*&.mat-step-icon-selected{
          @include respond-to("x-small") {
                border-bottom: 4px solid #3f51b5;           
           }
           @include respond-to("small") {
                border-bottom: 4px solid #3f51b5;            
           }
           @include respond-to("medium") {
                border-bottom: none;
           }
           @include respond-to("large") {
                border-bottom: none;
           }
           @include respond-to("x-large") {
                border-bottom: none;
           }
      }*/
     
       .mat-icon, .mat-step-icon-content {
            @include respond-to("x-small") {
                display: none;   
                position:absolute;            
           }
           @include respond-to("small") {
                display: none;   
                position:absolute;    
           }
           @include respond-to("medium") {
                display: block;
                position:relative;
           }
           @include respond-to("large") {
                display: block;
                position:relative;
           }
           @include respond-to("x-large") {
                display: block;
                position:relative;
           }
    }
       span.ng-star-inserted{
            //margin-left: 8px;
    }

       .ng-star-inserted{
                  /* padding: 5px; */
    /* width: 24px; */
    display: block;
    text-align: center;
    /* margin-left: 8px; */
    margin-top: 1px;
    margin-left: auto;
    margin-right: auto;
    line-height:13px;

    mat-icon{
        line-height: 14px;
    }

    

       }


    }
  }
  .mat-stepper-horizontal-line {
    border-top-width: 10px !important;
    border-top-style: dotted !important;
    margin: 0px 20px !important;
    @include respond-to("x-small") {
        display: none;              
    }
    @include respond-to("small") {
        display: none;
    }
    @include respond-to("medium") {
        display: block;
    }
    @include respond-to("large") {
        display: block;
    }
    @include respond-to("x-large") {
        display: block;
    }
  }
  /*.mat-step-icon-selected{
      @include respond-to("x-small") {
            border-bottom: 4px solid #3f51b5;           
       }
       @include respond-to("small") {
            border-bottom: 4px solid #3f51b5;            
       }
       @include respond-to("medium") {
            border-bottom: none;
       }
       @include respond-to("large") {
            border-bottom: none;
       }
       @include respond-to("x-large") {
            border-bottom: none;
       }
  }*/
  .mat-step-icon-state-edit {
    background-color: green !important;
    color: #fff;

        .mat-step-icon-content{
            position :absolute !important;
            display: block;
            // .material-icon{
            //    display: none;
            // }                
    }
    .mat-icon{
       @include respond-to("x-small") {
            position:absolute !important;          
       }
       @include respond-to("small") {
            position:absolute !important;       
       }
       @include respond-to("medium") {
            position:absolute !important; 
       }
       @include respond-to("large") {
            position:absolute !important;
       }
       @include respond-to("x-large") {
            position:absolute !important; 
            

       }
    }
    .mat-step-icon-content{
         @include respond-to("x-small") {
            left: 0;         
       }
       @include respond-to("small") {
            left: 0;       
       }
       @include respond-to("medium") {
            left: 19px !important;
       }
       @include respond-to("large") {
            left: 19px !important; 
       }
       @include respond-to("x-large") {
            left: 19px !important;

       }
    }
    
  }
  .mat-step-label {
    min-width: unset;
  }
  .rmv-border{
        .mat-horizontal-stepper-header{
            .mat-step-header-ripple{
                border-right: none !important;
            }
        }
    }
  .mat-step-header-ripple{
      &:last-child {
                border-right: none !important;
            }
       @include respond-to("x-small") {
            border-right: 1px solid #b5b5b5;
       }
       @include respond-to("small") {
            border-right: 1px solid #b5b5b5;
       }
       @include respond-to("medium") {
            border-bottom: none;
            border-right: none;
       }
       @include respond-to("large") {
            border-bottom: none;
            border-right: none;
       }
       @include respond-to("x-large") {
            border-bottom: none;
            border-right: none;
       }
  }
  .activity-title {
      font-family: Tahoma !important;
    padding-top: 10px;
    @include font-size(16);
    @include font-weight(medium);
    color: #202020;
    /*&:after {
        content: "";
        height: 100%;
        border-bottom: 4px solid #2ecd73;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }*/
     @media only screen and (max-width: 768px) {
        white-space:normal !important;
        font-size:10px !important;
        line-height:inherit !important;
    }
  }
}
.mat-tab-body-content{
        padding-top:30px;
    }
.mat-ink-bar {
    background-color: $pw_btn-bg-color__primary;
    height:4px;
}
.mat-tab-label{
    @include font-size(18);
}


//breadcrum
ul {
    &.breadcrumb {
      //   padding: 10px 16px;
      text-transform: uppercase;
      margin-bottom:10px;
      margin-top:10px;
      list-style: none;
      background-color: #fff;
      padding-left: 0;
      li {
        display: inline;
        
        @include respond-to("x-small") {
            @include font-size(13);
       }
       @include respond-to("small") {
            @include font-size(15);
       }
       @include respond-to("medium") {
            @include font-size(18);
       }
       @include respond-to("large") {
            @include font-size(18);
       }
       @include respond-to("x-large") {
            @include font-size(18);
       }

        &:not(:first-child) {
          &:before {
            padding: 8px;
            color: black;
            content: ">";
          }
        }
      }
      a {
        //padding: 10px 16px;
        color: #0275d8;
        text-decoration: none;
        font-weight:500;
        &:hover {
          color: #01447e;
          text-decoration: underline;
        }
      }
    }
  }

//layout css


.cart-hide{   
    @include respond-to("x-small") {
         display: none;
    }
    @include respond-to("small") {
         display: none;
    }
    @include respond-to("medium") {
         display: block;
    }
    @include respond-to("large") {
         display: block; 
    }
    @include respond-to("x-large") {
         display: block;
    }
}
.mat-tab-label-active{
   
    webkit-box-shadow: 0px -2px 12px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px -2px 12px -3px rgba(0,0,0,0.75);
    box-shadow: 0px -2px 12px -3px rgba(0,0,0,0.75);
    background-color: $pw_btn-bg-color__primary ;
    /*border: 1px solid #7d7d84 !important;*/
    color: $pw_btn-text-color !important;
    opacity: 1 !important;
    &:focus{
        webkit-box-shadow: 0px -2px 12px -3px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px -2px 12px -3px rgba(0,0,0,0.75);
        box-shadow: 0px -2px 12px -3px rgba(0,0,0,0.75);
        background-color: $pw_btn-bg-color__primary;
        /*border: 1px solid #7d7d84;*/
        color: $pw_btn-text-color;
        opacity: 1 !important;
    }

}

.mat-tab-label{
    .tab-img{
        @include respond-to("x-small") {
         display: block;
        }
        @include respond-to("small") {
             display: none;
        }
        @include respond-to("medium") {
             display: none;
        }
        @include respond-to("large") {
             display: none; 
        }
        @include respond-to("x-large") {
             display: none;
        }
    }
    .tab-text{
        @include respond-to("x-small") {
         display: none;
        }
        @include respond-to("small") {
             display: block;
        }
        @include respond-to("medium") {
             display: block;
        }
        @include respond-to("large") {
             display: block; 
        }
        @include respond-to("x-large") {
             display: block;
        }
    }
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $pw_btn-bg-color__primary;

}

.mat-tab-body-content {
    padding-top: 0px;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.65);
}

.mmc_corporate.client_wrapper{
    margin:0px;
    border-top: 2px solid #2560bc;

    @media screen and (max-width: 415px) {
        .contact-person {
            .remove_right_padding {
                flex: 0 0 auto !important;
            }
            .user-name {
                flex: 0 0 auto !important;
            }
        }
        .contact-detail {
            flex-direction: column;
            .drop-area {
                flex: 1 1 auto !important;
            }
            .add-more {
                flex: 0 1 85px !important;
            }
        }
    }
}



.mat-tab{
    &:hover{
        cursor: pointer;
    }
}
.mat-tab-group{
    margin-top: 20px;
}


.mat-tab-header{
    margin-bottom: -2px
}






//new section
.setup_page{
    min-height:47vh;
}
.setup_page, .bookingthrounghReg{
    //min-height: 430px;
    .mat-horizontal-stepper-header-container{
        display: none;
    }
    .mat-stepper-horizontal{
        margin-top: 10px;
    }
    .mat-horizontal-content-container{
        padding: 0;
        
        .mat-tab-group{
            min-height:700px;
        }
    }
}
.regSec{
    &__title{
        border-bottom: 1px solid #e3e3e3;
        &--main, main{
            @include font-size(24);
            font-weight: 700;
        }
        &--sub, sub{
            @include font-size(14);
            font-weight: 400;
        }
    }
    &__body{
        @include respond-to("x-small") {
            flex-direction: column;
            margin: 10px;
        }
        @include respond-to("small") {
             flex-direction: column;
             margin: 20px;
        }
        @include respond-to("medium") {
             flex-direction: row;
             margin: 50px;
        }
        @include respond-to("large") {
             flex-direction: row;
             margin: 50px;
        }
        @include respond-to("x-large") {
             flex-direction: row;
             margin: 50px;
        }
        display:flex;        
        justify-content: center;
        
        &--customer, customer{
            position: relative;
            @include respond-to("x-small") {
                padding: 20px 20px 74px;
            }
            @include respond-to("small") {
                 padding: 20px 60px 74px;
            }
            @include respond-to("medium") {
                 padding: 20px 60px 74px;
            }
            @include respond-to("large") {
                 padding: 20px 60px 74px;
            }
            @include respond-to("x-large") {
                 padding: 20px 60px 74px;
            }
            
            &:not(:last-child){
                @include respond-to("x-small") {
                    border-right: none;
                }
                @include respond-to("small") {
                     border-right: none;
                }
                @include respond-to("medium") {
                     border-right: 1px solid #b6b6b6;
                }
                @include respond-to("large") {
                     border-right: 1px solid #b6b6b6;
                }
                @include respond-to("x-large") {
                     border-right: 1px solid #b6b6b6;
                }
                &::after{
                    position: absolute;
                    top: 40%;
                    border-radius:50%;
                    content:"or";
                    height:50px;
                    width: 50px;
                    right:-25px;
                    /* text-align:center; */
                    vertical-align:center;
                    background-color:#f1f3f6;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                     @include respond-to("x-small") {
                        display: none;
                    }
                    @include respond-to("small") {
                         display: none;
                    }
                    @include respond-to("medium") {
                         display: flex;
                    }
                    @include respond-to("large") {
                         display: flex;;
                    }
                    @include respond-to("x-large") {
                         display: flex;
                    }
                }
            }
            .mat-button{
                padding:0;
                width: 100%;

            }
            .name{
                @include font-size(16);
                font-weight:600;
                padding-bottom: 30px;
                span{
                    color: #006ff8;
                }
            }
            .outer-wrapper{
                height:115px;
                width: 115px;
                background:#f1f3f6;
                justify-content: center;
                border-radius: 50%;
                margin:5px;
                cursor: pointer;
               

            }
            .inner-wrapper{
                height:100px;
                width: 100px;
                background:#fff;
                justify-content: center;
                border-radius: 50%;
                display:flex;
                align-items: center;
                border: 1px dashed #b6b6b6;
            }
            &-indi{

            }
            .mmc{
                font-size: 40px;
                color: #C4C4C4;
               
            }
            .rectangle{
                border: 1px dashed #b6b6b6;
                //width: 260px;
                border-radius: 5px;
                justify-content: center;
                &:hover{
                    -webkit-box-shadow: 1px 3px 5px -1px rgba(0,0,0,0.74);
                    -moz-box-shadow: 1px 3px 5px -1px rgba(0,0,0,0.74);
                    box-shadow: 1px 3px 5px -1px rgba(0,0,0,0.74);
                    transition: 0.3s;
                    background: #006ff8;
                    border: none;
                    .outer-wrapper{
                        background: #76a9fc;
                    }
                    .mmc{
                        color: #006ff8;
                    }
                    .inner-wrapper{
                        border:none;
                    }
                }
            }
            
        }

        
    }
}    

