//@import "../sass/helpers/functions";
@import "../../sass/helpers/mixins";
@import "../../sass/helpers/variables";
@import "../../sass/base/color";
@import "../../sass//layout/grid";
@import "../../sass/components/buttons";

.login__signin .login__right-sec form .forget-email {
  position: relative;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  .forget-email-username-icon {
    position: absolute !important;
    top: 30% !important;
  }
  .forget-email-username-placeholder {
    margin-left: 30px;
  }
}

.login .login__wrap .login__bg .login__signin .login__right-sec{

  .login__name{
    .icon-user{
      top: -4px;
      position: relative;
    }
  }

  .login__password {
    img{
      top: -4px;
      position: relative;
    }
  }

 .recaptcha {
    @media screen and (max-width: 768px) {
      margin: auto;
      width: 90%;
    }
  }
}

// ------------------------------------- Legacy CSS-----------------

.txt-summery {
  width: 100%;
}

.login__bg {
  color: #454545;

  .mat-form-field-invalid,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #454545;
  }
}
.font__error-fp {
  color: #f44336;
  font-weight: 400;
}

.login-pw-reset {
  align-items: center;
  justify-content: center;
  @include respond-to("x-small") {
    display: none !important;
  }
  @include respond-to("small") {
    display: none !important;
  }
  @include respond-to("medium") {
    display: flex !important;
  }
  @include respond-to("large") {
    display: flex !important;
  }
  @include respond-to("x-large") {
    display: flex !important;
  }

  .pw-reset-image {
    height: 200px;
  }
}
.return:hover{
    cursor: pointer;
    color:#006ff8 !important;
}
.return{
  text-decoration: underline;
}

.login {
  .login__return {
    span {
      padding-left: 10px;
    }
  }
  &__wrap {
    margin: 0;
    @include respond-to("x-small") {
      display: flex !important;
      justify-content: center !important;
      width: 100%;
    }
    @include respond-to("small") {
      display: flex !important;
      justify-content: center !important;
      width: 100%;
    }
    @include respond-to("medium") {
      display: block;
    }
    @include respond-to("large") {
      display: block;
    }
    @include respond-to("x-large") {
      display: block;
    }
  }

  .error-login {
    @include font-size(14);
    text-align: left;
    color: red;
  }
  .mat-form-field-underline {
    display: none;
  }
  padding: 60px 0;
  color: black !important;
  @include font-size();
  /*offset-md-2*/
  &__welcome {
    @include respond-to("x-small") {
      display: none;
    }
    @include respond-to("small") {
      display: none;
    }
    @include respond-to("medium") {
      /*display:block;*/
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    @include respond-to("large") {
      /*display:block;*/
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    @include respond-to("x-large") {
      /*display:block;*/
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    width: 100%;
    // background: url(../../assets/images/signup-bg.jpg) no-repeat center;
    // background-color: #006ff8;
    background: linear-gradient(-241deg, #2388f0 0%, #00698f 100%);
    background-size: cover;
    border-radius: 10px 0 0 10px;
    padding: 0px 0;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35);
    z-index: 1;
  }
  // &__text-middle {
  //   margin: 0 auto;
  // }
  &__register {
    padding: 45px 0 55px;
    font-weight: 400;
    line-height: 1.2;
    @include font-size;

    white-space: -moz-pre-wrap !important;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
    word-wrap: break-word;
    white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/

    white-space: normal;
  }
  &__left-sec {
    // @include padding(padding, all, 0 15);
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
  &__right-sec {
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    .login__title {
      p {
        padding: 10px;
      }
    }
    form {
      @include respond-to("x-small") {
        width: 100%;
        padding: 20px 5px;
      }
      @include respond-to("small") {
        width: 100%;
        padding: 20px 5px;
      }
      @include respond-to("medium") {
        width: 100%;
        padding: 0px 5px;
      }

      @include respond-to("large") {
        width: 100%;
        padding: 0px 5px;
      }
      @include respond-to("x-large") {
        width: 100%;
        padding: 0px 5px;
      }

      .forget-email {
        //width:70%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &__center {
    display: flex;
    justify-content: center;
    padding: 0 25px;
  }
  &__welcome-text {
    @include font-size(33);
  }
  &__signin {
    background: #eff8fd;
    // border-radius: 0 10px 10px 0;
    /*padding: 50px 0 50px;*/
    @include respond-to("x-small") {
      border-radius: 10px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    @include respond-to("small") {
      border-radius: 0 10px 10px 0;
      padding: 50px 0 50px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    @include respond-to("medium") {
      padding: 20px 0 20px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    @include respond-to("large") {
      padding: 20px 0 20px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    @include respond-to("x-large") {
      padding: 20px 0 20px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  &__signin-text {
    @include font-size(33);
  }
  &__title {
    color: #00698f;
    text-transform: uppercase;
  }
  &__sub {
    padding: 16px 15px 16px;
    color: $color-text;
    margin-bottom: 0 !important;
  }
  &__name {
    margin-bottom: 20px;
  }
  &__name,
  &__password {
    width: 100%;
    background: $color-login-input-bg;
    padding: 0 10px;
    border-radius: 5px;

    img {
      width: 25px;
      height: 25px;
      margin-right: 20px;
      vertical-align: middle;
    }
  }

  .mat-form-field {
    width: 100%;
  }

  &__input {
    vertical-align: bottom;
    height: 25px;
    min-width: 80%;
    border: none;
    background: none;
    font-size: 14px;

    &::placeholder {
      //  font-size: 16px;
      //  font-weight: 500;
    }
    color: black;
    &:focus {
      outline-color: $color-login-input-bg;
    }
  }
  &__btn {
    border: none;
    color: white;
    text-transform: uppercase;
    &--signup {
      background: none;
      margin: 0;
    }
  }
  &__submit {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  &__forgot {
    color: black !important;
    border-bottom: 1px solid black;
    &:hover,
    &:focus {
      text-decoration: none;
      color: #006ff8 !important;
      border-bottom: 1px solid #006ff8;
    }
  }
  &__bg {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 10px 10px 0;
    background: $color-login-bg;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35);
    @include respond-to("x-small") {
      height: auto;
      border-radius: 10px 10px 10px 10px;
    }
    @include respond-to("small") {
      height: auto;
      border-radius: 10px 10px 10px 10px;
    }
    @include respond-to("medium") {
      height: auto;
      border-radius: 0 10px 10px 0;
    }
    @include respond-to("large") {
      height: auto;
      border-radius: 0 10px 10px 0;
    }
    @include respond-to("x-large") {
      height: auto;
      border-radius: 0 10px 10px 0;
    }

    input {
      //   border: none;
      //   background: transparent;
      //   width: 100%;
      /*padding: 10px;*/
      //   outline:none;
    }
    .mat-form-field-infix {
      //  top: 4px;
    }

    /*
    .mat-form-field{
        &.mat-focused {
            .placeholder{
              //display:none !important;
            }
        }

      .mat-input-element{
          color: #000 !important;
          @include font-size(16);
          position: relative;
          top: 4px;
          &:visited{
            .placeholder{
              display:none !important;
            }
        }
      }
      .mat-form-field-label {
          color: #000;
      }

  }

*/
  }
}
.text-center {
  text-align: center;
}
.sigup--mobile {
  @include respond-to("x-small") {
    display: block;
    padding-top: 32px;
    padding-bottom: 32px;
    color: $color-text-dark;

    .goto-home {
      color: #0275d8 !important;
    }
  }
  @include respond-to("small") {
    display: block;
    padding-top: 32px;
    padding-bottom: 32px;
    color: $color-text-dark;
    .goto-home {
      color: #0275d8 !important;
    }
  }
  @include respond-to("medium") {
    display: none;
  }
  @include respond-to("large") {
    display: none;
  }
  @include respond-to("x-large") {
    display: none;
  }

  &__link {
    cursor: pointer !important;
  }
}

@media screen and (max-width: 576px) {
  .login {
    padding-top: 20px;
    &__welcome {
      display: none;
    }
    &__bg {
      padding: 0;
      background: none;
      border-radius: 0;
      //padding-bottom: 50px;
      border-radius: 10px;
    }
  }
  /*.sigup--mobile {
    display: block;
    padding-top: 100px;
    padding-bottom: 30px;
    color: $color-text-dark;
    &--link {
      color: $color-text-link;
    }
  }*/
}

// test
.bg-bubbles {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: -1;

  li {
    position: absolute;
    list-style: none;
    background-color: #c6afaf;
    opacity: 0.1;
    display: block;
    width: 40px;
    height: 40px;
    background-color: fade(white, 15%);
    bottom: -160px;

    -webkit-animation: square 25s infinite;
    animation: square 25s infinite;

    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;

    &:nth-child(1) {
      left: 10%;
    }

    &:nth-child(2) {
      left: 20%;

      width: 80px;
      height: 80px;

      animation-delay: 2s;
      animation-duration: 17s;
    }

    &:nth-child(3) {
      left: 25%;
      animation-delay: 4s;
    }

    &:nth-child(4) {
      left: 40%;
      width: 60px;
      height: 60px;

      animation-duration: 22s;

      background-color: fade(white, 25%);
    }

    &:nth-child(5) {
      left: 70%;
    }

    &:nth-child(6) {
      left: 80%;
      width: 120px;
      height: 120px;

      animation-delay: 3s;
      background-color: fade(white, 20%);
    }

    &:nth-child(7) {
      left: 32%;
      width: 160px;
      height: 160px;

      animation-delay: 7s;
    }

    &:nth-child(8) {
      left: 55%;
      width: 20px;
      height: 20px;

      animation-delay: 15s;
      animation-duration: 40s;
    }

    &:nth-child(9) {
      left: 25%;
      width: 10px;
      height: 10px;

      animation-delay: 2s;
      animation-duration: 40s;
      background-color: fade(white, 30%);
    }

    &:nth-child(10) {
      left: 90%;
      width: 160px;
      height: 160px;

      animation-delay: 11s;
    }
  }
}

@-webkit-keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-850px) rotate(600deg);
  }
}
@keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-850px) rotate(600deg);
  }
}

//crome autho fill colr
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill {
  -webkit-text-fill-color: none !important;
}

.goto-home {
  justify-content: center;
  color: white;
  font-size: 14px;
  padding-top: 20px;
  &:hover {
    color: white;
  }
}
.container-fluid {
  &.remove-padding {
    @include respond-to("x-small") {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
    @include respond-to("small") {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
    @include respond-to("medium") {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
    @include respond-to("large") {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
    @include respond-to("x-large") {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
    .container {
      @include respond-to("x-small") {
        padding-top: 0;
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      @include respond-to("small") {
        padding-top: 10px;
        padding-right: 15px !important;
        padding-left: 15px !important;
      }
      @include respond-to("medium") {
        padding-top: 10px;
        padding-right: 15px !important;
        padding-left: 15px !important;
      }
      @include respond-to("large") {
        padding-top: 10px;
        padding-right: 15px !important;
        padding-left: 15px !important;
      }
      @include respond-to("x-large") {
        padding-top: 10px;
        padding-right: 15px !important;
        padding-left: 15px !important;
      }
    }
  }
}
.rc-anchor-normal {
  max-width: 285px !important;
}
.mat-form-field-appearance-legacy {
    .mat-form-field-subscript-wrapper{
        white-space: nowrap;
        min-width : 250px;
         @include respond-to("x-small") {
               left : 0;
         }
         @include respond-to("small") {
               left :0;
         }
         @include respond-to("medium") {
               left :0;
         }
         @include respond-to("large") {
               left :0;
         }
         @include respond-to("x-large") {
               left :0;
         }
    }
}

.sigup--mobile{
  .signup-checkout{
    padding: 5px !important;
    margin-bottom: 5px !important;
  }
}

.login-modal-width{

  @include respond-to("x-small") {
            max-width: 90% !important;
             width: 90% !important;
          }
        @include respond-to("small") {
               max-width: 90% !important;
                width: 90% !important;
          }
          @include respond-to("medium") {
               max-width: 90% !important;
               width: 90% !important;
          }
          @include respond-to("large") {
               max-width: 35% !important;
               width: 35% !important;
          }
          @include respond-to("x-large") {
               max-width: 35% !important;
                width: 35% !important;
          }
}

.wm-stepper-content {
    ::ng-deep mat-step-header {
        pointer-events: none !important;
    }
}
