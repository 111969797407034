@media print {
    body {
        -webkit-print-color-adjust: exact;
        -moz-print-color-adjust: exact;
        -ms-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}

.mmc_enquiry{
    color: #363636;
    -webkit-box-shadow: inset 1px 6px 10px 0px #c1c1c1;
    box-shadow: inset 1px 6px 10px 0px #c1c1c1;
    margin-bottom: 20px;
    .mmc_card{
       border-radius: 5px;
    }
    .top-line{
        padding:30px;
        border-bottom: 1px solid #eee;
        .icon-left{
            padding-right:20px;
            @include font-size(50);
            color: #00a651;
        }
        .enquiry-content{
            flex-grow: 1;
        }
        .icon-right{
            color: #c1c1c1;
            .material-icons{
                @include font-size(50);
                cursor: pointer;
            }
        }
    }
    .seco-line{
        padding:30px;
        flex-direction: column;
        border-radius: 5px;
        &--colored{
            flex-direction: column;
            padding: 20px;
            background-color: #faf5dd;
            margin: 20px;
        }
    }
    .bottom-line{
        border-radius: 0 0 5px 5px;
        border: 1px solid #fff;
        flex-direction: column;
        padding: 30px;
        background-color: #00a651;
        color: #fff;      
        .line{
            hr{
                background: #fff;
                margin-left: 10px;
            }
        }
        span{
            color: #fafbb8;
            @font-weight(medium);
            &.confirm-footer-mesg-txt{
                color: #fff;
            }
        }
        .para{
            white-space: normal;
        }
    }
    
}

.mmc_corporate_enquiry{
    .mmc_text_area{
        .mat-form-field-flex{
            height: auto !important;
        }
    }
    .dp-upload-wrapper .document-upload{
        width: 100%;
    }
    
    .mmc_required_docs{
        display: flex;
        flex-direction: column;
        gap: 16px;
        .mmc_required_docs_inner{
            display: flex;
            flex-direction: column;
            gap: 16px;
            border: solid 1px #9095a1;
            .mmc_required_docs_element{
                display: flex;
                flex-direction: column;
                gap: 8px;
                .mmc_required_docs_items{
                    display: flex;
                    gap: 8px;
                }
            }
        }
    }

    .mmc_privacy_notice{
        max-height: 340px;
        overflow-y: hidden;
        background-color: #fff;
    }
    .btn__card.btn-continue.main{
        height: auto !important;
        padding-right: 20px;
        padding-left: 20px;
        border-width: 1px !important;
    }
}

.mmc_banner{
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #bdd0fe;
    border-radius: 3px;
    margin-bottom: 32px;
    .mmc_banner-top{
        display: flex;
        flex-direction: column;
        gap: 8px;
        i{
            font-size: 24px;
        }
        &.flex-row.align--center.mb-4{
            @media only screen and (max-width: 768px){
                align-items: flex-start !important;
            }
        }
    }
    button.btn-continue.main{
        border-radius: 99px !important;
        border-width: 1px !important;
        padding-left: 20px;
        padding-right: 20px;
        min-width: unset;
        width: fit-content;
        height: auto;
    }
    &.p-5{
        @media only screen and (max-width: 768px){
            padding: 1.5rem !important;
        }
    }
}

#document-upload-container .ngx-file-drop__drop-zone.drop-zone {    
    height: auto !important;
    margin: auto !important;
    border-radius: 5px !important;
    border: unset !important;
    padding: 3px !important;
}
div#document-upload-container {
    padding: unset !important;
} 
.mmc_corporate_enquiry .mmc_required_docs .mmc_required_docs_inner .mmc_required_docs_element .mmc_required_docs_items i.material-icons.pw-ico-color{
    color: $color-blue-icon !important;
}
.doc-sec .doc-container .doc-inner {
    background: $color-white !important;
}

h3.pw-txt-h3.pw-text-semi-bold.mb-5 {
    margin-bottom: 1rem !important;
}

