/*.custom-dialog-container {
    width: 500px;
    margin-left:auto;
    margin-right: auto;
}*/


.mat-dialog-container{
    .mat-dialog-content{
      overflow: hidden;
      
      .modal-header{
        button.mat-button:focus{
            border: unset !important;
        }
      }
    }
  }
  // --------------------------- Legacy CSS ------------------------

.cdk-overlay-container {
  z-index: 1000 !important;
}

.modal-body{
    padding: 0 35px !important;
     @media only screen and (max-width: 768px) {
                    padding: 0 32px !important;
            }
}
.dialog-container-common{
    .mat-dialog-container{
        padding:0px;
    }
}
.w80modal{
    width: 80% !important;
    max-width: 80% !important;
}
.w60modal{
    width:60% !important;
    max-width:60% !important;
}
.modal-width,.custom-dialog-container{

    @include respond-to("x-small") {
              max-width: 90% !important;
               width: 90% !important;
               min-height: 150px !important;
               max-height: 95% !important;
            }
          @include respond-to("small") {
                 max-width: 90% !important;
                  width: 90% !important ;
                  min-height: 150px !important;
                  max-height: 95% !important;
            }
            @include respond-to("medium") {
                 max-width: 90% !important;
                 width: 90% !important;
                 max-height: 100% !important;
            }
            @include respond-to("large") {
                 max-width: 60%;
                 width: 60% !important;
                 max-height: 100% !important;
            }
            @include respond-to("x-large") {
                 max-width: 60% !important;
                  width: 60% !important;
                  max-height: 100% !important;
            }
}

.description-dialog-container{

  @include respond-to("x-small") {
            max-width: 90% !important;
             width: 90% !important;
             min-height: 200px !important;
             max-height: 95% !important;
          }
        @include respond-to("small") {
               max-width: 90% !important;
                width: 90% !important ;
                min-height: 200px !important;
                max-height: 95% !important;
          }
          @include respond-to("medium") {
               max-width: 90% !important;
               width: 50% !important;
               max-height: 100% !important;
          }
          @include respond-to("large") {
               max-width: 60%;
               width: 50% !important;
               max-height: 100% !important;
          }
          @include respond-to("x-large") {
               max-width: 60% !important;
                width: 40% !important;
                max-height: 100% !important;
          }
}

@media  (min-width:1024px) and (max-width:768px) {
  .payment-dialog-container{
      width: 80% !important;
      height: 500px !important;
  }
}

$start : 12;
$end : 18;

.chip-cage{
   display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-content: flex-start;
    align-items: flex-start;
}


@function calc-left($start) {
    @return ($start / 24) * 100%;
}
@function calc-width($end, $start) {
    $duration : $end - $start;
    @return ($duration / 24) * 100%;
}

.modal-header {
    /*z-index:99999;*/
    padding: 0;
    border-bottom: none;
    .mmc_flex--container{
        flex-grow: 1;
    }
    .icon{
        text-align: right; 
        padding-top: 8px;
        padding-right: 8px;
    }  
    .title{
               
    }
}
.footer-modal{
    justify-content: flex-end;
    margin-top: 15px;
    border-top: 1px solid lightgray;
    padding-top: 20px;
    .btn__card{
        margin: 0 10px;
        &.add{
            padding:0 25px;
        }
    }
}

.calender-modal-wrapper{
     .mat-dialog-content{
       
         @media only screen and (max-width: 768px) {
                    margin:0 !important;
            }
    }

     .modal-body{
         @media only screen and (max-width: 768px) {
                   padding: 0 10px !important;
         }

     }
}

.facility-calendar-modal {
  min-height: unset !important;

  .mat-dialog-content {
    padding: 20px;
    margin: 0px;
    overflow: hidden;
  }
}


.calendar-modal{
   
    .legend{
        display:flex;
        justify-content:center;
         @media only screen and (max-width: 768px) {
                                       margin-bottom:16px;
                                    }


        .w-80{
            /*width:80%;*/
            @media only screen and (max-width: 768px) {
                                       width:100%;
                                       flex-direction:column;
                                    }
        }

        .p15-web{
             width:15%;
            @media only screen and (max-width: 768px) {
                                       width:100%;
                                       /*flex-direction:column;*/
                                    }
        }

        .leg{
             height:8px;
            width:8px;

            &-1{
            height:8px;
            width:8px;

            /*background-image: linear-gradient(-180deg, #f5f5f5, #607D8B);*/
        }
            &-2{
              height:8px;
            width:8px;
            /*background-image: linear-gradient(-180deg, #CDDC39, #009688);*/
        }

            &-3{
              height:8px;
            width:8px;
            /*background-image: linear-gradient(-180deg, #f44336, #b71c1c);*/
        }

            &-4{
              height:8px;
            width:8px;
            /*background-color:#000;*/
        }
            &-5{
               height:8px;
            width:8px;
            /*background-color:#000;*/
        }
        }
    }

    .man-popup-cop{
        margin-left: auto;
        margin-right: auto;
       // width: 500px;
        height: 70px;
        //border: 1px solid gray;
        position: relative;
    }
    .inner-action{
        float: right;
    }
    .topic{
        text-align:center;
            display: flex;
            justify-content: center;
            margin-top: 20px;
             margin-bottom: 20px;

            .font__semibold{
                @include respond-to("x-small") {
                    @include font-size(14);
                }
                @include respond-to("small") {
                     @include font-size(16);
                }
                @include respond-to("medium") {
                     @include font-size(20);
                }
                @include respond-to("large") {
                     @include font-size(24);
                }
                @include respond-to("x-large") {
                     @include font-size(28);
                }                
                    margin-bottom: 20px;
                    @media only screen and (max-width: 768px) {
                                font-size:13px !important;
                        }
            }


        .mmc_flex--container{
            flex-direction: column;
            color: #7cb342;
            @include font-size(16);

            span{
                padding: 0 2px;
            }
        }
       
    }
     .chip-set{
         justify-content: center;
            .single-chip{
                border: 1px solid #696969;
                padding: 5px 10px;
                border-radius: 20px;
                margin-right: 20px;
                margin-bottom: 10px;
                color: #7cb342;
                span{
                    padding: 0 2px;
                }
            }
        }
   .timeline{
        background-image: linear-gradient(-180deg, #CDDC39,#009688);
        /*background-color: #4CAF50;*/
        /*background-color: #7cb342;*/
        height: 10px;
        width: 100%;
        margin-top: 2%;
        /* position: absolute; */
        z-index: 3;
        /*-webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;*/
    }
    .time-line--unavailable{
        /*background-image: linear-gradient(-180deg, #f5f5f5, #607D8B);*/
        /*-webkit-mask-image: url("http://support.nature.org/images/earth/assets/grit.png");
         mask-image: url("http://support.nature.org/images/earth/assets/grit.png");*/
        /*background-image: url("http://support.nature.org/images/earth/assets/grit.png");*/
        /*background-image: url("https://image.shutterstock.com/image-vector/intense-black-white-optical-illusion-600w-317800568.jpg");*/

        /*background-image:url("http://localhost/Optimo.PrivateWeb/dist/assets/images/unavailable-bg.png");*/
        /*background-color:#9e9e9e;*/
        /*background-color: #ababab;*/
        height: 10px;
        position: absolute;
        z-index: 300;
        margin-top: 0px;  
        /*opacity: 0.6;*/
    }
     .time-line--available{      
        height: 10px;
        /*position: absolute;*/
        z-index: 300;
        margin-top: 0px;         
    }
    .time-line--bump{      
        height: 10px;
        position: absolute;
        z-index: 300;
        margin-top: 0px;         
    }
    .time-line--sub{
        /*background-image: linear-gradient(-180deg, #f44336, #b71c1c);
        background-color: #f44336;*/
         /*background-color: #e53935;*/
        height: 10px;
        position: absolute;
        z-index: 300;
        margin-top: 0px;    
        /*-webkit-box-shadow: -1px 2px 5px 0px rgba(189,189,189,1);
        -moz-box-shadow: -1px 2px 5px 0px rgba(189,189,189,1);
        box-shadow: -1px 2px 5px 0px rgba(189,189,189,1);*/
        /*left: @include calc-left($start);
        width: @include calc-width($end , $start);*/
        //left: 50%;
        //width: 25%;

        /*&.test{
            left: 10% !important;
            width: 15% !important;
        }*/
    }
    
    .time-line--selected{
        /*background-image: linear-gradient(-180deg, #f44336, #b71c1c);*/
        /*background-color: #2196F3;*/
         /*background-color: #e53935;*/
        height: 10px;
        position: absolute;
        z-index: 300;
        margin-top: -10px;    
        /*-webkit-box-shadow: -1px 2px 5px 0px rgba(189,189,189,1);
        -moz-box-shadow: -1px 2px 5px 0px rgba(189,189,189,1);
        box-shadow: -1px 2px 5px 0px rgba(189,189,189,1);*/
        /*left: @include calc-left($start);
        width: @include calc-width($end , $start);*/
        //left: 50%;
        //width: 25%;

        /*&.test{
            left: 10% !important;
            width: 15% !important;
        }*/
    }

     .time-line--teardown-down{
        /*background-image: linear-gradient(-180deg, #f44336, #b71c1c);*/
        /*background-color: #607D8B;*/
        
        height: 10px;
        position: absolute;
        z-index: 300;
        margin-top: -10px;    
       
    }

     .time-line--teardown-up{
        /*background-image: linear-gradient(-180deg, #f44336, #b71c1c);*/
        /*background-color: #607D8B;*/
        
        height: 10px;
        position: absolute;
        z-index: 300;
        margin-top: -10px;    
       
    }

    .time-line-separator{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: flex-start;
        background-color: white;
        border-bottom: 1px solid;

    }

    .line{
        order: 0;
        flex: 1 0 auto;
        align-self: stretch;
        border-right: 1px solid #acacac;
        height: 10px;

        &:nth-child(1) {
          position:relative;
          border-left: 2px solid #212529;

          &:after {
              width:61px;
              content:"12:00 am";
              position:absolute;
              top:20px;
              right:12px;
              font-size:12px;
              letter-spacing:0.05rem;
              font-weight:600;
              word-spacing:0;
              /*@include font-size(12);*/

                  @media only screen and (max-width: 768px) {
                left:-19px !important;
                 width:51px !important;
            }
            }
        }
        
        &:nth-child(7) {
          position:relative;
          border-left: 2px solid #212529;
          &:after {
              content:"6:00 am";
              position:absolute;
              top:20px;
              left: -28px;
              font-size:12px;
              letter-spacing:0.05rem;
              font-weight:600;
              word-spacing:0;
              /*@include font-size(12);*/
            }
        }
        &:nth-child(13) {
          position:relative;
          border-left: 2px solid #212529;
          &:after {
              content:"12:00 pm";
              position:absolute;
              top:20px;
              left: -34px;
              font-size:12px;
              letter-spacing:0.05rem;
              font-weight:600;
              word-spacing:0;
              /*@include font-size(12);*/
            }
        }
        &:nth-child(19) {
          position:relative;
          border-left: 2px solid #212529;
          &:after {
              content:"6:00 pm";
              position:absolute;
              top:20px;
              left: -28px;
              font-size:12px;
              letter-spacing:0.05rem;
              font-weight:600;
              word-spacing:0;
              /*@include font-size(12);*/
            }
        }
        &:nth-child(24) {
          position:relative;
          border-right: 2px solid #212529;
          &:after {
              width:66px;
              content:"12:00 am";
              position:absolute;
              top:20px;
              left: 5px;
              font-size:12px;
              letter-spacing:0.05rem;
              font-weight:600;
              word-spacing:0;
              /*@include font-size(12);*/

                @media only screen and (max-width: 768px) {
                left:-11px !important;
                width:56px !important;
            }
            }
        }
    }

    .time-line-legend{
        margin-top:5%;
        margin-left:20%;

        .available{
            width:10px;
            height:10px;
            background-color:#4CAF50;
        }

        .booked{
            width:10px;
            height:10px;
            background-color:#f44336;
        }

        .unavailable{
            width:10px;
            height:10px;
            background-color:#9e9e9e;
        }
    }
}


/*delete modal*/

.delete-modal{
        margin: 24px !important;
}

/*alternative options modal*/

.alternative-options-modal{
    .mmc-modal-body{

           @media only screen and (max-width: 768px) {
        padding: 25px 16px !important;
    }
        .available-count{

        }

        .search--result{
            .mobile-card{
                .content{
                    .mmc_flex--container{
                        .item_name{
                            max-width:inherit !important;
                            text-decoration:none !important;
                            padding-bottom:8px;
                        }

                        .item_description{

                        }


                        .quentity{
                            padding-top:12px !important;
                            .config-item{
                                .config-img{
                                    >img{
                                        width:40px !important;
                                        /*height:40px !important;*/
                                    }
                                }
                            }
                        }
                        }
                }

                .butn__cage{
                    width: 200px !important;
                    min-width:200px !important;

                    .price{

                    }

                    .mmc-btn-primary{
                        font-size:12px !important;
                    }
                }
            }
        }
    }
}

.category-event-selection-row{
  margin-top: 3rem;
  .bookingpopup-input{
    width: 90%;
  }
  .mmc_flex__item.m-1{
    display: flex;
    flex-direction: column;
  }
}

@media  (max-width: 768px) {
  .category-event-selection-row{
    flex-direction: column;
    .bookingpopup-input{
      width: 100%;
    }
  }
}
.edit-modal {
  
  .edit-timeSlot.edit-row:after {
    border-left: none !important;
    }

    .mat-form-field-label {
        white-space: normal !important;
        font-size: 12px !important;
    }

    .p50{
          @media only screen and (max-width: 768px) {
                width:100% !important;
            }
    }

    .fd-col{
        @media only screen and (max-width: 768px) {
               flex-direction:column;
            }
    }

    .edit-row {
        .max-count-error {
            position: absolute;
            bottom: 5px;
            margin-bottom: 10px;
            font-size: 11px;
            letter-spacing: 0.05rem;
            line-height: 1.125rem;
            font-weight: 500;
            color: #D32F2F;

            @include respond-to("x-small") {
                margin-bottom: 100px !important;
            }

      @include respond-to("small") {
        margin-bottom: 10px !important;
      }

      @include respond-to("medium") {
        margin-bottom: 10px !important;
      }

      @include respond-to("large") {
        margin-bottom: 10px !important;
      }

      @include respond-to("x-large") {
        margin-bottom: 10px !important;
      }
    }

        #attendees-error {
            position: relative;
            // bottom: 20px;
            // margin-bottom: -6px;
            font-size: 11px;
            letter-spacing: 0.05rem;
            line-height: 1.125rem;
            font-weight: 500;
            color: #D32F2F;
            /*@include respond-to("x-small") {
                    margin-bottom: 85px;
                }
                @include respond-to("small") {
                      margin-bottom: -6px;
                }
                @include respond-to("medium") {
                      margin-bottom: -6px;
                }
                @include respond-to("large") {
                     margin-bottom: -6px;
                }
                @include respond-to("x-large") {
                     margin-bottom: -6px;
                }*/
        }

       
    }

  .mat-form-field {
    width: 100%;
  }

    .edit-timeSlot{
        span.mat-placeholder-required {
            color: red;
            font-size: 15px;
        }
    }
    .edit-row {
        position: relative;
        padding: 16px;
        margin: 0;
        background-color: #f5f5f5;
        border-radius: 3px;
        border: 1px solid #eee;
        min-height: 220px;

        .mmc_flex__item.p50.pickers{
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .mat-form-field-wrapper {
            padding-bottom: 0 !important;
          }
        }

    @include respond-to("x-small") {
      flex-direction: column;
      height: unset !important;
    }

    @include respond-to("small") {
      flex-direction: row;
    }

    @include respond-to("medium") {
      flex-direction: row;
    }

    @include respond-to("large") {
      flex-direction: row;
    }

    @include respond-to("x-large") {
      flex-direction: row;
    }

    .m1 {
      .mat-form-field {
        width: 100%;
      }

      @include respond-to("x-small") {
        width: 100%;
      }

      @include respond-to("small") {
        width: 100%;
      }

            @include respond-to("medium") {
                width: 100%;
            }

      @include respond-to("large") {
        width: 25%;
      }

      @include respond-to("x-large") {
        width: 25%;
      }
    }

    .item-attendess {
      .mat-form-field {
        width: 100%;
      }
      @media only screen and (max-width: 768px) {
        width: 100% !important;
      }
    }

    .item-availability {
      margin-left: 16px !important;

      @media only screen and (max-width: 768px) {
        margin-left: 0 !important;
        margin-top: 16px !important;
      }
    }

    .m2 {
      .mat-form-field {
        width: 100%;
      }

      @include respond-to("x-small") {
        width: 100%;
        /*margin-left:0 !important;*/
        margin-top: 50px !important;
      }

      @include respond-to("small") {
        width: 50%;
        /*margin-left:16px !important;*/
        margin-top: 0 !important;
      }

      @include respond-to("medium") {
        width: 50%;
        /*margin-left:16px !important;*/
        margin-top: 0 !important;
      }

      @include respond-to("large") {
        width: 50%;
        /*margin-left:16px !important;*/
        margin-top: 0 !important;
      }

      @include respond-to("x-large") {
        width: 50%;
        /*margin-left:16px !important;*/
        margin-top: 0 !important;
      }
    }

    .item-date {
      .mat-icon-button,
      .mat-icon {
        font-size: 16px !important;
      }
    }

    .item-attendess {
      position: relative;
      // width: 25%;
      @media only screen and (max-width: 768px) {
        margin: 0;
        width: 100% !important;
      }

      .p25 {
        @media only screen and (max-width: 768px) {
          width: 100% !important;
        }
      }

      .btn-wrapper {
        position: absolute;
        top: 15px;
        right: 4px;
        display: flex;
        flex-direction: column;
        background-color: #f5f5f5;

        .mat-button {
          padding: 0;
          min-width: unset;
          /*height: 20px;*/
        }

        .mat-button-wrapper {
          display: flex;
          align-items: center;
        }
      }

      .mat-form-field-label {
        white-space: normal !important;
        font-size: 12px !important;
      }
    }

    .item-availability {
      .title {
        color: #8bc34a;

        > span {
          color: #000;
        }

        @include respond-to("x-small") {
          text-align: center;
        }

        @include respond-to("small") {
          text-align: left;
        }

        @include respond-to("medium") {
          text-align: left;
        }

        @include respond-to("large") {
          text-align: left;
        }

        @include respond-to("x-large") {
          text-align: left;
        }
      }

      .available-chip {
        background-color: #e8f5e9 !important;
        border: 1px solid #8bc34a !important;
        color: #8bc34a !important;
        height: 40px;
        display: flex;
        align-items: center;

        > span {
          margin: 0 8px 0 8px;
          color: #000 !important;
        }

        .time-opt {
          color: #000;
          font-size: 10px;
          position: absolute;
          top: 22px;
          width: 85%;
          text-align: center;
        }
      }

      .mat-chip-list-wrapper {
        @media only screen and (max-width: 768px) {
          justify-content: center !important;
        }
      }
    }

    &:after {
      content: "";
      width: 3px;
      height: 90%;
      border-left: 1px dashed #a5a5a5;
      position: absolute;
      left: 49.5%;
      top: 5%;
      z-index: 1;

      @include respond-to("x-small") {
        display: none;
      }

      @include respond-to("small") {
        display: block;
      }

      @include respond-to("medium") {
        display: block;
      }

      @include respond-to("large") {
        display: block;
      }

      @include respond-to("x-large") {
        display: block;
      }
    }
  }

  .middle-row {
    position: relative;
    padding: 16px;
    margin: 0 -16px 0 -16px;
    /*background-color: #f5f5f5;*/
    border-radius: 3px;
    /*border:1px solid #eee;*/
    @include respond-to("x-small") {
      flex-direction: column;
    }

    @include respond-to("small") {
      flex-direction: row;
    }

    @include respond-to("medium") {
      flex-direction: row;
    }

    @include respond-to("large") {
      flex-direction: row;
    }

    @include respond-to("x-large") {
      flex-direction: row;
    }

    .m1 {
      .mat-form-field {
        width: 100%;
      }

      @include respond-to("x-small") {
        width: 100%;
      }

      @include respond-to("small") {
        width: 25%;
      }

      @include respond-to("medium") {
        width: 25%;
      }

      @include respond-to("large") {
        width: 25%;
      }

      @include respond-to("x-large") {
        width: 25%;
      }
    }

    .item-date {
      .mat-icon-button,
      .mat-icon {
        font-size: 16px !important;
      }
    }

    .booking-for {
      .mat-form-field {
        width: 50% !important;
      }
    }

    .m3 {
      .mat-form-field {
        width: 100%;

        @include respond-to("x-small") {
          width: 100% !important;
        }

        @include respond-to("small") {
          width: 50% !important;
        }

        @include respond-to("medium") {
          width: 50% !important;
        }

        @include respond-to("large") {
          width: 50% !important;
        }

        @include respond-to("x-large") {
          width: 50% !important;
        }
      }

      @include respond-to("x-small") {
        width: 100% !important;
        margin-left: 0 !important;
      }

      @include respond-to("small") {
        width: 50% !important;
        margin-left: 16px !important;
      }

      @include respond-to("medium") {
        width: 50% !important;
        margin-left: 16px !important;
      }

      @include respond-to("large") {
        width: 50% !important;
        margin-left: 16px !important;
      }

      @include respond-to("x-large") {
        width: 50% !important;
        margin-left: 16px !important;
      }
    }
  }

  .config-row {
    .config-item {
      display: flex;
      flex-direction: column;

      .config-img {
        height: 40%;
        width: 40%;
        object-fit: cover;

        > img {
          height: 40%;
          width: 50px;
          object-fit: cover;
        }
      }
    }

    .mat-form-field {
      width: 50% !important;
    }

    .item-w {
      @include respond-to("x-small") {
        width: 100% !important;
        margin-right: 0 !important;
      }

      @include respond-to("small") {
        width: 20% !important;
        margin-right: 8px !important;
      }

      @include respond-to("medium") {
        width: 20% !important;
        margin-right: 8px !important;
      }

      @include respond-to("large") {
        width: 100% !important;
        margin-right: 8px !important;
      }

      @include respond-to("x-large") {
        width: 100% !important;
        margin-right: 8px !important;
      }
    }
  }

  .confirmation {
    color: #006ff8;

    span {
      align-self: center;
      padding-left: 10px;
    }
  }

  .err-msg {
    /*margin-top: 10px;*/
    padding: 5px 10px;
    color: #fff;
    background-color: #d50000;
    border-radius: 5px;
    margin-bottom: 16px;
    font-size: 12px;

    .icon-error {
      &:before {
        font-size: 40px;
        margin-right: 20px;
      }
    }
  }

  .available-msg {
    margin-top: 10px;
    padding: 5px 10px;
    color: #fff;
    background-color: #00695c;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 14px;

    .icon-verified {
      &:before {
        font-size: 40px;
        margin-right: 20px;
      }
    }
  }

  .disable-div {
    cursor: not-allowed !important;
    opacity: 0.5;
  }

  .calendar-modal {
    .topic {
      .font__semibold {
        font-size: 20px;
        margin-bottom: 15px;
      }
    }

    .timeline {
      margin-top: 5%;
    }
  }

  .check-availability--btn {
    width: 100% !important;
    margin-top: 20px;
    background-color: #fff;
    color: #aba9a9;
    border: 1px solid #e8e8e8;
    margin-left: 5px;
    margin-right: 5px;

    &:hover {
      /*box-shadow: -1px 3px 27px 0px #c7c7c7;*/
      background-color: #1254cd;
      color: #fff;
      background-color: #006ff8;
    }
  }
  /*.mat-form-field-suffix{
      position: relative;
      left: -95px;
      top: 4px;
  }*/
  .mat-form-field-infix {
    width: auto !important;
  }
}

.disable-div {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.doc-modal {
  .doc-sec {
    .mat-form-field-infix {
      width: 300px;
    }
  }
}
/*.line:nth-child(6) {
background: red;
position:relative;


}


.line:nth-child(6):after {
content:"6";

// position:absolute;
width:10px;
height:20px;

left:0
}


.line:nth-child(10):after {
content:"10";

// position:absolute;
width:10px;
height:20px;

left:0
}*/

.alternative-list {
  .mat-expansion-panel-header {
    .pw-txt-b3 {
      @media only screen and (max-width: 425px) {
        font-size: 12px !important;
      }
    }
  }
}

.edit-booking-modal {
  .Alternatives{
    .p50-web{
      .pw-txt-b2{
        display: flex;
      }
    }
  }
  .availabiblity-legend legend{
    width: 10% !important;
  }
  .mmc-modal-header {
    z-index: 111;
    height: 70px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: relative;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            height: auto !important;
        }

        .mmc_flex__item--hstart {
            @media only screen and (max-width: 768px) {
                padding-left: 24px;
                padding-top: 10px;

                .pw-txt-h4 {
                    @media screen and (max-width: 768px) {
                        height: auto !important;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        padding-left: 0px !important;
                        padding-top: 5px !important;

                        .pw-txt-b3 {
                            position: relative;
                            height: auto;
                            left: 0px;
                            top: 0px !important;
                            padding-left: 0px !important;
                            padding: 5px 0 !important;
                        }
                    }
                }
            }
        }

        .mmc_just-content-end {
            @media only screen and (max-width: 768px) {
                padding-left: 20px;
                padding-top: 3px;
                justify-content: flex-start;
            }

            @media screen and (max-width: 768px) {
                justify-content: start;
                padding-left: 15px;
                position: relative;
                left: 8px;
            }
        }

        .close-icon-cage {
            @media only screen and (max-width: 768px) {
                position: absolute;
                right: 0;
            }

            @media screen and (max-width: 768px) {
                position: absolute;
                right: 0;
            }
        }

        .pw-txt-h4{
            @media only screen and (max-width: 768px) {
                font-size:14px !important;
            }
        }

        .pw-txt-b3{
            padding: 5px 0px;
            border-radius: 3px;
            font-size: 12.5px;

            span {
                margin-left: 32px;
                padding-left: 10px;
                border-left: 2px solid;
                font-size: 14px;
            }
            
            @media only screen and (max-width: 768px) {
                font-size:12px !important;
            }
        }

    .icon-cage {
      width: 70px;
      color: #fff;
      background-color: #fff;
      margin-right: 15px;
    }

    .close-icon-cage {
      width: 70px;
      color: #3a424d;

      button {
        height: 70px;
        width: 70px;
        transition: 0.3s;

        &:hover {
          color: #006ff8;
        }
      }
    }
  }

    .mmc-modal-body {
        padding: 25px 75px;
        /*padding:32px;*/
        max-height: 70vh;
        overflow-y: scroll;
        overflow-x: hidden !important;
        @media screen and (max-width: 768px) {
            max-height: 50vh;
        }

    .mat-dialog-content {
      overflow: unset !important;
      @media only screen and (max-width: 768px) {
        margin-top: 24px !important;
      }
    }
  }

  .Alternatives {
    .btn__card {
      width: 65%;
      font-size: 12px !important;
      text-transform: inherit !important;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .mmc-modal-footer {
    z-index: 111;
    justify-content: center;
    align-items: center;
    height: 65px !important;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12), 0 1px 8px rgba(0, 0, 0, 0.24);
    position: relative;

    .btn__card {
      width: 65%;
      font-size: 12px !important;
      text-transform: inherit !important;
      background-color: #00507c !important;
      color: $opt_color_white !important;
    }

    .item-w-75 {
      @include respond-to("x-small") {
        width: unset !important;
      }

      @include respond-to("small") {
        width: 75% !important;
      }

      @include respond-to("medium") {
        width: 75% !important;
      }

      @include respond-to("large") {
        width: 75% !important;
      }

      @include respond-to("x-large") {
        width: 75% !important;
      }
    }

    .item-w-25 {
      @include respond-to("x-small") {
        width: 70% !important;
      }

      @include respond-to("small") {
        width: 25% !important;
      }

      @include respond-to("medium") {
        width: 25% !important;
      }

            @include respond-to("large") {
                width: 40% !important;
            }

            @include respond-to("x-large") {
                width: 40% !important;
            }
        }
    }

  .Alternatives {
    padding-left: 16px;

    @media only screen and (max-width: 768px) {
      padding-left: 0;
      margin-top: 16px;
    }
  }

  .p50-web {
    width: 50%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .p20-web {
    width: 20%;
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-top: 16px;
    }
  }

  .fd-col-mob {
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  /*config image*/

  .config-img {
    > img {
      width: 50px;
    }
  }

  /*availabiblity-legend*/

  .availabiblity-legend {
    border: 1px solid #9e9e9e !important;
    border-radius: 3px;
    padding: 15px;
    width: 90%;
    margin: auto;
    position: relative;

    legend {
      /*width:8%;*/
      margin-bottom: 0 !important;
      @include respond-to("x-small") {
        width: 38%;
      }

      @include respond-to("small") {
        width: 38%;
      }

      @include respond-to("medium") {
        width: 8%;
      }

      @include respond-to("large") {
        width: 8%;
      }

      @include respond-to("x-large") {
        width: 8%;
      }
    }

    .key-container {
      @include respond-to("x-small") {
        flex-direction: column;
      }

      @include respond-to("small") {
        flex-direction: column;
      }

      @include respond-to("medium") {
        flex-direction: row;
        flex-wrap: wrap;
      }

      @include respond-to("large") {
        flex-direction: row;
        flex-wrap: wrap;
      }

      @include respond-to("x-large") {
        flex-direction: row;
        flex-wrap: wrap;
      }

      .key-item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 3px;
        &:nth-child(3) {
          &:after {
            content: "";
            width: 3px;
            height: 30px;
            border-left: 1px dashed #9e9e9e;
            position: absolute;
            top: -5px;
            right: -3px;
            z-index: 1;
            @media only screen and (max-width: 1023px) {
              display: none !important;
            }
          }
        }
        @include respond-to("x-small") {
          justify-content: flex-start;
        }

        @include respond-to("small") {
          justify-content: flex-start;
        }

        @include respond-to("medium") {
          justify-content: center;
        }

        @include respond-to("large") {
          justify-content: center;
        }

        @include respond-to("x-large") {
          justify-content: center;
        }

        .key-color{
            margin-right:8px;
            height:10px;
            width:15px;
            border: 1px solid #bababa;
             

        }

        .non-operational {
          height: 10px;
          width: 20px;
          background-color: rgb(155, 148, 148);
        }

        .available {
          height: 10px;
          width: 20px;
          background-image: linear-gradient(-180deg, #cddc39, #009688);
        }

        .booked {
          height: 10px;
          width: 20px;
          background-color: rgb(244, 67, 54);

          &:after {
            content: "";
            width: 3px;
            height: 70%;
            border-left: 1px dashed #9e9e9e;
            position: absolute;
            left: 60%;
            top: 22%;
            z-index: 1;

            @include respond-to("x-small") {
              display: none;
            }

            @include respond-to("small") {
              display: block;
            }

            @include respond-to("medium") {
              display: block;
            }

            @include respond-to("large") {
              display: block;
            }

            @include respond-to("x-large") {
              display: block;
            }
          }
        }

        .selected {
          height: 10px;
          width: 20px;
          background-color: rgb(251, 171, 0);
        }

        .setup {
          height: 10px;
          width: 20px;
          background-color: rgb(79, 28, 3);
        }
      }
    }
  }

  .alternative-list {
    .pw-txt-b3 {
      @media only screen and (max-width: 768px) {
        font-size: 13px !important;
      }
    }

    .mat-expansion-panel-body {
      padding: 0px 0px 0px 0px !important;

      .edit-modal-alternative-body {
        background-color: #f5f5f5;
        position: relative;
        padding: 0 24px 0;
        border-bottom: 1px solid #eeeeee;

        @media only screen and (max-width: 768px) {
          padding: 0 8px 0 !important;
        }

        .alternative-radio-group {
          .body-container {
            &:after {
              content: "";
              width: 100%;
              border-bottom: 1px solid #e4e4e4;
              position: absolute;
              left: 0px;
            }
            .alternative-radio {
              height: 55px;

              .mat-radio-label {
                margin-bottom: 0 !important;
                @media only screen and (max-width: 768px) {
                  font-size: 12px !important;
                  display: flex;
                  align-items: baseline;
                }

                .mat-radio-label-content {
                  @media only screen and (max-width: 768px) {
                    white-space: normal !important;
                  }
                }
              }
            }
          }
        }

        .f-end {
          justify-content: flex-end;
        }

        .text-right {
          text-align: right;

          .session-text {
            .pw-txt-h4 {
              @media only screen and (max-width: 768px) {
                font-size: 12px !important;
              }
            }

            .pw-txt-s2 {
              @media only screen and (max-width: 768px) {
                font-size: 10px !important;
              }
            }
          }
        }
    }

}
    }
   

.book-btn-wrapper{
    margin-right: 35px;

    div:nth-child(2){
        margin-left: 10px;
    }
}    

}

//wp modal styles
.wp-modal-wrapper {
  .mmc-modal-header {
    z-index: 111;
    height: 75px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: relative;
    
    .icon-cage {
      width: 75px;
      color: #fff;
      background-color: #ed3e84;
      margin-right: 15px;
    }
    .close-icon-cage {
      width: 75px;
      color: #3a424d;
      button {
        height: 75px;
        width: 75px;
        transition: 0.3s;
        &:hover {
          color: #006ff8;
        }
      }
    }
  }

  .mmc-modal-body {
    padding: 25px 25px;
    /*padding:32px;*/
    max-height: 80vh;
    /*overflow-y: scroll;*/

    .wp-modal-title {
      > div {
        padding: 3px 0;
      }
      .pw-text-color__wp-primery {
        color: #4caf50;
      }

      .link-primary {
        font-size: 0.75rem !important;
      }
    }

    .mat-form-field {
      width: 100%;

      .mat-form-field-infix {
        /*height:200px;*/

        > textarea {
          height: -webkit-fill-available;
          padding: 5px;
          /*height:200px;*/
          height: 200px !important;
          background-color: #eee;
        }
      }
    }

    .btn-wrapper {
      display: flex;
      justify-content: flex-end;

      .mmc-btn-secondary--small {
        background-color: #00507c !important;
        border: 1px solid #00507c !important;
      }
    }
  }
}

//new modal styles
.mmc-modal-wrapper {
  .mmc-modal-header {
    z-index: 111;
    height: 75px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: relative;
    .icon-cage {
      width: 75px;
      color: #fff;
      background-color: #ed3e84;
      margin-right: 15px;
    }
    .close-icon-cage {
      width: 75px;
      color: #3a424d;
      button {
        height: 75px;
        width: 75px;
        transition: 0.3s;
        &:hover {
          color: #006ff8;
        }
      }
    }
  }
  .header-primary{
    background: #00507C;
    height: 65px !important;
  }

    

    
    .mmc-modal-body{
        padding:25px 75px;
        /*padding:32px;*/
        max-height: 80vh;
        overflow-y: scroll;

        @media screen and (max-width: 768px) {
            max-height: 55vh;
        }
        @media screen and (max-width: 470px) {
          max-height: 65vh;
      }
    }
}
//Add extras modal
.add-extras-modal {
  .mmc-modal-body {
    @media only screen and (max-width: 768px) {
      padding: 25px 16px !important;
    }
  }

  .mmc_card search--result {
    .image-cage {
      @media only screen and (max-width: 768px) {
        width: 100% !important;
        width: auto !important;
      }
    }
  }

  .inner-container {
    padding: 0px !important;
    background-color: #fff !important;
  }

  .main_search {
    margin-top: 0px !important;
  }
  .main_search {
    .search-field {
      flex: 0 0 50%;
    }
    .mat-paginator {
      background: #fff;
    }
    .search-options {
      flex: 0 0 50%;
      .search-date {
        width: 30%;
        display: flex;
        align-items: center;
        color: #909cac;
        border-left: 1px solid #909cac50;
        border-right: 1px solid #909cac50;
        padding-left: 10px;
        .mmc_flex--container {
          display: flex;
          align-items: center;
          width: 100%;
          .down-arrow {
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            top: 5px;
          }
          .selected-date {
            display: flex;
            align-items: center;
          }
        }
      }
      .search-category {
        width: 30%;
        border-left: 1px solid #ccc;
        @media only screen and (max-width: 1024px) {
          width: 100% !important;
        }
      }
      .search-btn {
        width: 30%;
        display: flex;
        align-items: center;

        @media only screen and (max-width: 1024px) {
          width: 100% !important;
        }
      }
    }
  }
  .items-available-label {
    margin: 20px 0px;
  }
  .search--result {
    .content {
      border-right: 1px solid #909cac50;
      padding: 15px 15px 15px 15px;

      @media only screen and (max-width: 1024px) {
        border-right: none !important;
      }

      > .mmc_flex--container {
        height: 100%;

        @include respond-to("x-small") {
          flex-direction: column;
        }
        @include respond-to("small") {
          flex-direction: column;
        }
        @include respond-to("medium") {
          flex-direction: column;
        }
        @include respond-to("large") {
          flex-direction: column;
        }
        @include respond-to("x-large") {
          flex-direction: column;
        }
      }
      .item_name {
        max-width: 370px;
        h1 {
          font-size: 1rem;
          letter-spacing: 0.15rem;
          line-height: 1.5rem;
          word-spacing: 0.1rem;
        }
      }
      .item_address {
        max-width: 370px;
        line-height: 1rem;
      }
    }
    .item_name {
      text-decoration: underline;
    }
    .butn__cage {
      padding: 10px;
      min-width: 230px;
      @media only screen and (max-width: 768px) {
        min-width: inherit !important;
      }

      .per-item__amount {
        display: block;
        color: #828282;
      }





            button{
                width:100%;
            }
            .per-item__amount{
                margin-right:5px;
            }
        }
        .quentity{
            button{
                min-width:unset;
            }
            label{
                margin:0px 5px;
            }
            .qty{
                margin:5px 0px;
            }
        }
        .image-cage:hover{
            border:2px solid $pw-border-color__secondary !important;
        }
        .quentity-increase{
            display:flex;
            align-items: center;
            height: 30px;

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }
            .mat-form-field-underline{
                display: none;
            }
            .qty-input{
                width: 12% !important;

                .mat-form-field-wrapper{
                    padding-bottom: 10px;
                }
            }
        }
    }
    .qty-input{
        width:60PX;
        /*max-width:100px;*/
        text-align:center;
        .mat-form-field-infix{
            line-height:unset;
        }
    }
}
.date-select-menu {
  min-width: 350px;
  max-height: 400px;
  overflow-x: hidden;
  padding: 10px;
  .all {
    margin-top: 10px;
  }
  .date-select-row {
    padding: 10px;
    .month {
      padding-left: 10px;
    }
    .day {
      padding-left: 20px;
    }
  }
}

//Manage Contacts modal
.manage-contacts-modal {
  .mmc-modal-body {
    .add-contact-container {
      .mat-form-field {
        width: 100% !important;
      }

      .sub-section-title {
        align-items: center;
      }

      .mmc-btn-secondary {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        font-size: 16px !important;
        color: #ffffff !important;
        text-align: center !important;
        background-color: #2574a9 !important;
      }

      .mmc-btn-secondary:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      }
      .w120 {
        @include respond-to("x-small") {
          width: auto;
        }
        @include respond-to("small") {
          width: auto;
        }
        @include respond-to("medium") {
          width: auto;
        }
        @include respond-to("large") {
          width: 120px;
        }
        @include respond-to("x-large") {
          width: 120px;
        }
      }
    }

    .contact-list-container {
      .mmc_flex--container {
        align-items: center;

        .name-ico {
          margin-right: 24px;
          height: 48px;
          width: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #f44336;
          font-size: 18px;
          border: 1px solid #f44336;
          border-radius: 50%;
          background-color: rgba(244, 67, 54, 0.31);
        }
      }

      .sub-section-title {
        align-items: center;
        margin: 16px 0px !important;
      }

      .contact-list-wrapper {
        overflow: auto;
        height: 220px;

        .contact-item {
          margin-right: 8px;
          background-color: #fff;
          padding: 8px;
          margin-bottom: 4px;

          flex-wrap: wrap;
          list-style: none;
          display: flex;
          display: -moz-flex;
          display: -webkit-flex;

          .mmc_flex__item--inherit {
            @include respond-to("x-small") {
              margin: 10px;
              width: 20%;
            }
            @include respond-to("small") {
              margin: 10px;
              width: 20%;
            }
            @include respond-to("medium") {
              margin: 10px;
              width: 20%;
            }
            @include respond-to("large") {
              margin: auto;
              width: auto;
            }
            @include respond-to("x-large") {
              margin: auto;
              width: auto;
            }
            &.contact-status {
              @include respond-to("x-small") {
                width: auto !important;
              }
              @include respond-to("small") {
                width: auto !important;
              }
              @include respond-to("medium") {
                width: auto !important;
              }
              @include respond-to("large") {
                width: auto !important;
                margin-right: 15px;
              }
              @include respond-to("x-large") {
                width: auto !important;
                margin-right: 15px;
              }
            }
            &.mmc_just-content-end {
              @include respond-to("x-small") {
                width: 100%;
              }
              @include respond-to("small") {
                width: 65%;
              }
              @include respond-to("medium") {
                width: 65%;
              }
              @include respond-to("large") {
                width: auto;
              }
              @include respond-to("x-large") {
                width: auto;
              }

              .mat-button {
                @include respond-to("x-small") {
                  width: 100%;
                }
                @include respond-to("small") {
                  width: 100%;
                }
                @include respond-to("medium") {
                  width: 100%;
                }
                @include respond-to("large") {
                  width: 100%;
                }
                @include respond-to("x-large") {
                  width: 100%;
                }
              }
            }
          }

          .mmc_flex__item {
            @include respond-to("x-small") {
              margin: 10px;
              width: 100% !important;
            }
            @include respond-to("small") {
              margin: 10px;
              width: 25% !important;
            }
            @include respond-to("medium") {
              margin: 10px;
              width: 25% !important;
            }
            @include respond-to("large") {
              margin: 10px;
              width: 15% !important;
            }
            @include respond-to("x-large") {
              margin: auto;
              width: 15% !important;
            }

            &.contact-name {
              @include respond-to("x-small") {
                margin: 10px;
                width: auto !important;
              }
              @include respond-to("small") {
                margin: 10px;
                width: 70% !important;
              }
              @include respond-to("medium") {
                margin: 10px;
                width: 70% !important;
              }
              @include respond-to("large") {
                margin: 10px;
                width: 15% !important;
              }
              @include respond-to("x-large") {
                margin: 10px;
                width: 15% !important;
              }
            }
          }
          .email-col {
            overflow: hidden;
          }
          .pw-txt-b3 {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .contact-item:not(:last-child) {
          margin-bottom: 4px;
        }

        .contact-status {
          margin-right: 15px;
          .mat-button-toggle-group {
            height: 36px !important;
            border-radius: 8px !important;

            .mat-button-toggle-appearance-standard {
              background: #fff !important;
              color: #000 !important;
            }
            .mat-button-toggle-checked {
              background: #044fa2 !important;
              color: #fff !important;
              font-weight: 600 !important;
            }

            .mat-button-toggle-button {
              height: 36px !important;
              border-radius: 8px !important;

              .mat-button-toggle-label-content {
                line-height: 36px !important;
              }
            }
          }
        }
      }
    }
  }
  overflow: auto;
}

//Questionnaire modal
.questionnaire-modal {
  .inner-container {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .header-container {
    .col {
      &:not(:last-child) {
        border-right: 1px solid #909cac50;
      }
    }
  }
  .answered-label {
    margin-bottom: 20px;
  }
  .questionnaire-accordion {
    .mat-expansion-panel-header {
      .mat-expansion-indicator {
        display: none;
      }
    }

    .mat-expansion-panel-body {
      background-color: #f6f6f6;
      padding: 16px 24px 16px;
    }
  }
  .extras-label {
    margin: 20px 0px 0px 0px;
    padding: 20px 0px;
    border-top: 1px dotted #909cac;
  }
  .extras-accordion {
    .mat-expansion-panel-body {
      background-color: #f6f6f6;
      padding: 16px 24px 16px;
    }
  }
  .button-row {
    margin: 20px 23px 20px 0px;
    button {
      margin: 0px;
    }
    .col-sm-12{
      padding: unset;
    }
  }
  .mat-form-field {
    width: 100%;
  }

  .mmc-modal-body {
    @media only screen and (max-width: 768px) {
      padding: 15px 15px !important;
    }

    .header-container {
      @media only screen and (max-width: 768px) {
        flex-wrap: wrap !important;
      }

      .col:not(:last-child) {
        @media only screen and (max-width: 768px) {
          border-right: none !important;
        }
      }
    }
  }
  .mat-expansion-panel{
    &.mat-expanded{
        margin-bottom: 10px !important;
    }
  }
  .mat-expansion-panel-header{
    &.mat-expanded{
        padding: 8px 20px !important;
    }
  }
  .mat-radio-label-content {
    margin-top: 5px;
    text-wrap: wrap;
  }
}
//Dialog message styles
.dialog-message-wrapper {
  &.questionnaire-message {
    .message-title {
      //margin-bottom:20px;
    }
    .answers-container {
      margin-top: 20px;
    }
    .button-row {
      margin-top: 20px;
    }
  }
}

//Global styles
.card {
  margin-bottom: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 20px;
  border-radius: 4px;
  position: relative;
  background-color: #ffffff;
}
.inner-container {
  background-color: $background-color_light;
  padding: 30px 20px;
  margin-bottom: 20px;
}

.mcc_warning {
  .row {
    margin: 0;
  }
}

.manage-contacts-modal {
    .mmc-modal-body {
        @include respond-to("x-small") {
            padding: 0 !important;
        }
        @include respond-to("small") {
            padding: 0 !important;
        }
        @include respond-to("medium") {
            padding: 0 !important;
        }
        @include respond-to("large") {
            padding: 25px 16px !important;
        }
        @include respond-to("x-large") {
            padding: 25px 16px !important;
        }
    }
}

.add-extras-modal {
    .main_search {
        .main-upsell-search  {
            flex: 0 0 50% !important;
        }
    }
}

.mmc-modal-header {
    border-bottom: 1px solid #e7e7e7;
    box-shadow: 0px 0px 10px #e7e7e7;
    z-index: 9999999999;
}

.mmc-modal-footer {
    border-top: 1px solid #e7e7e7;
    box-shadow: 0px 0px 10px #e7e7e7;
    z-index: 9999999999;
}

.m-dialog-container{

  @include respond-to("x-small") {
            max-width: 90% !important;
             width: 90% !important;
          }
        @include respond-to("small") {
               max-width: 90% !important;
                width: 90% !important ;
          }
          @include respond-to("medium") {
               max-width: 90% !important;
               width: 60% !important;
          }
          @include respond-to("large") {
               max-width:40%;
               width: 40% !important;
          }
          @include respond-to("x-large") {
               max-width:40% !important;
                width: 40% !important;
          }
        }

        .footer-btn{
          @media screen and (max-width:768px) {
            justify-content: center !important;
          }
        }

        @media screen and (min-width:1024px) {
          .sessionpop{
              width: 30% !important;
          }
      }

.mat-paginator {
    .mat-paginator-outer-container {
        .mat-paginator-container {
            .mat-paginator-page-size {
                .mat-paginator-page-size-label {
                    // flex: 0 0 45%;
                }

                .mat-paginator-page-size-select {
                    width: 56px !important;
                }
            }
        }
    }
}

@media screen and (max-width:850px) {
  .search--result .description-text{
    table{
      width: auto !important;
    }
  }
}

@media screen and (min-width:768px) {
  .mmc_flex__item.col-12.col-xs-12.col-sm-12.col-md-6.mmc_flex__item--vcenter.mmc_just-content-center.ng-star-inserted {
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    padding-right: 32px !important;
  }
}

.mmc_flex__item.col-12.col-xs-12.col-sm-12.col-md-6.mmc_flex__item--vcenter.mmc_just-content-center.ng-star-inserted {
  padding-right: 50px!important;
}

#event-error{
  color: #D32F2F;
  font-size: 11px;
  letter-spacing: 0.05rem;
  line-height: 1.12rem;
  font-weight: 500;
}

@media (max-width: 380px){
  .search--result .mobile-card {
    padding: 15px;
  }
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button, .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  justify-content: flex-end !important;
  width: auto !important;
}