
 .pd-0{
     padding:0px;
 }


.mmc-slider-wrapper{
    font-family: "FontAwesome" !important;


    margin-top:16px;
       font-weight: 200;

    ngx-gallery-image{
        height:100% !important;
    }

    .ngx-gallery-thumbnails{
        display:none;
    }

    i,.mmc{
        color:#a0a0a0;
    }

    .fa-arrow-circle-right:before {
      content: "\f105" !important;

     }
   .fa-arrow-circle-left:before {
        content: "\f104";
    }
   .avl-cage{
        text-align: center;
        margin-top: 8px;        
        border: 1px solid #464646;
        padding: 5px 5px;
        border-radius: 5px;
        background-color: #504a4ab3;
        &:hover{
            background-color: #030202;
            -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.75);
            box-shadow: 0px 1px 1px 1px rgba(202, 193, 193, 0.75);
            -webkit-transition: background-color 1s ease-out;
          -moz-transition: background-color 1s ease-out;
          -o-transition: background-color 1s ease-out;
          transition: background-color 1s ease-out;
        }

    .font__semibold{
            font-size: 14px;
        }   

    .icn-cage{
        line-height: 0px;

    }
   
}

   .btn_wrp{
       @include respond-to("x-small") {
         width: 100%;
      }
      @include respond-to("small") {
         width: 100%;
      }
      @include respond-to("medium") {
         width: 100%;
      }
      @include respond-to("large") {
         width: auto;
      }
      @include respond-to("x-large") {
         width: auto;
      }
   }

.mmc{
       
      line-height: 20px;
      font-size: 16px;


    .font__semibold{
        font-size: 16px;
    }    
}

.avl-lbl{
    padding-left: 8px;
    cursor : pointer;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    .font__large{
        font-weight:700;
    }
    .avl-state{
        @include font-size(12);
        font-weight:700;
    }
    .avl-date{
        @include font-size(10);
        font-weight:600;
    }
}

   .ngx-gallery-arrow-left[_ngcontent-c12] {
        left: 40px;
    }
   .ngx-gallery-arrow-right[_ngcontent-c12] {
        right: 40px;
    }
    .ngx-gallery-arrow {
        @media screen and (max-width: 768px) {
            top: 35% !important;
        }  
    }
    .font__h3{
           // font-size: 42px;
    /* font-size: 2rem; */
    /* letter-spacing: 1rem; */
    line-height: 2rem !important;
    
    font-weight: 600;

     @include respond-to("x-small") {
         font-size: 27px;
          word-spacing: 0.20rem;
      }
      @include respond-to("small") {
         font-size: 27px;
          word-spacing: 0.20rem;
      }
      @include respond-to("medium") {
        font-size: 27px;
      }
      @include respond-to("large") {
           font-size: 20px;
           word-spacing: 0.20rem;
           letter-spacing: 0.20rem;
}
      }
      @include respond-to("x-large") {
         font-size: 32px;
         word-spacing: 0.32rem;
      }



    

    .txt-address{
        color:#a0a0a0;
    }

    .font__xsmall{
            
            @include respond-to("x-small") {
                font-weight: 500;
              }
              @include respond-to("small") {
                 font-weight: 600;
              }
              @include respond-to("medium") {
                 font-weight: 600;
              }
              @include respond-to("large") {
                 font-weight: 600;
              }
              @include respond-to("x-large") {
                 font-weight: 600;
              }

    }

    margin-left:auto;
    margin-right:auto;

    position:relative !important;

        .slider-detail--wrapper{
           


            position: absolute !important;
            bottom: 0px !important;
            z-index: 998 !important;

             @include respond-to("x-small") {
                padding-left: 20px;
                padding-right: 20px;
                width:100%;
                position: relative !important;
                top: -20px;
              }
              @include respond-to("small") {
                    padding-left: 40px;
                    padding-right: 40px;
                    width:100%;
                    position: relative !important;
                    top: -20px;
              }
              @include respond-to("medium") {
                padding-left: 40px;
                padding-right: 40px;
                width:100%;
                padding-bottom: 10px;
                position: relative !important;
                top: -20px;
              }
              @include respond-to("large") {
                padding-left: 30px;
                padding-right: 30px;
                width:100%;
                padding-bottom: 0px;
              }
              @include respond-to("x-large") {
                padding-left: 40px;
                padding-right: 40px;
                padding-bottom: 0px;
                width:100%;
              }

            
             .confirmation{
                justify-content: flex-end;
            }


        }
        .ngx-gallery-image{

            z-index:997 !important;          
        }
    .ngx-gallery-layout {
        &.thumbnails-bottom {
            position: relative !important;
        }
    }


    .ngx-gallery-thumbnails{
            height: 100%;
    /* width: 100%; */
    position: absolute;
    left: 40px;
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
    will-change: transform;
  

        a{
               border: 2px solid #fff;
               @include respond-to("x-small") {
               display: none;       
            }
            @include respond-to("small") {
                display: none;
            }
            @include respond-to("medium") {
               display: block;
            }
            @include respond-to("large") {
               display: block;
            }
            @include respond-to("x-large") {
               display: block;
            }
        }


    }

    ngx-gallery-thumbnails{
        position: absolute;
        top:0px;
            margin-top: 0px !important;

            ngx-gallery /deep/ .ngx-gallery-arrow.ngx-gallery-disabled { 
                display: none !important;                
            }
            .ngx-gallery-arrow-wrapper{
                 display: none !important;
            }

            
    }
    
        
    .ngx-gallery-thumbnails-wrapper {


       background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 79%, rgba(0,0,0,0) 100%);
       background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 60%,rgba(0,0,0,0) 79%,rgba(0,0,0,0) 100%);
       background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 60%,rgba(0,0,0,0) 79%,rgba(0,0,0,0) 100%);
       filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
 //position: absolute;
  //  top: -648px !important;
    z-index: 999;
    display: flex;
    //height: 121px !important;
    /* left: 50px; */
  
    padding-top: 40px;
    /* padding-left: 50px; */
    padding-bottom: 5px;
 
    }

    .ngx-gallery-thumbnails-wrapper .ngx-gallery-thumbnails {
        display: flex;
    }
    ngx-gallery-thumbnails{
        height:0;
    }
    ngx-gallery-image{
        height:100%;
        
    }

    .ngx-gallery-thumbnail {
        width: 80px !important;
        height: 80px !important;
        position: static !important;
        margin-right: 14px;
    }
    .ngx-gallery-icon {
        font-size: 80px !important;
    }
    @media (min-width: 300px){
         ngx-gallery {
            height: 633px !important;
        }
    }
}
.slider-available-msg{
    max-width:570px;
    position: absolute;
    background-color: #3b3b37;
    border-radius: 4px;
    color: #fff;
    @include font-size(12);
    font-weight: 600;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    position: absolute;
    padding:3px 0;
    
    z-index: 999;

    @include respond-to("x-small") {
          max-width: 300px;
          top: 15px;
          right: 15px;
    }
    @include respond-to("small") {
          max-width: 350px;
          top: 40px;
          right: 40px;
    }
    @include respond-to("medium") {
         max-width: 570px;
         top: 40px;
         right: 40px;
    }
    @include respond-to("large") {
         max-width: 570px;
         top: 40px;
         right: 40px;
    }
    @include respond-to("x-large") {
         max-width: 570px;
         top: 40px;
         right: 40px;
    }                
    &:before{
        content: '';
        bottom: -10px;
        right: 82px;
        position: absolute;
        border-top: 10px solid #3b3b37;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }
   .text{
        padding: 0 15px;
        width: auto;
        margin-right:81px;
        display:flex;
        justify-content:center;
    }
   .text-small{
       font-size: 10px;
       font-weight: 500;
       padding: 0 15px;
   }
    .icon{    
               
        &--available{
            position: absolute;
            height: 80px;
            right: 0;      
            background-color: #2ecd73;
            border-radius: 0 4px 4px 0;
             padding: 0 15px;
                  top:0px;
            i{
                font-size: 50px !important;
                color: #fff;
            }
        }
        &--nt-available{
            position: absolute;
            height: 80px;
            right: 0;      
            background-color: #ce6b6b;
            border-radius: 0 4px 4px 0;
            padding: 0 15px;
            top:0px;
            i{
                font-size: 50px !important;
                color: #fff;
            }
        }
         
    }
   
}

.ngx-gallery-arrow-wrapper[_ngcontent-c14] {
    z-index: 1000 !important;
}
.fa{
    font-family: "FontAwesome" !important;
}