$color-grey: #eaeaea;
$color-grey-light: #b7b7b7;
$color-grey-dark: #717070;
$color-black-light: #979797;
$color-gray: #afafaf;
$color-yellow: #cbdd59;
$color-green: #00a651;
$color-blue: #006ff8;
$color-pink: #ed3e84;
$color-pink-orange: #e6614f;
$color-green-light: #2ecd73;
$color-green-dark: #3fba74;
$color-green-very-light: #80d4a3;
$color-white-dark: #fffefe;
$color-black: #373737;
$color-login-bg: #eff8fd;
$color-login-input-bg: #e7eff5;
$color-red: #ed1c24;
$color-text: #636363;
$color-text-dark: #3d3d3d;
$color-text-link: #00698f;
$color-red-text: #c74242; 
$color-blue-icon: #018abd;
$color-white: #ffffff;