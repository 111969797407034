.margin {
  &--auto {
    margin: auto;
  }
}
.d {
  &--flex {
    display: flex;
  }
}
.align--center {
  height: 100%;
  align-items: center;
}
.characterCountSec{
  margin-top: -21px;
  margin-bottom: 21.5px;
}