﻿@import "../layout/grid";
/*@import "../helpers/_mixins.scss";*/
@import "../helpers/_flexbox.scss";
@import "../base/_tc-md-mixins-new.scss";
@import "../themes/default/_variables-new.scss";


button {
    &:hover,
    &:focus {
        outline: none;
    }
}



.pw{
    &-btn{
        @include pw-btn;

        &.gradient {
            @include pw-btn-gradient;
            background: $pw_btn-bg-color__gradient;
            color: $pw_btn-text-color;

            &--line {
                border: 1px solid $pw-btn-border-color !important;
                color: $pw_btn-text-color !important;
            }
        }


        &.btn-primary {
            
            color: $pw_btn-text-color !important;
            border: 1px solid $pw-btn-border-color__primary !important;
            background-color: $pw_btn-bg-color__primary;

            &:hover {
                background-color: $pw_btn-bg-color__primary;
                color: $pw_btn-text-color !important;
                border: 1px solid $pw-btn-border-color__primary !important;
            }
        }

        &.btn-secondary {
            @include pw-btn;
            color: $pw_btn-text-color__primary !important;
            border: 1px solid $pw-btn-border-color__primary !important;
            background-color: $pw_btn-bg-color;

            &:hover {
                background-color: $pw_btn-bg-color__primary;
                color: $pw_btn-text-color !important;
                border: 1px solid $pw-btn-border-color__primary !important;
            }
        }


        &.btn-tertiary {
            @include pw-btn;
            color: $pw_btn-text-color !important;
            border: 1px solid $pw-btn-border-color__secondary !important;
            background-color: $pw_btn-bg-color__secondary;

            &:hover {
                background-color: $pw_btn-bg-color__secondary;
                color: $pw_btn-text-color !important;
                border: 1px solid $pw-btn-border-color__secondary !important;
            }
        }

        &--small {
            @include pw-btn--small;
        }


        &.btn-icon {
            @include pw-btn-icon;

            .btn-icon {
                margin-right: 8px;
            }

            .btn-label {
                width: 50px;
                white-space: normal;
                text-align: left;
                margin-right: 8px;
                font-size: 0.625rem;
            }

            &-primary {
                @include pw-btn-icon;
                color: $pw_icon-btn-text-color__primary;

                &:hover {
                    background-color: $pw_btn-icon-bg-color__primary;
                }
            }

            &-secondary {
                @include pw-btn-icon;
                color: $pw_icon-btn-text-color__secondary;

                &:hover {
                    background-color: $pw_btn-icon-bg-color__secondary;
                }
            }

            &-tertiary {
                @include pw-btn-icon;
                color: $pw_icon-btn-text-color__secondary;

                &:hover {
                    background-color: $pw_btn-icon-bg-color__primary;
                }
            }

            &--xl {
                @include pw-btn-icon--xl;
            }

            &--small {
                @include pw-btn-icon--small;
            }
        }

        &.add-more {
            border: 1px dotted #91a0ad;
            height: 64px;
            color: #91a0ad;
            position: relative;
            bottom: 11px;
            margin-left: 5px;
            transition: 0.3s;

            &:hover {
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                background-color: $pw_btn-bg-color__primary;
                color: $pw_btn-text-color !important;
                border: 1px solid $pw-btn-border-color__primary !important;
                text-align: center !important;
            }
        }
    }
}
