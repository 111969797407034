@import "../layout/grid";
@import "../helpers/_mixins.scss";
@import "../helpers/_flexbox.scss";

//.btn {
  // @include respond-to("xx-large") {
  //height: 60px;
 // width: 281px;
  //background: linear-gradient(-241deg, #2388f0 0%, #00698f 100%);
  //border-radius: 30px;
  //color: white;
  //&--light {
    //border: 1px solid white;
    //color: white;
  //}
  // }
  //@include respond-to("xxx-large") {
    //height: 55px;
  //}
//}
// }
button {
  &:hover,
  &:focus {
    outline: none;
  }
}
.btn {
  // @include respond-to("xx-large") {
  outline-style: none;
  height: 60px;
  width: 281px;
  background: linear-gradient(-241deg, #2388f0 0%, #00698f 100%);
  background-color: #2388f0;
  border-radius: 30px;
  color: white;

  &--light {
    border: 1px solid white;
    color: white;
  }
  // }
  @include respond-to("xxx-large") {
    height: 55px;
  }
}
// }

.btn__card {
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  &.main {
    @include border-radius(5px);
    border: 1px solid #006ff8;
    padding: 5px;
    font-weight: 600;
    height: 40px;
    color: $pw_btn-bg-color__primary;
    transition: all 0.5s ease;
    @include justify-content(center);
    width:100%;
    /*box-shadow: -1px 0px 1px 1px #0000004a;
    -moz-box-shadow: -1px 0px 1px 1px #0000004a;
	-webkit-box-shadow: -1px 0px 1px 1px #0000004a;*/

     @include respond-to("x-small") {
        @include font-size_defalt(13);
      }
      @include respond-to("small") {
         @include font-size_defalt(13);
      }
      @include respond-to("medium") {
         @include font-size_defalt(13);
      }
      @include respond-to("large") {
         @include font-size_defalt(13);
      }
      @include respond-to("x-large") {
         @include font-size_defalt(14);
      }




    &:hover {
      background-color: $pw_btn-bg-color__primary;
      color: $pw_btn-text-color;
      box-shadow:  0px 1px 2px 0px #0000004a;
    -moz-box-shadow:  0px 1px 2px 0px #0000004a;
	-webkit-box-shadow: 0px 1px 2px 0px #0000004a;
    }
  }
  &.secondary{
    @include border-radius(3px);
    padding: 5px;
    font-weight: 600;
    height: 40px;
    color: #fff;
    background-color:#ed3e84;
    transition: all 0.5s ease;
    width:100%;
  }
  &.icon-button{
      display:flex;
      align-items:center;
      justify-content:center;
      .material-icons{
          font-size:16px;
      }
  }
  &.icon-button:focus{
    border: 1px solid #000 !important;
  }
}
.btn__validate {
  @include padding-left(10);
  @include padding-right(10);
  &.mat-button {
    border: 1px dashed darkgray;
  }
}
button.mat-button:focus{
  border: 1px solid #000 !important;
}