@import "../../sass/helpers/functions";
@import "../../sass/helpers/mixins";
@import "../../sass/base/color";

.header {
  &__alter-menu {
    //@include padding-right(85);
    .sub{
        float: right;
    }
  }
  padding: 15px 0;
  &__bg {
    -webkit-box-shadow: 1px 6px 18px -9px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 6px 18px -9px rgba(0,0,0,0.75);
box-shadow: 1px 6px 18px -9px rgba(0,0,0,0.75);
    background-color: $header_bg;
    /*background-image: linear-gradient(
      -260deg,
      #992e98 0%,
      #ee3e84 52%,
      #942a93 100%
    );*/
  }
  &__logo {
    img {
      max-height: 70px;
    }
  }
  &__user {
    .navbar {
      padding: 0;
    }
    .btn-click{
        padding-right:5px;
        transition:0.3s;
        &:hover{
            color:#0076C0;
        }
    }
  }
}
.user {
  height: 59px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.11);
  border: 1px solid #d9d9d9;
  background-color: $color-grey; // padding-left: auto;
  color:#fff;
  &__avatar {
    background-color: $pw-bg-color;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    @include sizeSquare(32px);
  }
  &__login {
    text-decoration: none;
    color: $color-blue;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    display: inline-block;
     @include respond-to("x-small") {
              padding-left: 0.25rem;
              padding-right: 0.25rem;
              @include font-size(11);
              font-weight: 600;
            }
            @include respond-to("small") {
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              @include font-size(14);
              font-weight: 700;
            }
            @include respond-to("medium") {
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              @include font-size(14);
              font-weight: 700;
            }
            @include respond-to("large") {
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              @include font-size(14);
              font-weight: 700;
            }
            @include respond-to("x-large") {
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              @include font-size(14);
              font-weight: 700;
            }
     color: #006ff8 !important;

  }
  &__account {
    position: relative;

    @include respond-to("x-small") {
              padding-left: 0.5rem;
            }
            @include respond-to("small") {
              padding-left: 1.5rem;
            }
            @include respond-to("medium") {
              padding-left: 1.5rem;
            }
            @include respond-to("large") {
              padding-left: 1.5rem;
            }
            @include respond-to("x-large") {
              padding-left: 1.5rem;
            }
    &__currency {
      position: absolute;
      top: 0;
      @include respond-to("x-small") {
              padding-left: 0.5rem;
            }
            @include respond-to("small") {
              padding-left: 1.5rem;
            }
            @include respond-to("medium") {
              padding-left: 1.5rem;
            }
            @include respond-to("large") {
              padding-left: 1.5rem;
            }
            @include respond-to("x-large") {
              padding-left: 1.5rem;
            }
      @include font-size(14);
      @include font-weight(regular);
      text-transform: uppercase;
    }
    &__text {
        @include respond-to("x-small") {
              @include font-size(12);
            }
            @include respond-to("small") {
              @include font-size(14);
            }
            @include respond-to("medium") {
              @include font-size(14);
            }
            @include respond-to("large") {
              @include font-size(14);
            }
            @include respond-to("x-large") {
              @include font-size(14);
            }
      /*color: $color-grey-dark;*/
      /*text-transform: uppercase;*/

      /*@include font-weight(bold);*/
      padding-right: 10px;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
      display: inline-block;
    }
  }
  &__cart {
    cursor: pointer;
    background-color: $pw_btn-bg-color__secondary;
    // width: 3.4rem;
    // height: 2.7rem;
    // padding-right: 1rem;
    width: 40px;
    height: 32px;
    align-items:center;

    i{
        margin-left:auto;
        margin-right:auto;
        margin-top:5px;
    }

    &__icon {
      @include sizeSquare(18px);
    }
  }
  &__counter {
    color: $pw-badge-text-color;
    position: absolute;
    background-color: $pw-badge-bg-color__secondary;
    // @include sizeSquare(2rem);
    height: 23px;
    width: 23px;
    top: -11px;
    right: -11px;
        font-size: 13px;
    font-weight: 500;
    // top: -0.2rem;
    // right: -1rem;
  }
  &__left {
    border-left: 1px solid $color-grey-light;
    //box-shadow: inset 2px 0 0 0 white;
    // height: 4.7rem;
  }
}
.counter__number {
    align-items: center;
    display: flex;
}
.link-disabled {
   pointer-events: none;
   cursor: default;
}
@media screen and (max-width: 576px) {
  .header {
    &__user {
      .bg--light {
        background-color: $color-grey !important;
        height: auto;
        min-height: 59px;
        button {
          border: none;
          color: #555555;
        }
      }
    }
    &__logo {
      display: flex;
      img {
        margin: auto;
      }
    }
  }
}
.header__user{
    margin-bottom:16px;
}
@media screen and (max-width: 768px) {
    .header__user{
        margin-bottom:40px;
        .navbar-collapse{
            margin:0px !important;
        }
        .user{
            height: auto !important;
            width: 100%;
            padding-bottom: 10px;
        }
    }
}
@media screen and (max-width: 375px) {
  .header__user{
    margin-bottom: 70px;
    z-index: 99999;
  }
}
/*@media screen and (max-width: 1023px) {
    .navbar-collapse{
        margin:0 40px;
    }

}*/
.navbar-collapse{
    @include respond-to("x-small") {
            margin:0;
    }
    @include respond-to("small") {
            margin:0;
    }
    @include respond-to("medium") {
            /*margin:0 40px;*/
    }
    @include respond-to("large") {
           /*margin:0 40px;*/
    }
    @include respond-to("x-large") {
           /*margin:0 40px;*/
    }

}
iframe{
    .header, .powered__by--mb, .footer, footer-css--wrap{
        display: none !important;
    }
}

// @media print {
//   .header__user.sub-header-user.mobile-header {
//     width: 50%;
//   }
// }

@media print{
  .header__user .user .sub {
    width: unset !important;
    max-width: min-content;
  }
  .user__account.pw-txt-b2.pw-text-color__tertiary.d--flex.align--center.pl-0.ng-star-inserted {
    width: max-content;
  }
  .userprofile-wrapper.float-right.pr-2 {
    width: max-content;
  }
  .mmc_flex__item--vcenter.wm-sign-in-btn {
    padding-left: 10px;
  }
  .header__user .user {
    width: 88%;
    min-width: unset !important;
  }
}

